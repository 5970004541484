import React from 'react'
import crypto from 'crypto'

const randomID = crypto.randomBytes(12).toString('hex')

const SamplesIcon = (active, className) => (active ? (
  <svg
    width='21px'
    height='20px'
    viewBox='0 0 21 20'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g
        id={`PGGE-IOH-HomePage-Samples-${randomID}`}
        transform='translate(-324.000000, -5633.000000)'
        strokeWidth='1.17'
      >
        <g transform='translate(0.000000, 5596.000000)'>
          <g id='Samples' transform='translate(307.000000, 38.000000)'>
            <g id='icon' transform='translate(18.000000, 0.000000)'>
              <polyline
                stroke='#FFF'
                points='11.1132075 8.11764706 4.99424491 17.6470588 3.52088582 15.3300565 0.716981132 15.0778462 4.61638424 9.02709776'
              />
              <polyline
                stroke='#FFF'
                points='9.67924528 10.7820318 14.1139529 18 15.5500418 15.6281214 18.2830189 15.3699378 14.4822552 9.17647059'
              />
              <path
                d='M7.39513585,7.67725305 L1.78523854,10.1251762 C0.94930298,10.4901018 0,9.90347099 0,9.02123331 L0,1.21400271 C0,0.332337907 0.94930298,-0.254865745 1.78523854,0.110059842 L7.88679245,2.77109493'
                stroke='#FFF'
              />
              <path
                d='M11.6042675,7.67725305 L17.2141648,10.1251762 C18.0501003,10.4901018 19,9.90347099 19,9.02123331 L19,1.21400271 C19,0.332337907 18.0501003,-0.254865745 17.2141648,0.110059842 L11.1132075,2.77109493'
                stroke='#FFF'
              />
              <path
                d='M9.5,2.47058824 C11.1828256,2.47058824 12.5471698,3.81381258 12.5471698,5.47058824 C12.5471698,7.12736389 11.1828256,8.47058824 9.5,8.47058824 C7.81717441,8.47058824 6.45283019,7.12736389 6.45283019,5.47058824 C6.45283019,3.81381258 7.81717441,2.47058824 9.5,2.47058824 Z'
                stroke='#FFF'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
) : (
  <svg
    width='21px'
    height='20px'
    viewBox='0 0 21 20'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g
        id={`PGGE-IOH-HomePage-Sample-${randomID}`}
        transform='translate(-324.000000, -5633.000000)'
        strokeWidth='1.17'
      >
        <g transform='translate(0.000000, 5596.000000)'>
          <g id='Samples' transform='translate(307.000000, 38.000000)'>
            <g id='icon' transform='translate(18.000000, 0.000000)'>
              <polyline
                stroke='#5E5E5E'
                points='11.1132075 8.11764706 4.99424491 17.6470588 3.52088582 15.3300565 0.716981132 15.0778462 4.61638424 9.02709776'
              />
              <polyline
                stroke='#5E5E5E'
                points='9.67924528 10.7820318 14.1139529 18 15.5500418 15.6281214 18.2830189 15.3699378 14.4822552 9.17647059'
              />
              <path
                d='M7.39513585,7.67725305 L1.78523854,10.1251762 C0.94930298,10.4901018 0,9.90347099 0,9.02123331 L0,1.21400271 C0,0.332337907 0.94930298,-0.254865745 1.78523854,0.110059842 L7.88679245,2.77109493'
                stroke='#5E5E5E'
              />
              <path
                d='M11.6042675,7.67725305 L17.2141648,10.1251762 C18.0501003,10.4901018 19,9.90347099 19,9.02123331 L19,1.21400271 C19,0.332337907 18.0501003,-0.254865745 17.2141648,0.110059842 L11.1132075,2.77109493'
                stroke='#5E5E5E'
              />
              <path
                d='M9.5,2.47058824 C11.1828256,2.47058824 12.5471698,3.81381258 12.5471698,5.47058824 C12.5471698,7.12736389 11.1828256,8.47058824 9.5,8.47058824 C7.81717441,8.47058824 6.45283019,7.12736389 6.45283019,5.47058824 C6.45283019,3.81381258 7.81717441,2.47058824 9.5,2.47058824 Z'
                stroke='#339DD0'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
))

export default SamplesIcon
