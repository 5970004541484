import React from 'react'

const PGBrandsIcon = (active, className) => (active ? (
  <svg
    width='46px'
    height='45px'
    viewBox='0 0 46 45'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g transform='translate(-22.921773, -22.900000)'>
        <g>
          {/* <circle fill='#FFFFFF' cx='45' cy='45' r='45' /> */}
          <line
            x1='37.4641991'
            y1='33.3409023'
            x2='50.5354965'
            y2='33.3409023'
            stroke='#FFFFFF'
            strokeWidth='2.5'
            strokeLinecap='round'
            strokeLinejoin='round'
            transform='translate(43.999848, 33.340902) scale(-1, 1) translate(-43.999848, -33.340902) '
          />
          <line
            x1='37.4641991'
            y1='40.9772586'
            x2='50.5354965'
            y2='40.9772586'
            stroke='#FFFFFF'
            strokeWidth='2.5'
            strokeLinecap='round'
            strokeLinejoin='round'
            transform='translate(43.999848, 40.977259) scale(-1, 1) translate(-43.999848, -40.977259) '
          />
          <line
            x1='37.4641991'
            y1='49.704523'
            x2='50.5354965'
            y2='49.704523'
            stroke='#FFFFFF'
            strokeWidth='2.5'
            strokeLinecap='round'
            strokeLinejoin='round'
            transform='translate(43.999848, 49.704523) scale(-1, 1) translate(-43.999848, -49.704523) '
          />
          <path
            d='M62.9843514,61.7045113 C62.9843514,59.5956044 64.7258943,57.8863335 66.8746185,57.8863335 L43.5330159,57.8863335 C41.3842917,57.8863335 39.6427488,59.5956044 39.6427488,61.7045113 L39.6427488,65.5226892 L62.9843514,65.5226892 L62.9843514,61.7045113 Z'
            stroke='#FFFFFF'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
            transform='translate(53.258684, 61.704511) scale(-1, 1) translate(-53.258684, -61.704511) '
          />
          <path
            d='M46.1288842,57.659894 C48.3440235,57.659894 50.139396,59.4198493 50.139396,61.5912907 C50.139396,63.7627322 51.9347684,65.5226874 54.1499077,65.5226874 C56.365047,65.5226874 58.1604195,63.7627322 58.1604195,61.5912907 L58.1604195,26.25 L28.75,26.25 L28.75,57.659894'
            stroke='#FFFFFF'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
            transform='translate(43.455210, 45.886344) scale(-1, 1) translate(-43.455210, -45.886344) '
          />
        </g>
      </g>
    </g>
  </svg>
) : (
  <svg
    width='46px'
    height='45px'
    viewBox='0 0 46 45'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g transform='translate(-22.921773, -22.900000)'>
        <g>
          {/* <circle fill='#FFFFFF' cx='45' cy='45' r='45' /> */}
          <line
            x1='37.4641991'
            y1='33.3409023'
            x2='50.5354965'
            y2='33.3409023'
            stroke='#309DD0'
            strokeWidth='2.5'
            strokeLinecap='round'
            strokeLinejoin='round'
            transform='translate(43.999848, 33.340902) scale(-1, 1) translate(-43.999848, -33.340902) '
          />
          <line
            x1='37.4641991'
            y1='40.9772586'
            x2='50.5354965'
            y2='40.9772586'
            stroke='#309DD0'
            strokeWidth='2.5'
            strokeLinecap='round'
            strokeLinejoin='round'
            transform='translate(43.999848, 40.977259) scale(-1, 1) translate(-43.999848, -40.977259) '
          />
          <line
            x1='37.4641991'
            y1='49.704523'
            x2='50.5354965'
            y2='49.704523'
            stroke='#309DD0'
            strokeWidth='2.5'
            strokeLinecap='round'
            strokeLinejoin='round'
            transform='translate(43.999848, 49.704523) scale(-1, 1) translate(-43.999848, -49.704523) '
          />
          <path
            d='M62.9843514,61.7045113 C62.9843514,59.5956044 64.7258943,57.8863335 66.8746185,57.8863335 L43.5330159,57.8863335 C41.3842917,57.8863335 39.6427488,59.5956044 39.6427488,61.7045113 L39.6427488,65.5226892 L62.9843514,65.5226892 L62.9843514,61.7045113 Z'
            stroke='#5E5E5E'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
            transform='translate(53.258684, 61.704511) scale(-1, 1) translate(-53.258684, -61.704511) '
          />
          <path
            d='M46.1288842,57.659894 C48.3440235,57.659894 50.139396,59.4198493 50.139396,61.5912907 C50.139396,63.7627322 51.9347684,65.5226874 54.1499077,65.5226874 C56.365047,65.5226874 58.1604195,63.7627322 58.1604195,61.5912907 L58.1604195,26.25 L28.75,26.25 L28.75,57.659894'
            stroke='#5E5E5E'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
            transform='translate(43.455210, 45.886344) scale(-1, 1) translate(-43.455210, -45.886344) '
          />
        </g>
      </g>
    </g>
  </svg>
))

export default PGBrandsIcon
