import React from 'react'
import crypto from 'crypto'

export default (active, className, color) => {
  const randomID = crypto.randomBytes(12).toString('hex')
  return (
    <svg
      aria-labelledby={`spinnerIcon-${randomID}`}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 100 100'
      preserveAspectRatio='xMidYMid'
      className={className}
    >
      <circle
        cx='50'
        cy='50'
        id={`spinnerIcon-${randomID}`}
        fill='none'
        stroke={color}
        strokeWidth='10'
        r='35'
        strokeDasharray='164.93361431346415 56.97787143782138'
        transform='rotate(175.951 50 50)'
      >
        <animateTransform
          attributeName='transform'
          type='rotate'
          repeatCount='indefinite'
          dur='1s'
          values='0 50 50;360 50 50'
          keyTimes='0;1'
        />
      </circle>
    </svg>
  )
}
