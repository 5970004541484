import React from 'react'
import crypto from 'crypto'

const randomID = crypto.randomBytes(12).toString('hex')
const PGBrandsIcon = (active, className) => (active ? (
  <svg
    width='19px'
    height='18px'
    viewBox='0 0 19 18'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g
        id={`PGGE-IOH-HomePage-Brands-${randomID}`}
        transform='translate(-250.000000, -5634.000000)'
        stroke='#FFF'
        strokeWidth='1.4'
      >
        <g transform='translate(0.000000, 5596.000000)'>
          <g transform='translate(224.000000, 39.000000)'>
            <path
              d='M35.6421657,16 C35.6421657,16 31.8435977,13.4452712 28.9335045,9.82792329 C25.7708499,5.89833083 26.530789,0.339635857 30.8998751,0.0125811459 C34.3613188,-0.245360024 35.6421657,3.54847394 35.6421657,3.54847394 C35.6421657,3.54847394 36.9173752,-0.245360024 40.3788189,0.0125811459 C44.7467775,0.339635857 45.5078441,5.89833083 42.3451894,9.82792329 C39.4350963,13.4452712 35.6421657,16 35.6421657,16 Z'

            />
          </g>
        </g>
      </g>
    </g>
  </svg>
) : (
  <svg
    width='19px'
    height='18px'
    viewBox='0 0 19 18'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g
        id={`PGGE-IOH-HomePage-Brand-${randomID}`}
        transform='translate(-250.000000, -5634.000000)'
        stroke='#5E5E5E'
        strokeWidth='1.4'
      >
        <g transform='translate(0.000000, 5596.000000)'>
          <g transform='translate(224.000000, 39.000000)'>
            <path
              d='M35.6421657,16 C35.6421657,16 31.8435977,13.4452712 28.9335045,9.82792329 C25.7708499,5.89833083 26.530789,0.339635857 30.8998751,0.0125811459 C34.3613188,-0.245360024 35.6421657,3.54847394 35.6421657,3.54847394 C35.6421657,3.54847394 36.9173752,-0.245360024 40.3788189,0.0125811459 C44.7467775,0.339635857 45.5078441,5.89833083 42.3451894,9.82792329 C39.4350963,13.4452712 35.6421657,16 35.6421657,16 Z'

            />
          </g>
        </g>
      </g>
    </g>
  </svg>
))

export default PGBrandsIcon
