import React from 'react'

const hamburger = (active, className) => (
  <svg
    aria-labelledby='hamburger-icon'
    x='0px'
    y='0px'
    width='100%'
    height='100%'
    pointerEvents='none'
    viewBox='0 0 120 120'
    className={className}
  >
    <title>Hamburger Menu</title>
    <desc>Hamburger Menu</desc>
    <path
      id='hamburger-icon'
      fill='#000000'
      d='M33.13,36.94H86.87a2.2,2.2,0,0,0,0-4.4H33.13a2.2,2.2,0,0,0,0,4.4Z'
    />
    <path
      fill='#000000'
      d='M33.13,62.16H86.87a2.2,2.2,0,0,0,0-4.4H33.13a2.2,2.2,0,0,0,0,4.4Z'
    />
    <path
      fill='#000000'
      d='M89.07,85.26a2.2,2.2,0,0,0-2.2-2.2H33.13a2.2,2.2,0,0,0,0,4.4H86.87A2.21,2.21,0,0,0,89.07,85.26Z'
    />
  </svg>
)

export default hamburger
