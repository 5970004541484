import { makeAutoObservable } from 'mobx'
import {
  fecthInternetMessageApi,
  fetchMemberHistoryApi,
} from '../../helpers/aimia.api.helper'
import Reward from './Models/reward.model'
import {
  fetchRewards,
  fetchRewardsWithEligibility,
  fetchIncentives,
} from '../../adapters/serverside.adapters'
import LoginStore from '../../components/UserManagement/Login-store'
import { throwAIMIAError } from '../../okta/forms/okta.helpers'

class AimiaStore {
  constructor() {
    makeAutoObservable(this)
  }

  /**
   *  userEnrolled value,
   *  false : not enrolled
   *  true : enrolled
   */
  userEnrolled = false

  /**
   * Unique member ID provided by AIMIA
   */
  memberID = null

  /**
   * User name, birthday, gender etc
   */
  userDetails = null

  /**
   *Shema:
   {
        "memberPointsEarned": 25,
        "memberPointsDeducted": 0,
        "memberPointsUsed": 0,
        "memberPointsExpired": 0,
        "memberPointsAvailable": 25,
        "memberPointsLocked": 0
    }
   */
  pointBalance = null

  rewards = []

  pointsHistory = null

  TransactionCustomFields = null

  charityLevelPoints = {}

  charityImpactPoints = {}

  charityPledges = {}

  internetMessageResponse = {}

  messages = []

  featurePromotionVicks = false

  featurePromotionBeauty = false

  unReadMessagesLength = 0

  totalMessagesLength

  messagesLoading = undefined

  allMessagesList

  readMessages = []

  earnRewards = []

  redeemRewards = []

  earnRewardsHistory = []

  redeemRewardsHistory = []

  additionalRewards = []

  showAdditionalRewards = false

  totalPointsAvailable = -1

  redemptionReached = false

  noReceiptScanUnlocked = false

  messageFetched = false

  samples = []

  earnedTransactionNextPage = 1

  redeemedTransactionNextPage = 1

  fetchAdditionalRewards = (allRewards, specialReward, typeId) => {
    if (
      typeId === 'Gift_card_and_local_experiences'
      || typeId === 'All_Rewards'
    ) {
      this.showAdditionalRewards = true
      this.additionalRewards = allRewards
        .filter(
          (reward) => specialReward.indexOf(reward.rewardTypeExternalReference) > 0,
        )
        ?.map((reward) => new Reward(reward))

      this.rewards = allRewards
        ?.filter(
          (reward) => specialReward.indexOf(reward.rewardTypeExternalReference) < 0,
        )
        ?.map((reward) => new Reward(reward))
    } else {
      this.rewards = allRewards
        ?.filter(
          (reward) => specialReward.indexOf(reward.rewardTypeExternalReference) < 0,
        )
        ?.map((reward) => new Reward(reward))
    }
  }

  fetchAllRewards = async (loggedInUser, specialReward, typeId = null) => {
    if (
      this.rewards.length !== 0
      && this.additionalRewards.length !== 0
      && !loggedInUser
    ) {
      return this.rewards
    }

    if (loggedInUser && specialReward) {
      this.rewards = []
      this.additionalRewards = []
      const rewardsResponse = await fetchRewardsWithEligibility()
      const rewardsData = await rewardsResponse.json()

      if (rewardsResponse.status === 200) {
        const {
          memberChoiceRewards,
          memberExistsInPromotionalGroupScanStreak,
        } = rewardsData

        const allRewards = memberChoiceRewards?.choiceRewardWithMemberEligibility || []

        const promotionalUser = Boolean(
          memberExistsInPromotionalGroupScanStreak?.memberIsInPromotionalGroup,
        )

        if (promotionalUser) {
          this.rewards = allRewards?.map((reward) => new Reward(reward))
          return this.rewards
        }
        this.fetchAdditionalRewards(allRewards, specialReward, typeId)
        this.redemptionReached = true
      } else {
        LoginStore.showErrorModal = true
        throwAIMIAError('fetchRewardsWithEligibility', rewardsData)
      }
    } else {
      const allRewardsResponse = await fetchRewards()
      const allRewardsData = await allRewardsResponse?.json()

      if (allRewardsResponse.status === 200) {
        const { memberChoiceRewards } = allRewardsData
        const allRewards = memberChoiceRewards?.choiceRewardWithMemberEligibility || []
        this.fetchAdditionalRewards(allRewards, specialReward, typeId)
      } else {
        LoginStore.showErrorModal = true
        throwAIMIAError('fetchRewards', allRewardsData)
      }
    }
    return this.rewards
  }

  fetchMemberHistoryAction = async () => {
    this.earnRewards = []
    this.redeemRewards = []
    const promiseArr = [fetchMemberHistoryApi({ transactionType: 'earn' }), fetchMemberHistoryApi({ transactionType: 'redeem' })]
    const history = await Promise.all(promiseArr)
    const earnRewards = history?.[0]?.transactions || []
    const redeemRewards = history?.[1]?.transactions || []
    if (earnRewards?.length) {
      this.earnRewards = earnRewards
    }
    if (redeemRewards?.length) {
      this.redeemRewards = redeemRewards
    }
    this.pointsHistory = [...earnRewards, ...redeemRewards]
  }

  fetchPointHistoryAction = async (transactionType, page = 1) => {
    const response = await fetchMemberHistoryApi({ transactionType, page })
    if (response?.transactions?.length) {
      if (transactionType === 'earn') {
        this.earnedTransactionNextPage = response?.pagination?.nextPage
        if (page === 1) this.earnRewardsHistory = response.transactions
        // eslint-disable-next-line no-unsafe-optional-chaining
        else this.earnRewardsHistory.push(...response?.transactions)
      } else if (transactionType === 'redeem') {
        this.redeemedTransactionNextPage = response?.pagination?.nextPage
        if (page === 1) this.redeemRewardsHistory = response.transactions
        // eslint-disable-next-line no-unsafe-optional-chaining
        else this.redeemRewardsHistory.push(...response?.transactions)
      }
    }
  }

  // this functionality need to be removed as we not showing charity points
  fetchCharityLevelPoints = async (shouldRefresh) => {
    if (!shouldRefresh) {
      const sessionData = JSON.parse(
        sessionStorage.getItem('charityLevelPoints'),
      )
      if (sessionData && sessionData !== 'null') {
        this.charityLevelPoints = sessionData
        return
      }
    }

    sessionStorage.setItem(
      'charityLevelPoints',
      JSON.stringify(this.charityLevelPoints),
    )
  }

  fetchMessagesList = async () => {
    this.messages = await fecthInternetMessageApi()
    if (this.messages && this.messages.length) {
      // eslint-disable-next-line array-callback-return, consistent-return
      this.readMessages = this.messages.filter((message) => {
        if (!message.read) {
          return message
        }
      })
    }
  }

  fetchIncentives = async () => {
    if (this.samples?.length !== 0) {
      return this.samples
    }

    const response = await fetchIncentives()
    const allIncentives = await response.json()
    this.samples = allIncentives?.data?.incentives
      .filter(
        (sample) => sample.incentiveType === 'SAMPLE'
          && !sample?.customTags?.includes('OFFER_CATALOG'),
      )
      .filter(
        (sample) => sample.distributions[0]?.availableQuantity
          >= Number(process.env.OOS_INVENTORY_LIMIT),
      )

    return this.samples
  }
}

export default new AimiaStore()
