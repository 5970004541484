import React from 'react'
import crypto from 'crypto'

export default (active, className) => {
  const randomID = crypto.randomBytes(12).toString('hex')
  return (
    <svg
      width='79px'
      height='79px'
      viewBox='0 0 79 79'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      className={className}
    >
      <defs>
        <polygon
          id={`path-${randomID}`}
          points='0 0.000219444444 79 0.000219444444 79 79 0 79'
        />
        <linearGradient
          x1='22.9334275%'
          y1='-87.8671954%'
          x2='59.9638036%'
          y2='204.905955%'
          id={`linearGradient-${randomID}`}
        >
          <stop stopColor='#A400A6' offset='0%' />
          <stop stopColor='#E04748' offset='100%' />
        </linearGradient>
      </defs>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          transform='translate(-2512.000000, -2343.000000)'
        >
          <g
            transform='translate(1832.000000, 2121.000000)'
          >
            <g transform='translate(680.000000, 222.000000)'>
              <g>
                <mask id={`mask-${randomID}`} fill='white'>
                  <use xlinkHref={`#path-${randomID}`} />
                </mask>
                <g />
                <path
                  d='M39.5,0.000219444444 C61.3149722,0.000219444444 79,17.6852472 79,39.5002194 C79,61.3151917 61.3149722,79.0002194 39.5,79.0002194 C17.6850278,79.0002194 0,61.3151917 0,39.5002194 C0,17.6852472 17.6850278,0.000219444444 39.5,0.000219444444'
                  fill={`url(#linearGradient-${randomID})`}
                  mask={`url(#mask-${randomID})`}
                />
              </g>
              <g
                transform='translate(17.000000, 17.000000)'
                stroke='#FFFFFF'
                strokeWidth='2.25'
              >
                <path
                  d='M22.7248816,11.9109079 L22.9356711,11.9109079 C26.7144868,11.9109079 29.7780395,14.9756447 29.7780395,18.7532763 L29.7780395,24.9194605 C29.7780395,28.6994605 26.7144868,31.7641974 22.9356711,31.7641974 L22.7248816,31.7641974 C18.9460658,31.7641974 15.8813289,28.6994605 15.8813289,24.9194605 L15.8813289,18.7532763 C15.8813289,14.9756447 18.9460658,11.9109079 22.7248816,11.9109079 Z'
                />
                <path
                  d='M11.9115,41.6896579 C14.9442632,39.0429474 18.5833421,37.719 22.8299211,37.719 C27.0765,37.719 30.7155789,39.0429474 33.7483421,41.6896579'
                  strokeLinecap='square'
                />
                <path
                  d='M22.8301579,0.88875 C34.9481842,0.88875 44.7712105,10.7117763 44.7712105,22.8298026 C44.7712105,34.9478289 34.9481842,44.7720395 22.8301579,44.7720395 C10.7121316,44.7720395 0.887921053,34.9478289 0.887921053,22.8298026 C0.887921053,10.7117763 10.7121316,0.88875 22.8301579,0.88875 Z'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
