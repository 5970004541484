import React from 'react'

const GoodNewsIcon = (active, className) => (active ? (
  <svg
    width='25px'
    height='24px'
    viewBox='0 0 25 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
    aria-labelledby='icon-goodnews'
  >
    <g
      stroke='none'
      strokeWidth='1'
      id='icon-goodnews'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g
        transform='translate(0.800431, 0.000000)'
        strokeWidth='1.75'
      >
        <g transform='translate(3.000000, 1.000000)'>
          <rect
            stroke='#FFF'
            x='0'
            y='0'
            width='18'
            height='22'
          />
          <rect
            stroke='#FFF'
            x='4'
            y='4'
            width='10'
            height='6'
          />
          <line x1='4' y1='14' x2='14' y2='14' stroke='#FFF' />
          <line x1='4' y1='18' x2='14' y2='18' stroke='#FFF' />
        </g>
      </g>
    </g>
  </svg>
) : (
  <svg
    width='25px'
    height='24px'
    viewBox='0 0 25 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
    aria-labelledby='goodnews-icon'
  >
    <g
      stroke='none'
      strokeWidth='1'
      id='goodnews-icon'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g
        transform='translate(0.800431, 0.000000)'
        strokeWidth='1.75'
      >
        <g transform='translate(3.000000, 1.000000)'>
          <rect
            stroke='#5E5E5E'
            x='0'
            y='0'
            width='18'
            height='22'
          />
          <rect
            stroke='#309DD0'
            x='4'
            y='4'
            width='10'
            height='6'
          />
          <line x1='4' y1='14' x2='14' y2='14' stroke='#5E5E5E' />
          <line x1='4' y1='18' x2='14' y2='18' stroke='#5E5E5E' />
        </g>
      </g>
    </g>
  </svg>
))

export default GoodNewsIcon
