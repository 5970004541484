import React from 'react'

export default (active, className) => (
  <svg
    width='13px'
    height='11px'
    viewBox='0 0 13 11'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g
        transform='translate(-2997.000000, -386.000000)'
        stroke='#FFFFFF'
        strokeWidth='1.75'
      >
        <g transform='translate(1833.000000, 208.000000)'>
          <g
            id='Progress-Bar-Copy-2'
            transform='translate(1082.000000, 164.000000)'
          >
            <g transform='translate(81.000000, 12.000000)'>
              <g transform='translate(2.333333, 2.916667)'>
                <line
                  x1='10.1335132'
                  y1='8.80776933e-15'
                  x2='3.44537126'
                  y2='8.91460299'
                />
                <line
                  x1='5.06705788e-13'
                  y1='5.25'
                  x2='3.19952898'
                  y2='8.81857582'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
