module.exports = {
  homepage: '/',
  login: '/login/',
  loginOutOfStock: '/login/out-of-stock/',
  myAccount: '/my-account',
  digitalCoupons: '/digital-coupons',
  couponsBrandSaver: '/coupons/brand-saver',
  About: '/about',
  offerrules: '/offer-rules',
  sitemap: '/sitemap',
  help: '/help',
  changePassword: '/change-password',
  pointsHistory: '/my-account/points-history',
  myImpactDashboard: '/my-account/',
  rewards: '/rewards',
  rewardsOptout: '/opt-out',
  manageCoupons: '/manage-coupons',
  showCouponsPrintScreen: '?printCoupons',
  pointsDetails: '/my-account/details',
  articleListing: '/good-news',
  referFriend: '/refer-a-friend',
  completeProfile: '/complete-profile',
  pledgeCharity: '?pledge',
  impact: '/impact',
  coupons: '/coupons/',
  filteredCoupons: '/coupons/?coupon_name=',
  myMessages: '/my-messages',
  quiz: 'quiz',
  earnPoints: '/earn-points',
  survey: '/survey',
  loginQuery: '/?login',
  impactSummary: '/impact/summary',
  receiptScan: '/earn-points/?receiptscan',
  search: '/search',
  info: '/info',
  maintenance: '/maintenance',
  rebates: '/rebates',
  rebatesWithEndSlash: '/rebates/',
  couponsWithEndSlash: '/coupons/',
  personalizedCoupons: '/coupons/personalized-coupons/',
  sampleOrderingPage: '/rewards/samples/order/',
  rewardsWithEndSlash: '/rewards/',
  impactsWithEndSlash: '/impact/',
  sustainabilityMythQuiz: '/good-news/sustainability-myths-quiz',
  signUpOutOfStock: '/signup/out-of-stock/',
  commonVideoGatingLogin: '/login/?utm_campaign=',
  commonVideoGatingSignup: '/signup/?utm_campaign=',
  simplifiedSignupFlow: '/signup/?flow=simplified-signup',
  sampleOrder: '/rewards/samples/order/?sample_name',
  sampleListing: '/rewards/samples',
  brands: '/brands',
  pampersRewardDetailsPage: '/rewards/donations/charity_1_014pmprs/',
  outOfStock: '/out-of-stock/',
  accountSetupPage: '/account-setup/',
  sustainability: '/sustainability',
  olympics: '/olympics',
  tideDigitalCoupon: 'tide-digital-coupon',
  swifferCoupons: '/coupons/pah/?coupon_name=swiffer',
  gilleteCoupons: '/coupons/pah/?coupon_name=gillette',
  tampaxCoupon: '/coupons/pah/?coupon_name=tampax',
  olayCoupons: '/coupons/pah/?coupon_name=olay',
  olaySuperSerumBodyCoupons: '/coupons/pah/?coupon_name=olay',
  accountSetupOlayCoupons: '/account-setup/olay-coupons/',
  pahCoupons: '/coupons/pah',
  authError: '/auth-error',
  loginDwanCoupon: '/login/?utm_campaign_coupon=dawn',
  commonRebates: '/rebates/?rebate_name=',
  rebatesForGoodAsGold: '/rebates/?rebate_name=good_as_gold',
  tideScholarship: '/tide-scholarship',
  myCoupons: 'my-coupons',
  dawnDucks: 'dawn-ducks',
  accountDawnDucks: '/account-setup/dawn-ducks/',
  oldSpiceCoupon: 'old-spice-coupon',
  loggedinUserRedirectForCoupon: [
    'my-coupons',
    'old-spice-coupon',
    'always-discreet-coupon-special-offer',
    'hs-coupon',
  ],
  alwaysUdo: 'always-discreet-coupon-special-offer',
  olayCouponsCampaign: 'olay-coupons',
  oldSpiceCouponfamilyDollar: 'oldspice-coupon-family-dollar',
  accountOldSpiceCouponfamilyDollar:
    '/account-setup/oldspice-coupon-family-dollar',
  headandShouldersCoupon: 'hs-coupon',
  couponPages: [
    { path: 'pah', title: 'Print At Home' },
    { path: 'brand-saver', title: 'brandSAVER™' },
    { path: 'personalized-coupons', title: 'Digital Coupons' },
  ],
  pah: 'pah',
  brandSaver: 'brand-saver',
  digitalCoupon: 'personalized-coupons',
  sweepstakesAprilFool: 'rewards/sweepstakes-entry/sweeps_yopg/',
}
