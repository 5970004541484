module.exports = {
  backend: {
    loginUrl: 'auth/login',
    enrollPGGEUserWithWelcomeEmailUrl: 'user/enroll/email',
    ChangePasswordUrl: 'user/password',
    updateProfileUrl: 'user/profile',
    updateAddress: 'user/address',
    getProfileUrl: 'user/profile',
    logoutUrl: 'auth/logout',
    optoutUrl: 'user/optout',
    membersPoints: 'user/reward/points',
    internetMessagePromotion: 'user/message/promotion',
    readMessage: 'user/message/read/?messageId=',
    issueInteraction: 'user/transaction/memberInteractions',
    rewardsList: 'user/reward/list',
    rewardsListAnonymous: 'user/reward/choiceList',
    sendRewardMail: 'user/reward/email',
    couponsApi: 'user/coupon/national',
    restrictionApi: 'user/coupon/restricted?profileGuid=',
    issueReward: 'user/reward',
    memberHistory: 'user/transaction/history',
    orderReward: 'user/reward/sample',
    ordersHistory: 'user/incentives',
    receiptScan: 'user/receipt/upload',
    digitalCoupons: {
      udcCoupons: 'udc/v2/coupons',
      udcLiveRetailers: 'udc/v2/retailers',
      verifyCredentialsStatus: 'udc/v2/wallet',
      verifyCredentialsInitiate: 'udc/v2/wallet/initiation',
      verifyCredentialsVerify: 'udc/v2/wallet/verification',
      clipCoupons: 'udc/v2/clip/coupons',
      walletLink: 'udc/wallets/link',
    },
    favoriteBrand: 'user/brand/favorite',
    incentives: 'user/campaign/incentives',
    oosAutomation: 'user/campaign/oosAutomation',
    uidRegister: 'user/coupon/register/?campaign=',
  },
  pwalink: 'https://consumersupport.pg.com/carehub?brand:PG%20GoodEveryday&language=en&country=us&articleId=How-do-I-save-the-PWA-to-my-phone-or-tablet-like-an-app',
  chatlink: 'https://consumersupport.pg.com/carehub?brand=PG%20GoodEveryday&amp;language=en&amp;country=us&amp;&component=chat',
}
