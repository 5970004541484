import { makeAutoObservable } from 'mobx'
import { loginContextText } from '../../constants/texts'
import staticRoutes from '../../../config/router.config'

class SignInStore {
  constructor() {
    makeAutoObservable(this)
  }

  showSignInPopup = false

  priority = true

  showWelcomeBackModal = false

  shrinkedHeader = false

  context = loginContextText.generic

  rewardStatus = false

  userLoggedIn = false

  emailVerified = false

  enrolledForRewards = false

  pendingFor = null

  pendingForSignup = null

  checkLoginStatus = true

  openSurvey = false

  openFeedbackSurvey = false

  addToHomeInAction = true

  profileData = ''

  showErrorModal = false

  errorModalCode = null

  setErrorModalCode = (code) => {
    if (this.errorModalCode === null) {
      this.errorModalCode = code
    }
  }

  userEmail = ''

  showAlertBar = true

  showAddToHomePopup = false

  showAddToHomeHamburger = false

  addToHomeAPI = true

  alreadyInstalled = false

  hideAddtoHome = true

  hideReferFriendCallout = false

  carousalIndex = 0

  refreshAfterLogin = true

  PGEUserRedirectedFromEmail = false

  showThankYouPopup = false

  filteredSurveyList = []

  surveyCompletedBadge = []

  utmCampaign = ''

  featuredCardLength = 3

  videoGating = false

  iohVideoGating = false

  showRewardMessage = false

  traitCampaignName = ''

  showPhotoRewardMessage = false

  pledge = null

  showCompleteProfileOverlay = false

  completeProfileCTALaunched = false

  filteredAlerts = []

  completeProfile = false

  showSurveyToolTip = false

  ispreview = false

  toolTipBehaviour = false

  getLoginCount = null

  isLayoutAvailed = false

  articlePendingUrl = '/'

  query = null

  showContent = 'View All'

  enableButton = true

  showAlreadyAccountAvailableModal = false

  charityID = ''

  janrainCaptureToken = ''

  optedOutUser = false

  addToBasketPendingWithRegistration = false

  sendOTPPendingWithRegistration = false

  PGEUserLoginTriggeredFromDigitalCoupons = false

  showLimitExceededErrorModal = false

  simplifiedSignupFlow = false

  redirectionUrlAfterSimplifiedSignup = staticRoutes.earnPoints

  showCountryRestrictionModal = false

  openTellMeAboutSurvey = false

  simplifiedSignupFlowWithCompleteProfileSurvey = false

  showCompleteProfile20Survey = false

  outOfStockTraitCampaignName = false

  cascadeDigitalCampaignName = false

  OpenCampaignSpecificSurvey = false

  completeProfile20SurveyTaken = false

  fetchInterationApiCalled = false

  showSurveyForSampleFlow = false

  showCompleteProfileEndCard = false

  showArticleQuiz = false

  sampleSpecificSurveyName = false

  buildYourProfileSurveyTaken = false

  showNetworkErrorModal = false

  networkErrorFunc = null

  showCompleteProfile20OrBuildYourProfileSurvey = false

  /**
   *  Auth0 Loading overlay will be shown.
   *  This will be displayed when user is redirected to Okta form for Login/Signup
   */
  auth0Loading = false

  auth0Code = null

  isFormProcessing = false

  showOptOutErrorModal = false

  errorPage = false

  sitemapBackUrl = '/'

  showCascadeThanksPopUp = false

  showTwogetherThanksPopUp = false

  showCincinnatiThanksPopUp = false

  showIneligiblePromoContentCMF = false

  showSessionExpiryModal = false

  loginStatusCode = null

  loginFlowPopup = null

  reRoutePath = null

  incentiveResponse = null

  forgotPasswordLinkLoader = true

  triggerTrakers = false

  openSurveyDuringSignup = false

  showCookiesDisabledodal = false

  ModalContent = {}

  oktaErrorEvent = null

  oktaLoginErrorEvent = { code: null, response: {} }

  blueBarSignup = false
}
export default new SignInStore()
