import React from 'react'

const Logo = (active, className) => (
  <svg
    role='img'
    width='185px'
    height='57px'
    viewBox='0 0 185 57'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
    aria-label='pgge-logo'
  >
    <title>PgGoodEveryday Logo</title>
    <desc>PgGoodEveryday Logo</desc>
    <g transform='translate(0.000000, 2.185185)'>
      <g>

        <radialGradient id='SVGID_1_' cx='-1569.2216' cy='-225.5477' r='3.2179' gradientTransform='matrix(24.1699 0 0 24.1699 37938.0156 5456.3628)' gradientUnits='userSpaceOnUse'>
          <stop offset='2.147594e-02' stopColor='#FFFFFF' />
          <stop offset='2.531055e-02' stopColor='#FBFEFF' />
          <stop offset='8.734551e-02' stopColor='#BAE7F9' />
          <stop offset='0.1437' stopColor='#87D5F4' />
          <stop offset='0.1926' stopColor='#63C9F1' />
          <stop offset='0.2321' stopColor='#4CC1EF' />
          <stop offset='0.2572' stopColor='#44BEEE' />
          <stop offset='0.282' stopColor='#37B8EB' />
          <stop offset='0.3493' stopColor='#19ACE5' />
          <stop offset='0.4069' stopColor='#07A4E0' />
          <stop offset='0.4478' stopColor='#00A1DF' />
          <stop offset='0.785' stopColor='#0045AB' />
          <stop offset='0.8154' stopColor='#003DA6' />
          <stop offset='0.9564' stopColor='#0059B6' />
        </radialGradient>
        <path
          fill='url(#SVGID_1_)'
          d='M9.5,26.1C9.5,14.6,16.8,4.8,27,1l0,0C13.6,1.5,2.8,12.6,2.8,26.1c0,13.6,10.7,24.6,24.2,25.1l0,0C16.8,47.5,9.5,37.6,9.5,26.1z'
        />

        <radialGradient id='SVGID_00000091010640280650301620000008504912298780558008_' cx='-1588.7844' cy='-235.3904' r='1.0767' gradientTransform='matrix(43.5615 0 0 43.5615 69223.3516 10264.7666)' gradientUnits='userSpaceOnUse'>
          <stop offset='0' stopColor='#00A1DF' />
          <stop offset='0.1267' stopColor='#0092D7' />
          <stop offset='0.3689' stopColor='#006CC1' />
          <stop offset='0.6249' stopColor='#003DA6' />
          <stop offset='0.7323' stopColor='#043799' />
          <stop offset='0.9308' stopColor='#102777' />
          <stop offset='1' stopColor='#152169' />
        </radialGradient>
        <path
          fill='url(#SVGID_00000091010640280650301620000008504912298780558008_)'
          d='M28,1c-0.3,0-0.7,0-1,0l0,0C16.8,4.8,9.5,14.6,9.5,26.1S16.8,47.4,27,51.2l0,0c0.3,0,0.6,0,0.9,0C41.8,51.2,53,39.9,53,26.1S41.8,1,28,1z'
        />

        <radialGradient id='SVGID_00000109010961782627360670000007948855770658315145_' cx='-1568.7125' cy='-225.2222' r='1.3074' gradientTransform='matrix(24.1699 0 0 24.1699 37938.0156 5464.8413)' gradientUnits='userSpaceOnUse'>
          <stop offset='0.3186' stopColor='#44BEEE' stopOpacity='0' />
          <stop offset='0.4833' stopColor='#2B99D9' stopOpacity='0.2745' />
          <stop offset='0.6679' stopColor='#1476C6' stopOpacity='0.5822' />
          <stop offset='0.8196' stopColor='#0561BA' stopOpacity='0.8352' />
          <stop offset='0.9185' stopColor='#0059B6' />
        </radialGradient>
        <path
          fill='url(#SVGID_00000109010961782627360670000007948855770658315145_)'
          d='M9.5,26.1C9.5,14.6,16.8,4.8,27,1l0,0C13.6,1.5,2.8,12.6,2.8,26.1c0,13.6,10.7,24.6,24.2,25.1l0,0C16.8,47.5,9.5,37.6,9.5,26.1z'
        />
      </g>
      <g transform='translate(9.856519, 17.253179)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          fill='#FFFFFF'
          d='M17.7,6.5c1.7-4.1-1.4-5.2-3.5-5.2H7.5C8,1.6,7.9,2.1,7.8,2.5L3.9,15c-0.2,0.5-0.7,1-1.1,1.1h5.4C8,16,7.6,15.6,7.7,15l1.4-4.5C9.2,10.5,15.7,11.4,17.7,6.5z M12.4,2.4c0.7,0,2.7,0.1,1.4,3.8c-1.3,3.9-4.3,3.1-4.3,3.1l2.2-6.9C11.7,2.4,12.4,2.4,12.4,2.4z'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          fill='#FFFFFF'
          d='M22,16.1h3.7c-1.2-0.4-2.9-1.5-3.6-2.3c0.6-0.6,1.2-1.4,1.4-2.9h-1.9c0,0,0.4,0.2,0.4,0.7c0,0.4-0.2,1-0.5,1.4c-0.6-0.8-1.1-1.8-1.4-3.2c0.7-0.4,1.8-0.8,2.6-1.5c0.8-0.7,1-1.3,1-1.8c0,0,0,0,0-0.1c0-0.4-0.2-0.9-0.5-1.2c-0.5-0.5-1.4-0.8-2.3-0.6c-0.7,0.1-1.4,0.5-1.7,0.8C18.1,6.5,18,8.2,18,9c0,0.2,0,0.3,0,0.4c-0.1,0-0.2,0.1-0.2,0.1c-0.8,0.3-3,0.9-3.7,2.6c-0.1,0.2-0.2,0.7-0.2,1.3c0,0.5,0.1,1,0.4,1.5c0.5,0.8,1.5,1.4,2.9,1.4c1.6,0,3.2-1.1,3.5-1.3C20.9,15.2,21.7,15.9,22,16.1z M22,6.2c-0.2,1.2-1.7,2.4-2.1,2.6c-0.1-0.7,0-1.7,0.5-2.6c0.4-0.6,0.9-0.8,1.3-0.7C22,5.6,22.1,5.9,22,6.2z M19.9,14.2c-0.2,0.1-1.1,0.6-1.9,0.6c-0.7,0.1-2-0.2-2-1.7c0-1.3,1.4-2.2,2.1-2.5C18.5,11.8,19.2,13.2,19.9,14.2z'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          fill='#FFFFFF'
          d='M33.2,2.4c2.2-0.5,4,1.2,4.1,1.3l0.6-2C37,1.4,30.8-0.7,26.4,4.4c-2.7,3.1-3.4,9.5,0.1,11.2c3.7,1.8,7.8-0.3,8-0.4l1.7-5.4c0.2-0.6,0.6-1.1,1.3-1.3h-5.8c0.9,0.4,0.8,1,0.7,1.3l-1.7,5.4c-0.2,0-4.9,1.2-2.3-7C29,6.3,30.6,3,33.2,2.4z'
        />
      </g>
    </g>
    <g transform='translate(107.047569, 23.308642)'>
      <path
        fill='#003DA6'
        d='M10.7,8.9H4.3c0.1,0.7,0.4,1.3,0.9,1.7s1.2,0.6,2,0.6c1,0,1.7-0.3,2.3-1l0.8,1c-0.4,0.4-0.8,0.8-1.3,1s-1.1,0.3-1.8,0.3c-0.8,0-1.6-0.2-2.2-0.5s-1.2-0.8-1.5-1.5S2.9,9.2,2.9,8.4s0.2-1.5,0.5-2.1s0.8-1.1,1.4-1.5s1.3-0.5,2-0.5s1.4,0.2,2,0.5c0.6,0.4,1.1,0.8,1.4,1.5c0.3,0.6,0.5,1.4,0.5,2.2C10.8,8.5,10.8,8.7,10.7,8.9z M5.1,6.1c-0.5,0.4-0.7,1-0.8,1.7h5.1C9.3,7.1,9,6.5,8.6,6.1c-0.5-0.4-1-0.6-1.7-0.6C6.2,5.5,5.6,5.7,5.1,6.1z'
      />
      <polygon fill='#003DA6' points='19.6,4.3 16.2,12.4 14.7,12.4 11.2,4.3 12.7,4.3 15.5,10.8 18.2,4.3' />
      <path
        fill='#003DA6'
        d='M27.9,8.9h-6.4c0.1,0.7,0.4,1.3,0.9,1.7s1.2,0.6,2,0.6c1,0,1.7-0.3,2.3-1l0.8,1c-0.4,0.4-0.8,0.8-1.3,1s-1.1,0.3-1.8,0.3c-0.8,0-1.6-0.2-2.2-0.5s-1.2-0.8-1.5-1.5S20,9.2,20,8.4s0.2-1.5,0.5-2.1s0.8-1.1,1.4-1.5s1.3-0.5,2-0.5s1.4,0.2,2,0.5s1.1,0.8,1.4,1.5s0.5,1.4,0.5,2.2C27.9,8.5,27.9,8.7,27.9,8.9z M22.3,6.1c-0.5,0.4-0.7,1-0.8,1.7h5.1c-0.1-0.7-0.4-1.2-0.8-1.7c-0.5-0.4-1-0.6-1.7-0.6C23.3,5.5,22.7,5.7,22.3,6.1z'
      />
      <path
        fill='#003DA6'
        d='M32.4,4.6c0.5-0.2,1.1-0.4,1.8-0.4v1.4c-0.1,0-0.2,0-0.3,0c-0.8,0-1.4,0.2-1.8,0.7c-0.4,0.5-0.7,1.2-0.7,2v4.1H30V4.3h1.4v1.4C31.6,5.2,31.9,4.8,32.4,4.6z'
      />
      <path
        fill='#003DA6'
        d='M43.3,4.3l-3.9,8.9c-0.3,0.8-0.7,1.4-1.2,1.8c-0.4,0.3-1,0.5-1.6,0.5c-0.4,0-0.8-0.1-1.1-0.2c-0.4-0.1-0.7-0.3-0.9-0.6l0.6-1.1c0.4,0.4,0.9,0.6,1.4,0.6c0.4,0,0.6-0.1,0.9-0.3c0.2-0.2,0.4-0.5,0.6-1l0.3-0.6l-3.5-8.1h1.5l2.8,6.5l2.7-6.5L43.3,4.3L43.3,4.3z'
      />
      <path
        fill='#003DA6'
        d='M51.9,1v11.4h-1.4v-1.3c-0.3,0.4-0.7,0.8-1.2,1s-1,0.4-1.6,0.4c-0.8,0-1.5-0.2-2.1-0.5s-1.1-0.8-1.4-1.5c-0.3-0.6-0.5-1.3-0.5-2.2s0.2-1.5,0.5-2.2S45,5,45.6,4.6s1.3-0.5,2.1-0.5c0.6,0,1.1,0.1,1.6,0.3s0.9,0.5,1.2,1V1H51.9L51.9,1zM49.2,10.9c0.4-0.2,0.7-0.6,1-1c0.2-0.4,0.3-0.9,0.3-1.5s-0.1-1.1-0.3-1.5s-0.5-0.8-1-1c-0.4-0.2-0.9-0.4-1.4-0.4s-1,0.1-1.4,0.4c-0.4,0.2-0.7,0.6-1,1S45,7.8,45,8.4c0,0.6,0.1,1.1,0.4,1.5c0.2,0.4,0.6,0.8,1,1s0.9,0.4,1.4,0.4C48.3,11.2,48.8,11.1,49.2,10.9z'
      />
      <path
        fill='#003DA6'
        d='M60.1,5C60.7,5.6,61,6.4,61,7.5v4.9h-1.4v-1.1c-0.2,0.4-0.6,0.7-1,0.9s-1,0.3-1.6,0.3c-0.9,0-1.6-0.2-2.1-0.7c-0.5-0.4-0.8-1-0.8-1.7s0.3-1.3,0.8-1.7s1.3-0.6,2.4-0.6h2.3V7.5c0-0.6-0.2-1.1-0.5-1.5c-0.4-0.3-0.9-0.5-1.6-0.5c-0.5,0-0.9,0.1-1.4,0.2c-0.4,0.2-0.8,0.4-1.1,0.6l-0.6-1.1c0.4-0.3,0.9-0.6,1.5-0.8c0.6-0.2,1.2-0.3,1.8-0.3C58.6,4.2,59.5,4.5,60.1,5z M58.6,11c0.4-0.3,0.7-0.6,0.9-1.1V8.8h-2.2c-1.2,0-1.8,0.4-1.8,1.3c0,0.4,0.1,0.7,0.5,1c0.3,0.2,0.7,0.4,1.3,0.4C57.8,11.4,58.2,11.3,58.6,11z'
      />
      <path
        fill='#003DA6'
        d='M70.5,4.3l-3.9,8.9c-0.3,0.8-0.7,1.4-1.2,1.8c-0.4,0.3-1,0.5-1.6,0.5c-0.4,0-0.8-0.1-1.1-0.2c-0.4-0.1-0.7-0.3-0.9-0.6l0.6-1.1c0.4,0.4,0.9,0.6,1.4,0.6c0.4,0,0.6-0.1,0.9-0.3c0.2-0.2,0.4-0.5,0.6-1l0.3-0.6l-3.5-8.1h1.5l2.8,6.5l2.7-6.5L70.5,4.3L70.5,4.3z'
      />
    </g>
    <g transform='translate(48.790388, 0.000000)'>
      <path
        fill='#003DA6'
        d='M24.4,36.1c0.9,0.3,1.9,0.3,2.8-0.2c1.9-0.9,3.8-3.3,4.8-6.2c0.7-2.1,1-4.2,0.8-6c-0.1-0.4-0.2-0.9-0.3-1.2c0.1,0,0.2-0.1,0.3-0.2c0.7-0.7,1.3-1,1.9-0.9c1,0.1,1.7,1.1,1.7,1.1l0,0c-0.8,1-1.4,2.3-1.9,3.6c-0.7,2.1-1,4.2-0.8,6c0.3,2,1.2,3.4,2.6,3.9c0.4,0.1,0.8,0.2,1.1,0.2c0.6,0,1.1-0.1,1.7-0.4c1.9-0.9,3.8-3.3,4.8-6.2c0.7-2.1,1-4.2,0.8-6c-0.3-2-1.2-3.4-2.6-3.9c-0.9-0.3-1.9-0.3-2.8,0.2c-0.5,0.2-1.1,0.6-1.6,1.1c-0.5-0.5-1.5-1.5-2.9-1.6c-1.2-0.1-2.3,0.3-3.4,1.3c-0.4-0.5-0.9-0.8-1.4-1c-0.9-0.3-1.9-0.3-2.8,0.2c-1.9,0.9-3.8,3.3-4.8,6.2c-0.7,2.1-1,4.2-0.8,6C22.1,34.2,23,35.6,24.4,36.1zM40.3,21.8c0.3-0.2,0.6-0.2,0.9-0.2c0.2,0,0.3,0,0.5,0.1c1.4,0.5,2,3.7,0.7,7.5c-0.9,2.4-2.4,4.4-3.8,5.1c-0.5,0.2-1,0.3-1.4,0.1c-1.4-0.5-2-3.7-0.7-7.5C37.3,24.4,38.8,22.4,40.3,21.8z M24.4,26.8c0.9-2.4,2.4-4.4,3.8-5.1c0.3-0.2,0.6-0.2,0.9-0.2c0.2,0,0.3,0,0.5,0.1c1.4,0.5,2,3.7,0.7,7.5c-0.9,2.4-2.4,4.4-3.8,5.1c-0.5,0.2-1,0.3-1.4,0.1C23.6,33.8,23,30.6,24.4,26.8z'
      />
      <path
        fill='#003DA6'
        d='M45.4,38.8c-0.5-0.2-1.1,0-1.3,0.5c-0.8,1.7-2.6,2.5-4.3,3c-2.3,0.6-4.7,0.8-7,0.7c-2.5,0-4.9-0.3-7.4-0.6c-0.8-0.1-1.6-0.3-2.4-0.4c-0.9-0.2-1.8-0.3-2.7-0.5c-0.9-0.1-1.7-0.2-2.6-0.3c0.8-4.6,1.4-8.8,2-12.1c0.4-1.3,0.6-2.5,0.6-3.7c0.5-2.8,0.8-4.5,0.8-4.6c0.1-0.5-0.3-1-0.8-1.1s-1,0.3-1.1,0.8c0,0,0,0.2-0.1,0.5c-0.4-0.5-0.9-0.9-1.5-1.1c-0.9-0.3-1.9-0.3-2.8,0.2C12.9,21,11,23.4,10,26.3c-0.7,2.1-1,4.2-0.8,6c0.3,2,1.2,3.4,2.6,3.9c0.9,0.3,1.9,0.3,2.8-0.2c0.8-0.4,1.6-1,2.3-1.8c-0.4,2.2-0.7,4.5-1.1,7c-2.5,0-4.7,0.5-6.7,1.3c-2.6,1.1-4.5,3-5.5,5.3c-1.2,2.7-0.8,5.5,0.8,6.8c0.9,0.7,2,1,3.1,1c0.6,0,1.3-0.1,1.9-0.3c3.3-1.1,6.1-4.7,7.7-9.9c0.2-0.5-0.1-1-0.6-1.2s-1,0.1-1.2,0.6c-1.9,6.6-5.1,8.3-6.4,8.7c-1.2,0.4-2.4,0.2-3.3-0.4c-0.8-0.6-1.1-2.5-0.2-4.6c0.8-1.8,2.4-3.4,4.5-4.3c3.1-1.4,6.6-1.3,9.9-0.8c1.2,0.2,2.4,0.5,3.6,0.7c1.8,0.3,3.6,0.6,5.5,0.7c2.2,0.2,4.4,0.3,6.6,0.2c2.1-0.1,4.2-0.4,6.1-1.2c1.7-0.6,3.2-1.7,4.1-3.2c0.1-0.1,0.1-0.3,0.2-0.4C46.1,39.6,45.9,39,45.4,38.8z M13.9,34.2c-0.5,0.2-1,0.3-1.4,0.1c-1.4-0.5-2-3.7-0.7-7.5c0.9-2.4,2.4-4.4,3.8-5.1c0.3-0.2,0.6-0.2,0.9-0.2c0.2,0,0.3,0,0.5,0.1c0.9,0.3,1.4,1.7,1.4,3.5c-0.2,1-0.4,2.2-0.6,3.5c-0.1,0.2-0.1,0.3-0.2,0.5C16.8,31.5,15.3,33.5,13.9,34.2z'
      />
      <path
        fill='#003DA6'
        d='M70.6,2.2C69.1,1,67,0.8,65,1.6c-1.7,0.7-3.3,2.1-4.6,4.1c-1.3,2.1-2.3,4.7-2.9,7.9c-1.3,0-2.6-0.2-4.1-0.4c-0.4-0.1-5.9-1.2-11.9-1.4C33,11.4,27.9,13,26.3,16.4c-0.2,0.5,0,1.1,0.5,1.3c0.5,0.2,1.1,0,1.3-0.5c0.6-1.4,2.3-2.4,4.8-3c2.2-0.5,5.1-0.7,8.5-0.5c6,0.1,11.6,1.3,11.7,1.3l0,0c1.4,0.2,2.8,0.4,4.1,0.4c-0.3,2.1-0.6,3.9-0.9,5.5c-0.4-0.5-0.9-0.9-1.5-1.1C54,19.5,53,19.6,52,20c-1.9,0.9-3.8,3.3-4.8,6.2c-0.7,2.1-1,4.2-0.8,6c0.3,2,1.2,3.4,2.6,3.9c0.9,0.3,1.9,0.3,2.8-0.2c0.9-0.4,1.9-1.2,2.7-2.3c0.1,0.9,0.3,1.8,0.8,2.3c0.3,0.3,0.8,0.5,1.3,0.5c0.1,0,0.3,0,0.4,0c0.5-0.1,0.9-0.6,0.8-1.1s-0.6-0.9-1.1-0.8l0,0c-0.1-0.1-0.3-0.6-0.2-2c0-1,0.2-1.9,0.2-1.9l0,0c0,0,0.1-0.6,0.3-1.8c0.3-1.1,0.5-2.3,0.6-3.4c0.4-2.4,0.9-5.6,1.6-10c2.3-0.1,4.4-0.6,6.3-1.4c2.7-1.2,4.8-3.2,5.9-5.6C72.4,5.9,72.2,3.4,70.6,2.2z M55.6,25.2c-0.2,1.3-0.4,2.4-0.5,3.2c-0.1,0.2-0.1,0.5-0.2,0.7c-0.9,2.4-2.4,4.4-3.8,5.1c-0.5,0.2-1,0.3-1.4,0.1c-1.4-0.5-2-3.7-0.7-7.5c0.9-2.4,2.4-4.4,3.8-5.1c0.3-0.2,0.6-0.2,0.9-0.2c0.2,0,0.3,0,0.5,0.1C55.1,22,55.6,23.3,55.6,25.2z M69.6,7.6c-0.5,1.1-1.8,3.2-4.9,4.6c-1.5,0.7-3.2,1.1-5.1,1.3c0.7-3.2,2.3-8.6,6.2-10.1c0.4-0.3,1-0.4,1.5-0.4c0.8,0,1.5,0.2,2.1,0.7C70.1,4.2,70.4,5.7,69.6,7.6z'
      />
    </g>
  </svg>
)
export default Logo
