import {
  causeDetail, home, receiptScan, causeLanding, coupons, couponsDigital, siteDown,
  articleDetail, articleListing, about, siteMap, rewardsDashboard, rewardsListing, signUp,
  search, messages, earnPoints, PGBrands, campaign, authError,
} from '../../constants/page-source'

module.exports = {
  [home]: {
    Title:
      'text-sign-head w-full xl:w-8/12 mx-auto text-center text-24 font-montserratSemiBold font-semibold px-10 leading-leading29 pb-05 lg:text-32 lg:leading-noImgLh pt-20 md:pt-0 lg:pb-20 lg:pt-20',
    'Sub Title':
      'text-sign-head px-05 md:px-02 w-11/12 md:w-6/12 lg:w-10/12 xl:w-8/12 leading-cardline leading-wider lg:leading-widest mx-auto font-light text-base lg:text-lg font-montserratlight font-light text-sign-head text-center',
    'home-join-us':
      'font-montserratlight font-light home-join-us text-center lg:text-18 px-20 lg:leading-widest lg:pb-25 pb-20 leading-lightmedium',
    'home-article-title-richtext':
      'w-full text-sign-head text-24 md:leading-sitemap32 lg:leading-noImgLh lg:text-38 text-center font-montserratSemiBold font-semibold leading-leading29 px-20 pt-25 lg:pt-50 mb-15px lg:mb-20px',
    'home-get-started':
      'w-full text-center font-montserratSemiBold font-semibold lg:text-38 text-24 leading-leading29 lg:leading-noImgLh lg:pt-50 lg:pb-45 pt-35 pb-25',
    'home-intro-title':
      'home-intro-title container mx-auto pl-90 text-white font-montserratlight font-light text-16 lg:text-18 leading-points16 lg:leading-lightmedium',
    'card-text':
      'font-montserratlight font-light text-14 leading-points18 px-10 md:px-12',
  },
  [articleListing]: {
    'home-join-us':
      'font-montserratlight font-light home-join-us text-center lg:text-18 lg:leading-widest lg:pt-30 lg:pb-25 pt-22 pb-15 leading-lightmedium',
  },
  [articleDetail]: {
    Description:
      ' w-full relative font-montserratlight font-light text-16 lg:text-18 leading-lightmedium lg:leading-widest text-left py-17 article-detail-listingUl article-detail-listingOl',
    DescriptionUnderlineLink:
      ' w-full font-montserratlight font-light DescriptionUnderlineLink text-16 lg:text-18 leading-lightmedium lg:leading-widest text-left py-17 article-detail-listingUl article-detail-listingOl',
    DescriptionTopBorder:
      ' w-full font-montserratlight font-light text-16 border-t-half border-faq lg:text-18 leading-lightmedium lg:leading-widest text-left py-17 pt-35 mt-10px article-detail-listingUl article-detail-listingOl',
    DescriptionChoice:
      ' w-full font-montserratlight font-light text-16 lg:text-18 leading-lightmedium lg:leading-widest text-left py-17 article-detail-listingUl article-detail-listing-choice',
    DescriptionFeeding:
      ' w-full font-montserratSemiBold font-semibold text-16 lg:text-18 leading-lightmedium lg:leading-widest text-left py-17 article-detail-listingUl',
    shortDescription:
      ' w-full font-montserratlight font-light text-16 lg:text-18 leading-lightmedium lg:leading-widest text-left py-17 article-detail-listingUl border-b-half border-faq',
    List:
      ' w-full font-montserratlight font-light text-16 lg:text-18 leading-lightmedium lg:leading-widest text-left article-detail-listingNestedUl',
    Bold:
      ' w-full font-montserratSemiBold font-semibold text-16 lg:text-18 leading-lightmedium lg:leading-widest text-left article-detail-relief py-45 border-t-half border-faq ',
    'Long Text':
      ' w-full font-montserratlight font-light text-16 lg:text-18 leading-lightmedium lg:leading-widest text-left pt-20 pb-31 md:pb-31 article-detail-listingUl border-b-half border-faq',
    'Supporting Text':
      'w-full md:w-5/12 lg:w-6/12 bg-white shadow-aboutCard md:shadow-none md:float-left font-montserratlight font-light text-16 lg:text-18 leading-lightmedium lg:leading-relaxed text-left md:pr-20 lg:pr-60 px-20 pb-20 md:pb-0 md:px-0',
    supportingFoodText:
      'supportingFoodText font-montserratBold font-bold justify-center lg:text-28 text-18 text-center pb-15 md:pb-0 leading-wider flex flex-wrap items-center h-full md:border-t-half md:border-b-half',
    foodWasteText:
      'foodWasteText text-primary font-montserratBold font-bold justify-center px-25 md:px-0 tracking-2 lg:tracking-3 lg:text-28 text-18 text-center leading-wider lg:leading-wider flex flex-wrap items-center h-full',
    recyclelabels:
      'w-full md:w-9/12 mx-auto recyclelabels text-primary font-montserratBold font-bold justify-center px-25 md:px-0 tracking-2 lg:tracking-3 lg:text-28 text-18 text-center leading-wider block',
    'article-description-champion':
      ' w-full font-montserratlight font-light text-16 lg:text-18 leading-lightmedium lg:leading-relaxed text-left py-20 md:pb-05 article-detail-listingUl',
  },
  [causeDetail]: {
    Title:
      'cause-offerRules-wrapper text-base leading-leading28 lg:text-18 lg:leading-leading35 font-montserratSemiBold font-semibold',
    'Sub Title':
      'text-csdw-communities sparking-content CSDW w-full font-montserratlight font-light lg:text-18 leading-leading28 lg:leading-leading35 pb-10 lg:pb-15',
    'Pampers cause Sub Title':
      'text-csdw-communities sparking-content CSDW w-full font-montserratlight font-light lg:text-18 leading-leading28 lg:leading-leading35 pb-25 md:pb-30px lg:pb-47px aboveDeviderText',
    Description: 'lg:pt-30 md:pt-10',
    'Supporting Text':
      'cause-carousel-wrapper cause-video-wrapper text-xs font-montserratlight font-light leading-seller18 pt-45 md:pt-40 lg:pt-55',
    Bold:
      'text-base md:inline-block font-montserratlight font-light pb-35 pt-30 md:pt-45 lg:pt-25 md:pb-30 lg:pb-35 leading-leading28  lg:text-lg lg:leading-leading35',
    'Long Text': 'cause-csdw-text',
    'home-join-us':
      'earnPointsReferFriend getStartText bg-white px-06 font-montserratlight font-light home-join-us text-center lg:text-18 lg:leading-widest lg:pt-0 xl:pt-30 lg:pb-05 xl:pb-20 pt-22 pb-15 leading-lightmedium',
    'summary-join-us':
      'summary-richtext font-montserratlight font-light home-join-us text-center lg:text-18 lg:leading-widest lg:pt-30 pt-22 leading-lightmedium',
  },
  [about]: {
    Title:
      'font-montserratSemiBold font-semibold text-sign-head lg:text-21 lg:leading-lightmedium',
    'List Title': 'font-montserratlight font-light lg:text-18 lg:leading-wider',
    'about-good-description':
      'greaterGoodBtnTxt w-10/12 md:w-8/12 flex justify-center lg:w-full text-16 lg:text-18 text-center mx-auto px-20 md:px-50 pb-20 lg:pb-23 lg:px-40 xxl:px-20 font-montserratlight font-light leading-normal lg:leading-lightmedium lg:pt-25 md:pt-11 pt-20',
    'about-goal-description':
      'w-full about-extra-perks mx-auto text-16 xl:text-17half text-center mx-auto md:pb-15 px-16 md:px-50 md:pb-20 lg:pb-23 lg:px-40 xxl:px-20 font-montserratlight font-regular leading-leading17 lg:leading-ultralight',
    'about-feature-content':
      'text-16 lg:text-20 text-left leading-normal lg:leading-relaxed font-montserratlight font-light px-20 md:px-75 lg:px-200 md:pb-20 mx-auto lg:w-12/12',
    'Long Text':
      'w-full text-16 lg:text-18 text-center mx-auto md:pb-15 px-25 md:px-20 pb-20 lg:pb-23 lg:px-40 xxl:px-20 font-montserratlight font-light leading-normal lg:leading-widest',
  },
  [coupons]: {
    'coupon-empty-basket':
      'coupon-empty-basket lg:text-18 leading-lightmedium font-montserratlight font-light pb-20 pt-30 lg:pt-45',
    'coupon-saved':
      'coupon-saved lg:text-18 lg:leading-widest leading-lightmedium font-montserratlight font-light py-20 px-45 md:px-75 lg:px-100',
    'printCoupons-Title': '',
    'get-help-now':
      'intro-coupons-text bg-white w-9/12 md:w-full mx-auto font-montserratSemiBold font-semibold text-center pt-25 md:pt-35 xl:text-18 leading-ultralight xl:leading-leading22',
    'intro-coupons-text':
      'intro-coupons-text w-full text-16 xl:text-18 text-center leading-lightmedium font-normal md:px-03 xl:px-30 xxl:px-40 xl:pb-05',
    'coupons-accordian': ' ',
    'coupons-getstarted': 'font-bold pt-20 leading-lightmedium text-12',
    'coupons-offersSubject': 'font-light pb-10 leading-lightmedium text-12',
  },
  [couponsDigital]: {
    'coupon-empty-basket':
      'coupon-empty-basket lg:text-18 leading-lightmedium font-montserratlight font-light pb-20 pt-30 lg:pt-45',
    'coupon-saved':
      'coupon-saved lg:text-18 lg:leading-widest leading-lightmedium font-montserratlight font-light py-20 px-45 md:px-75 lg:px-100',
    'printCoupons-Title': '',
    'get-help-now':
      'intro-coupons-text intro-digicoupons-text w-12/12 md:w-full mx-auto text-center pt-25 xl:text-18 font-bold leading-ultralight xl:leading-lightmedium',
    'intro-coupons-text':
      'intro-coupons-text text-16 xl:text-18 text-center leading-lightmedium font-montserratlight font-light md:px-03 xl:px-30 xxl:px-40',
    'accordian-subText':
      'accordian-subText w-full px-15 xl:px-33 pt-02 mx-auto',
    'accordian-subTextHide':
      'hidden w-11/12 md:w-8/12 xl:w-9/12 xl:px-33 pt-02 mx-auto',
    'manage-coupons-test':
      'coupons-test text-14 leading-seller18 md:text-16 md:leading-ultralight pb-25 md:pb-35',
    'digitalCoupons-accordian': ' ',
  },
  [receiptScan]: {
    Bold:
      'w-11/12 md:w-10/12 lg:w-10/12 text-left md:text-center mx-auto font-montserratSemiBold font-semibold text-14 md:text-13 lg:text-21 leading-leading20 md:leading-seller18 lg:leading-lightmedium md:pt-15 px-05 lg:pt-20 md:px-0 xxl:px-20',
    'List-Title':
      'how-to-scan-text-list w-11/12 md:w-10/12 lg:w-12/12 md:w-full mx-auto text-left md:text-center flex flex-wrap justify-center font-montserratlight font-light text-14 md:text-17 leading-leading20 md:pt-15 px-05 lg:pt-20 md:px-0 pb-10 md:pb-20',
    'uploadsuccess-screen-text':
      'w-10/12 md:w-11/12 lg:w-full uploadsuccess-text mx-auto font-montserratlight font-light text-16 lg:text-18 leading-lightmedium lg:leading-widest px-0 md:px-10 lg:max-w-3xl lg:px-10px xl:px-50',
    'uploading-screen-text':
      'uploading-screen-text w-9/12 md:w-8/12 mx-auto font-montserratlight font-light text-14 lg:text-18 lg:leading-lightmedium lg:px-40 pt-10 lg:pt-10',
    'uploaderror-screen-text':
      'w-11/12 md:w-10/12 lg:w-10/12 mx-auto font-montserratlight font-light text-16 lg:text-18 lg:leading-lightmedium lg:px-40',
    retailersSearchText:
      'retailersSearchText w-full md:w-9/12 lg:w-5/12 mx-auto font-montserratlight font-light text-14 pt-37 md:pt-30px lg:pt-53 px-30 leading-leading20',
    brandsSearchText:
      'w-11/12 mx-auto font-montserratlight font-light text-14 pt-35 lg:pt-50 px-15 md:px-0 leading-leading20',
    'teal-4cc5d9': 'text-teal',
    'darkTeal-197b8b': 'text-darkSecondaryTeal',
    'blue-267ea6': 'text-blue',
    'red-dd3447': 'text-warning',
    'darkBlue-003da6': 'text-darkBlue',
    'darkGreen-438447': 'text-success',
    'pink-c7008e': 'text-info',
    'turquoise-218372': 'text-secondary',
    'darkGray-5e5e5e': 'text-input-br',
  },
  [causeLanding]: {
    'cause-landing-Offer-rules':
      'font-montserratlight font-light text-sign-head text-center text-sm leading-leading28 md:text-left mt-20px md:mt-5px lg:mt-0 md:text-14 md:leading-sitemap32',
    'home-join-us':
      'font-montserratlight font-light home-join-us bg-white text-center lg:text-18 lg:leading-widest lg:pt-30 lg:pb-25 pt-22 pb-15 leading-lightmedium',
  },
  [rewardsListing]: {
    'rewards-offer-rules':
      'text-xs leading-points16 font-montserratlight font-light text-sign-head md:pl-15 pt-10',
  },
  [rewardsDashboard]: {
    'RewardsCatalog-Splash':
      'RewardsCatalog-Splash text-base leading-lightmedium relative  bg-rewardsBg font-montserratlight font-light w-11/12 pt-20 lg:pt-30 mx-auto text-center lg:text-lg lg:leading-widest pb-05 lg:pb-0 lg:w-12/12',
  },
  [siteDown]: {
    'site-down-page':
      'site-down-page w-11/12 md:w-8/12 lg:w-7/12 mx-auto px-10 lg:px-35 xxl:px-65 pb-30 text-white text-center font-montserratSemiBold font-semibold text-14 md:text-16 lg:text-18 lg:leading-normal',
  },
  [authError]: {
    'auth-error-page':
      'w-11/12 md:w-8/12 lg:w-7/12 mx-auto px-10 lg:px-35 xxl:px-65 pb-30 text-white text-center font-montserratSemiBold font-semibold text-14 md:text-16 lg:text-18 lg:leading-normal',
  },
  [siteMap]: {
    'sitemap-Title':
      'sitemap-Title pt-40 pb-10 font-bold text-17half leading-leading21 siteSettingdIndent',
  },
  [signUp]: {
    Title: 'signupSuccesMessageTitle',
    'Sub Title':
      'signupSuccesMessageSubTitle signupSuccesMessageLink  font-montserratlight font-light text-base leading-lightmedium w-10/12 md:w-7/12 lg:w-6/12 xl:w-5/12 xxl:w-4/12 md:px-20 mx-auto pb-15 md:pb-20 lg:text-lg lg:leading-widest',
    campaignTitle:
      'signupSuccesMessageLink font-montserratlight font-light campaignTitle xl:text-18 text-16 xl:leading-widest leading-lightmedium max-w-lg md:max-w-md lg:max-w-lg mx-auto text-center px-0 xl:px-09',
    campaignOlayTitle:
      'signupSuccesMessageLink font-montserratlight font-light campaignTitle xl:text-18 md:text-16 text-14 xl:leading-widest md:leading-lightmedium leading-light max-w-lg md:max-w-md lg:max-w-lg mx-auto text-center px-0 xl:px-09',
    surveyThankyou: ' font-400 w-11/12 md:w-9/12 xl:w-9/12 mx-auto',
    campaignPrivacyText:
      'font-montserratlight block md:hidden sub-Text px-10 xl:px-30 pt-05 pb-15 font-light text-14 left-2',
    campaignOfferText:
      'font-montserratlight text-16 leading-lightmedium block sub-Text px-10 xl:px-30 pb-15 font-light text-14 left-2',
    footerUp:
      'w-11/12 campaignFooter md:w-10/12 lg:w-6/12 mx-auto text-center text-14 lg:text-base font-montserratlight font-light px-07 pb-50 pt-05 md:pt-0 md:pb80 lg:pb70 md:px-14 lg:px-10 leading-normal text-input-br',
    campaignDontHaveAccountText:
      'text-center block text-sm lg:text-base pt-20 md:pt-25 leading-loose text-input-br accountInfo lg:max-w-lg mx-auto xl:px-09 font-montserratSemiBold font-semibold',
    campaignGetStartedText:
      'text-center block text-sm lg:text-18 leading-lightmedium leading-loose accountInfo lg:max-w-lg mx-auto xl:px-09 font-montserratSemiBold font-semibold',
    campaignGetStartedOlayText:
      'text-center block text-sm lg:text-18 leading-lightmedium leading-loose accountInfo lg:max-w-lg mx-auto xl:px-09 font-montserratSemiBold font-bold',
    campaignGetStartedOlaySuperSerumText:
      'text-center block capitalize text-sm lg:text-18 leading-lightmedium leading-loose accountInfo lg:max-w-lg mx-auto xl:px-09 font-montserratSemiBold font-bold text-black',
    campaignSweepstakesImageHeading: 'w-full text-center px-10 text-24 xl:text-32 pb-20 xl:pb-50 text-sign-head leading-widest font-bold',
    olaySuperSerumoffersSubject: 'font-medium xl:w-9/12 mx-auto pb-10 leading-lightmedium text-16',
  },
  [search]: {
    'search-callout':
      'w-10/12 md:w-full px-10 font-montserratSemiBold font-semibold pb-35 text-center xl:text-38 text-30 leading-wider xl:leading-leading42',
    'message-subject': 'message-subject truncate w-159 md:w-420',
  },
  [messages]: {
    'message-subject': 'message-subject truncate w-159 md:w-420',
    'message-list': 'message-list py-05',
  },
  [earnPoints]: {
    'featured-promotions':
      'w-full text-center font-montserratSemiBold font-semibold text-24 xl:text-38 leading-leading29 xl:leading-leading42 pt-20 pb-15 md:pb-35 xl:pt-30 xl:pb-30',
    'feature-earnpoints':
      'w-full text-center font-montserratSemiBold font-bold text-24 pb-25 xl:text-32 leading-leading29 xl:leading-leading42',
    'feature-takeQuiz':
      'w-full text-center font-montserratSemiBold font-semibold text-30 pb-10 leading-wider md:text-24 xl:text-38 md:leading-widest xl:leading-leading42',
    'feature-text':
      'w-full text-center text-16 md:text-14 xl:text-18 pb-25 leading-lightmedium md:leading-light xl:leading-widest px-45 md:px-0 md:pb-25 xl:pb-35',
  },
  [PGBrands]: {
    'pgbrands-join-us':
      'font-montserratlight font-light pgbrands-join-us text-center lg:text-18 lg:leading-widest lg:pt-30 lg:pb-25 pt-22 pb-15 leading-lightmedium',
  },
  [campaign]: {
    Blue: 'text-primary',
    IOHBlue: 'text-IOHBlue',
    Green: 'text-success',
    IOHGreen: 'text-darkGreen',
    Red: 'text-warning',
    Teal: 'text-secondary',
    campaignTitle: '',
  },
  default: ' default-richtext ',
}
