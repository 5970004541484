import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import Markdown from 'react-markdown'
import ReceiptScanStore from '../../containers/ReceiptScan/ReceiptScan.store'

function Typography(props) {
  const {
    className, markdown, pageSource, parseHTML, behavior, ...other
  } = props

  const handleClose = () => {
    if (pageSource === 'datePicker') {
      ReceiptScanStore.setWidgetVisibility(false)
    }
  }

  return (
    <Markdown
      className={className}
      source={markdown}
      renderers={{
        link: ({ href, children }) => {
          let target
          if (/^http/.test(href)) target = '_blank'
          return (
            <Link
              href={href}
              passHref
              target={target}
              role='note'
              aria-label={(children && children[0]?.props?.value) || href}
              rel={target === '_blank' && 'noopener'}
              onClick={() => handleClose()}
              prefetch={false}
            >
              {children}
            </Link>
          )
        },
      }}
      escapeHtml={!parseHTML}
      {...other}
    />
  )
}

Typography.propTypes = {
  /**
   * The content of the component.
   */
  /**
   * @ignore
   */
  className: PropTypes.string,

  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   * By default, it maps the variant to a good default headline component.
   */
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

  /**
   * The markdown text that will be converted to HTML
   */
  markdown: PropTypes.string,

  /**
   * a "Flase" value will disable parsing Raw HTML
   */
  parseHTML: PropTypes.bool,
  href: PropTypes.string,
  children: PropTypes.node,
  pageSource: PropTypes.string,
  behavior: PropTypes.string,
}

Typography.defaultProps = {
  markdown: '',
  className: '',
  component: 'div',
  parseHTML: true,
  href: null,
  children: null,
  pageSource: '',
  behavior: null,
}

export default Typography
