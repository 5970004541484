import React from 'react'

export default (active, className) => (
  <svg
    width='22px'
    height='22px'
    viewBox='0 0 22 22'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >

    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-1017.000000, -1081.000000)'>
        <g transform='translate(1017.000000, 1081.000000)'>
          <circle fill='#003CA5' cx='11' cy='11' r='11' />
          <path d='M9.96908213,4.88888889 L11.7593129,4.88888889 C11.8649674,4.88888889 11.9506173,4.97453876 11.9506173,5.08019324 L11.9506173,17.7346216 C11.9506173,17.8402761 11.8649674,17.9259259 11.7593129,17.9259259 L9.96908213,17.9259259 C9.86342765,17.9259259 9.77777778,17.8402761 9.77777778,17.7346216 L9.77777778,5.08019324 C9.77777778,4.97453876 9.86342765,4.88888889 9.96908213,4.88888889 Z' fill='#ECEDEE' transform='translate(10.864198, 11.407407) rotate(90.000000) translate(-10.864198, -11.407407) ' />
        </g>
      </g>
    </g>
  </svg>
)
