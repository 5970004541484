module.exports = {
  variants: {
    paragraphModal: {
      headerReward: 'headerReward',
      layoutError: 'layoutError',
      layoutRateQuoteLimitExceededError: 'layoutRateQuoteLimitExceededError',
      sessionExpired: 'sessionExpired',
      sitemapLogout: 'sitemapLogout',
      usermenuLogout: 'usermenuLogout',
      accountSetupIneligiblePromoContent: 'accountSetupIneligiblePromoContent',
      accountSetupCascadeIneligiblePopup: 'accountSetupCascadeIneligiblePopup',
      accountSetupIneligiblePromoForSpecialSample: 'accountSetupIneligiblePromoForSpecialSample',
      rewardDetailRedeemFailure: 'rewardDetailRedeemFailure',
      rewardOptoutModal: 'rewardOptoutModal',
      accountSetupAlreadyAccountAvailable: 'accountSetupAlreadyAccountAvailable',
      campaignLandingProfileIncomplete: 'campaignLandingProfileIncomplete',
      updatePasswordInValidToken: 'updatePasswordInValidToken',
      updatePasswordChangedSuccessfully: 'updatePasswordChangedSuccessfully',
      accountSetupDigitalCouponsThankyou: 'accountSetupDigitalCouponsThankyou',
      cookieDisabled: 'cookieDisabled',
      digitalCouponUnlinkingConfirmation: 'digitalCouponUnlinkingConfirmation',
      messageResendEmailSuccess: 'messageResendEmailSuccess',
      pointsHistoryErrorResendNoContent: 'pointsHistoryErrorResendNoContent',
      couponCardBasketLengthError: 'couponCardBasketLengthError',
      receiptScanModalShow: 'receiptScanModalShow',
      pointsHistorySuccessResendPopUp: 'pointsHistorySuccessResendPopUp',
      pointsHistoryResendMailError: 'pointsHistoryResendMailError',
      earnPointsOutOfStock: 'earnPointsOutOfStock',
      receiptWidgetDeleteConfirmation: 'receiptWidgetDeleteConfirmation',
      receiptWidgetMaximumUpload: 'receiptWidgetMaximumUpload',
      receiptWidgetInvalidFilename: 'receiptWidgetInvalidFilename',
      receiptWidgetInvalidExtension: 'receiptWidgetInvalidExtension',
      receiptWidgetFileSizeExceeded: 'receiptWidgetFileSizeExceeded',
      accountSetupInvalidProgramIdPopup: 'accountSetupInvalidProgramIdPopup',
    },
    birthDayModal: {
      birthdayPromoModal1: 'birthdayPromoModal1',
      birthdayPromoModal2: 'birthdayPromoModal2',
      halfBirthdayPromoModal1: 'halfBirthdayPromoModal1',
      halfBirthdayPromoModal2: 'halfBirthdayPromoModal2',
      showTwogetherThanksPopUp: 'showTwogetherThanksPopUp',
      showCincinnatiThanksPopUp: 'showCincinnatiThanksPopUp',
      aprilFoolPromoModal1: 'aprilFoolPromoModal1',
    },
    layoutOptOutError: 'layoutOptOutError',
    layoutCountryRestrictedError: 'layoutCountryRestrictedError',
    layoutNetworkError: 'layoutNetworkError',
    layoutCompleteProfileEndCard: 'layoutCompleteProfileEndCard',
    rewardDetailRedemptionEmailStatus: 'rewardDetailRedemptionEmailStatus',
    couponCascadeThanksPopup: 'couponCascadeThanksPopup',
    revtraxDigitalCouponLogin: 'revtraxDigitalCouponLogin',
    contactShowChatPopup: 'contactShowChatPopup',
    earnPointsRewardMessageModal: 'earnPointsRewardMessageModal',
    couponsPopup: 'couponsPopup',
    loginForm: 'showLoginForm',
  },
}
