import React from 'react'

export default (active, className) => (
  <svg width='22px' height='4px' viewBox='0 0 22 4' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' className={className}>
    <g id='minusPage-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='PGGE-Account-open-state-Desktop-minus' transform='translate(-783.000000, -1443.000000)' fill='#A400A6'>
        <g id='My-Fav-Brands-minus' transform='translate(16.000000, 649.000000)'>
          <g id='Group-2-minus' transform='translate(602.000000, 784.000000)'>
            <g id='minusIcons-/-Plus' transform='translate(165.000000, 10.166667)'>
              <path d='M22,0.434782609 L22,3.23188406 C22,3.47200786 21.8053412,3.66666667 21.5652174,3.66666667 L0.434782609,3.66666667 C0.194658804,3.66666667 2.38031816e-13,3.47200786 2.38031816e-13,3.23188406 L2.38031816e-13,0.434782609 C2.38031816e-13,0.194658804 0.194658804,-2.10138171e-14 0.434782609,-2.11134383e-14 L21.5652174,-2.24073043e-14 C21.8053412,-2.25069254e-14 22,0.194658804 22,0.434782609 Z' id='Combined-Shape-minus' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
