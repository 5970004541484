import { makeAutoObservable } from 'mobx'
import LoginStore from '../../components/UserManagement/Login-store'
import { loginContextText } from '../../constants/texts'
import { storageAvailable } from '../../adapters/serverside.adapters'

class ReceiptScanStore {
  constructor() {
    makeAutoObservable(this)
  }

  showWidget = false

  pendingForLogin = false

  retailerId = null

  brandIds = ''

  receiptDate = null

  brandNames = ''

  setWidgetVisibility = (show) => {
    if (!storageAvailable('localStorage')) {
      LoginStore.showCookiesDisabledodal = true
      return
    }

    if (LoginStore.rewardStatus) {
      this.showWidget = show
    } else {
      LoginStore.pendingFor = '?receiptscan'
      localStorage.setItem('pendingfor', '?receiptscan')
      LoginStore.showSignInPopup = true
      LoginStore.context = loginContextText.receiptScan
    }
  }
}
export default new ReceiptScanStore()
