import React from 'react'

export default (active, className) => (
  <svg
    width='26px'
    height='25px'
    viewBox='0 0 26 25'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g
        transform='translate(0.865972, 0.000000)'
        stroke='#4CC5D9'
        strokeWidth='1.5'
      >
        <g>
          <line
            x1='0.520833333'
            y1='0.520833333'
            x2='24.4791667'
            y2='24.4791667'
          />
          <line
            x1='0.520833333'
            y1='0.520833333'
            x2='24.4791667'
            y2='24.4791667'
            transform='translate(12.500000, 12.500000) scale(-1, 1) translate(-12.500000, -12.500000) '
          />
        </g>
      </g>
    </g>
  </svg>
)
