/* eslint-disable import/no-cycle */
import jwt_decode from 'jwt-decode'
import { standardEventForCommonError } from '../containers/AuthContainer/standardEventErrorLog'
import { backend } from '../constants/urls'
import encrypt from '../helpers/encryption.helper'
import LoginStore from '../components/UserManagement/Login-store'
import { defaultCharity } from '../constants/texts'
import telemetry from './telemetry.adapter'
import { STATUS_CORS } from '../constants/status-code'

let baseUrl = ''
const API_RETRY_ATTEMPTS = parseInt(process.env.API_RETRY_ATTEMPTS, 10)

switch (process.env.BACKEND_ENVIRONMENT) {
  case 'dev':
    baseUrl = 'https://dev-eapi.pggoodeveryday.com/brandbuilding/consumer/v1/pgge/dev/us'
    break
  case 'qa':
    baseUrl = 'https://stage-eapi.pggoodeveryday.com/brandbuilding/consumer/v1/pgge/us'
    break
  case 'production':
    baseUrl = 'https://eapi.pggoodeveryday.com/brandbuilding/consumer/v1/pgge/us'
    break
  case 'uat':
    baseUrl = 'https://uat-eapi.pggoodeveryday.com/brandbuilding/consumer/v1/pgge/uat/us'
    break
  default:
    break
}

export const storageAvailable = (type) => {
  let storage
  try {
    storage = window[type]
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return (
      e instanceof DOMException
      && e.name === 'QuotaExceededError'
      && storage
      && storage.length !== 0
    )
  }
}

/*
 * Function to return JWT token status to call PGGE Protected Api
 * Validate the JWT Auth token if the token is available in the Local Storage
 */
export const validateJwtToken = async () => {
  if (typeof window !== 'undefined') {
    const jwtToken = localStorage.getItem('JWTtoken')
    if (jwtToken) {
      const decodedToken = jwt_decode(jwtToken)
      if (decodedToken) {
        const currentTimeInSeconds = Math.round(new Date() / 1000)
        // Token should be called out as expired if the difference is 30 Seconds or less
        const differenceInSeconds = decodedToken.exp - currentTimeInSeconds
        if (differenceInSeconds > 30) {
          return true
        }
      }
    }
  }
  telemetry.trackEvent('jwtTokenExpired')
  return false
}

/*
 * Function to return API headers required to call PGGE Api
 * Validate the JWT Auth token if the token is available in the Local Storage
 */
const getRequestHeaders = (origin) => {
  let headers = {
    'Content-Type': 'application/json',
    locale: 'en-US',
    'Ocp-Apim-Subscription-Key': process.env.ENTERPRISE_APIM_SUBSCRIPTION_KEY,
  }
  if (typeof window !== 'undefined' && storageAvailable('localStorage')) {
    const jwtToken = localStorage.getItem('JWTtoken')
    if (jwtToken) {
      headers = {
        ...headers,
        Authorization: `Bearer ${jwtToken}`,
      }
      return headers
    }
  }
  if (typeof window === 'undefined' && origin) {
    headers = {
      ...headers,
      origin: process.env.SITE_DOMAIN,
    }
  }
  return headers
}

let RESPONSE_STATUS = [200, 403, 406, 417, 422, 429]
let attempts = 0
const getResponse = async (
  requestType,
  requestMethod,
  requestBody,
  origin = false,
) => {
  let response
  if (requestType === backend.ChangePasswordUrl) RESPONSE_STATUS.push(400)
  else if (requestType === backend.sendRewardMail) RESPONSE_STATUS.push(204)
  else RESPONSE_STATUS = [200, 403, 406, 417, 422, 429]

  try {
    response = await fetch(`${baseUrl}/${requestType}`, {
      method: requestMethod,
      headers: getRequestHeaders(origin),
      credentials: 'include',
      body: JSON.stringify(requestBody),
    })

    await standardEventForCommonError(
      requestType,
      getRequestHeaders(origin),
      requestBody,
      response,
    )

    const successCall = RESPONSE_STATUS.includes(response.status)
    if (!successCall && attempts < API_RETRY_ATTEMPTS) {
      attempts += 1
      LoginStore.showNetworkErrorModal = true
      if (requestMethod.toUpperCase() === 'POST') {
        LoginStore.networkErrorFunc = getResponse.bind(
          this,
          requestType,
          requestMethod,
          requestBody,
          origin,
        )
      }
      return null
    }

    if (attempts >= API_RETRY_ATTEMPTS) {
      LoginStore.showNetworkErrorModal = false
      LoginStore.showErrorModal = true
    }
    return response
  } catch (error) {
    if (attempts >= API_RETRY_ATTEMPTS) {
      LoginStore.showNetworkErrorModal = false
      LoginStore.showErrorModal = true
      return null
    }

    LoginStore.showNetworkErrorModal = true
    LoginStore.setErrorModalCode(STATUS_CORS)
    telemetry.logCustomEvent('error_CORS')

    if (requestMethod.toUpperCase() === 'POST') {
      LoginStore.networkErrorFunc = getResponse.bind(
        this,
        requestType,
        requestMethod,
        requestBody,
        origin,
      )
    }
    return null
  }
}

export const loginAccount = async (authCode, campaignName, mediaCampaignId) => {
  const response = getResponse(
    backend.loginUrl,
    'POST',
    mediaCampaignId
      ? { authCode, campaignName, mediaCampaignId }
      : { authCode, campaignName },
  )
  return response
}

export const enrollPGGEUserWithWelcomeEmail = async (
  charityCauseId = null,
  incentiveId = null,
) => {
  const response = await getResponse(
    backend.enrollPGGEUserWithWelcomeEmailUrl,
    'POST',
    {
      charityID: charityCauseId || defaultCharity,
      incentiveId,
    },
  )
  return response
}

export const updateProfile = (data) => {
  const response = getResponse(backend.updateProfileUrl, 'POST', {
    zipcode: data?.addressPostalCode?.slice(0, 5),
    gender: data?.gender,
    birthdate: data?.birthDate?.toString(),
    birthmonth: data?.birthMonth,
    birthyear: data?.birthYear?.toString(),
    guid: data?.guid,
  })
  return response
}

export const updateAddress = (data) => {
  const response = getResponse(backend.updateAddress, 'PUT', {
    firstname: data.firstName,
    lastname: data.lastName,
    addressStreet1: data.addressStreet1,
    addressStreet2: data.addressStreet2,
    city: data.addressCity,
    zipcode: data.addressPostalCode.slice(0, 5),
    state: data.addressState,
  })
  return response
}

export const changePassword = async (data) => {
  const encryptedNewPassword = process.env.ENCRYPTION
    ? await encrypt(data.newPassword)
    : data.newPassword
  const response = await getResponse(backend.ChangePasswordUrl, 'PUT', {
    newPassword: encryptedNewPassword,
  })
  return response
}

export const getUserprofile = async () => {
  const isTokenValid = await validateJwtToken()
  if (isTokenValid) {
    const response = await getResponse(backend.getProfileUrl, 'GET')
    return response
  }
  telemetry.trackEvent('jwtTokenExpired for getUserprofile')
  return null
}

export const logout = async () => {
  const response = await getResponse(backend.logoutUrl, 'GET')
  return response
}

export const optOut = async () => {
  const response = await getResponse(backend.optoutUrl, 'PUT')
  return response
}

export const fetchMemberPoints = async () => {
  const response = await getResponse(backend.membersPoints, 'GET')
  return response
}

export const fetchInternetMessage = async () => {
  const response = await getResponse(backend.internetMessagePromotion, 'GET')
  return response
}

export const issueInteraction = async (interactionReference) => {
  const response = await getResponse(backend.issueInteraction, 'POST', {
    interaction: interactionReference,
  })
  return response
}

export const readMessage = async (id) => {
  const response = await getResponse(`${backend.readMessage}${id}`, 'GET')
  return response
}

export const fetchRewards = async () => {
  const response = await getResponse(backend.rewardsListAnonymous, 'GET')
  return response
}

export const fetchRewardsWithEligibility = async () => {
  const response = await getResponse(backend.rewardsList, 'GET')
  standardEventForCommonError(
    backend.rewardsList,
    getRequestHeaders(origin),
    null,
    response,
  )
  return response
}

export const reSendGiftCard = async (orderReference) => {
  const response = await getResponse(backend.sendRewardMail, 'POST', {
    orderReference,
  })
  return response
}

export const fetchNationalCoupons = async () => {
  const response = await getResponse(backend.couponsApi, 'GET')
  return response
}

export const fetchRestrictions = async (guid) => {
  const response = await getResponse(`${backend.restrictionApi}${guid}`, 'GET')
  return response
}

export const issueReward = async (
  rewardReference,
  rewardType,
  quantity = 1,
) => {
  const response = await getResponse(backend.issueReward, 'POST', {
    incentiveId: parseInt(rewardReference, 10),
    rewardQuantity: parseInt(quantity, 10),
    rewardType,
  })
  return response
}

export const fetchMemberHistory = async ({ transactionType, page }) => {
  const response = await getResponse(backend.memberHistory, 'POST', {
    transactionType,
    page,
  })
  return response
}

export const orderRewardSample = async (incentiveId) => {
  const response = await getResponse(backend.orderReward, 'POST', {
    incentiveId,
  })
  return response
}

export const fetchSampleHistory = async () => {
  const response = await getResponse(backend.ordersHistory, 'GET')
  return response
}

const getFileRequestHeaders = () => ({
  locale: 'en-US',
  'Ocp-Apim-Subscription-Key': process.env.ENTERPRISE_APIM_SUBSCRIPTION_KEY,
  ...(typeof window !== 'undefined'
    && localStorage.getItem('JWTtoken') && {
    Authorization: `Bearer ${localStorage.getItem('JWTtoken')}`,
  }),
})

const getFileResponse = async (requestType, requestMethod, requestBody) => {
  const response = await fetch(`${baseUrl}/${requestType}`, {
    method: requestMethod,
    headers: getFileRequestHeaders(),
    credentials: 'include',
    body: requestBody,
  })

  await standardEventForCommonError(
    requestType,
    getFileRequestHeaders(),
    requestBody,
    response,
  )
  return response
}

export const uploadReceiptScan = async (formdata) => {
  const response = await getFileResponse(backend.receiptScan, 'POST', formdata)
  return response
}

export const getdigitalCoupons = async () => {
  const response = await getResponse(backend.digitalCoupons.udcCoupons, 'GET')
  return response
}

export const vcStatus = async () => {
  const response = await getResponse(
    backend.digitalCoupons.verifyCredentialsStatus,
    'GET',
  )
  return response
}

export const vcInitiate = async (phoneNumber, walletDomainName) => {
  const response = await getResponse(
    backend.digitalCoupons.verifyCredentialsInitiate,
    'POST',
    {
      phoneNumber: `${1}${phoneNumber}`,
      walletDomainName,
    },
  )
  return response
}

export const vcVerify = async (transactionId, otp) => {
  const response = await getResponse(
    backend.digitalCoupons.verifyCredentialsVerify,
    'POST',
    {
      transactionId,
      otp,
    },
  )
  return response
}

export const clipDigitalCoupons = async (incentives) => {
  const response = await getResponse(
    backend.digitalCoupons.clipCoupons,
    'POST',
    {
      incentives,
    },
  )
  return response
}

export const fetchIncentives = async () => {
  const response = await getFileResponse(backend.incentives, 'GET')
  return response
}

export const sendDownlaodLink = async (phoneNumber) => {
  const response = await getResponse(
    backend.digitalCoupons.walletLink,
    'POST',
    {
      phoneNumber: `${1}${phoneNumber}`,
    },
  )
  return response
}

export const updateOosStatus = (traitValue, sampleID) => {
  const response = getResponse(backend.oosAutomation, 'POST', {
    campaign: traitValue,
    campaignId: sampleID,
  })
  return response
}

export const addFavoriteBrand = (brandReferenceId) => {
  const response = getResponse(backend.favoriteBrand, 'POST', {
    id: brandReferenceId.toString(),
  })
  return response
}

export const deleteFavoriteBrand = (traitID) => {
  const response = getResponse(`${backend.favoriteBrand}/${traitID}`, 'PUT', {})
  return response
}

export const revtraxUIDRegistration = (campaignName) => {
  const response = getResponse(`${backend.uidRegister}${campaignName}`, 'GET')
  return response
}

export const getLiveRetailers = async () => {
  const response = await getResponse(
    backend.digitalCoupons.udcLiveRetailers,
    'GET',
  )
  return response
}
