import React from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'

const ButtonBase = (props) => {
  const {
    component,
    children,
    className,
    onFocusHandler,
    handler,
    type,
    asPath,
    to,
    behavior,
    ...other
  } = props

  const { target } = props
  let Tag = ''
  const compProps = {}
  if (component === 'div') {
    return (
      <div className={className} {...other}>
        {children}
      </div>
    )
  }
  if (target === '_blank') {
    Tag = 'a'
    compProps.href = to
  } else if (to) {
    let toUrl = to
    if (toUrl[toUrl.length - 1] !== '/' && !toUrl.includes('?') && !toUrl.includes('.pdf') && !toUrl.includes('http:') && !toUrl.includes('https:')) {
      toUrl += '/'
    }
    return (
      <Link
        href={toUrl}
        passHref
        aria-label={compProps}
        className={className}
        role='button'
        tabIndex={0}
        target={target}
        onFocus={onFocusHandler}
        onClick={handler}
        onKeyDown={handler}
        {...other}
        prefetch={false}
      >
        {children}
      </Link>
    )
  } else {
    Tag = 'button'
    compProps.type = type || 'button'
  }
  return (
    <>
      <Tag
        className={className}
        target={target}
        onClick={handler}
        onFocus={onFocusHandler}
        rel={target === '_blank' ? 'noopener' : undefined}
        tabIndex={behavior === 'Carousel' ? '-1' : '0'}
        {...other}
        {...compProps}
      >
        {children}
      </Tag>
    </>
  )
}

ButtonBase.propTypes = {
  component: PropTypes.string,
  className: PropTypes.string.isRequired,
  type: PropTypes.string,
  onFocusHandler: PropTypes.func,
  handler: PropTypes.func,
  children: PropTypes.node,
  to: PropTypes.string,
  target: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  asPath: PropTypes.string,
  behavior: PropTypes.string,
}

ButtonBase.defaultProps = {
  component: null,
  type: 'button',
  handler: () => { },
  onFocusHandler: () => { },
  to: null,
  asPath: null,
  target: '_self',
  children: null,
  behavior: null,
}

export default ButtonBase
