import React from 'react'
import crypto from 'crypto'

export default (active, className) => {
  const randomID = crypto.randomBytes(12).toString('hex')
  return (
    <svg
      width='79px'
      height='79px'
      viewBox='0 0 79 79'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      className={className}
    >
      <defs>
        <polygon
          id={`path-${randomID}`}
          points='0 0.000219444444 79 0.000219444444 79 79 0 79'
        />
        <linearGradient
          x1='50%'
          y1='135.78307%'
          x2='38.4841907%'
          y2='-117.035211%'
          id={`linearGradient-${randomID}`}
        >
          <stop stopColor='#309DD0' offset='0%' />
          <stop stopColor='#258EBF' offset='100%' />
        </linearGradient>
      </defs>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g transform='translate(-676.000000, -343.000000)'>
          <g
            transform='translate(528.000000, 207.000000)'
          >
            <g
              transform='translate(148.000000, 136.000000)'
            >
              <g>
                <mask id={`mask-${randomID}`} fill='white'>
                  <use xlinkHref={`#path-${randomID}`} />
                </mask>
                <g />
                <path
                  d='M39.5,0.000219444444 C61.3149722,0.000219444444 79,17.6852472 79,39.5002194 C79,61.3151917 61.3149722,79.0002194 39.5,79.0002194 C17.6850278,79.0002194 0,61.3151917 0,39.5002194 C0,17.6852472 17.6850278,0.000219444444 39.5,0.000219444444'
                  fill={`url(#linearGradient-${randomID})`}
                  mask={`url(#mask-${randomID})`}
                />
              </g>
              <g
                transform='translate(23.000000, 21.407356)'
                stroke='#FFFFFF'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2.31260504'
              >
                <path
                  d='M14.4487255,34.9121457 C14.4487255,32.8801849 12.7696779,31.2324538 10.7003782,31.2324538 L33.1916667,31.2324538 C35.2621709,31.2324538 36.940014,32.8801849 36.940014,34.9121457 L36.940014,38.5918375 L14.4487255,38.5918375 L14.4487255,34.9121457 Z'
                />
                <path
                  d='M11.7974202,31.0151653 C9.66307843,31.0151653 7.93344258,32.7110756 7.93344258,34.804465 C7.93344258,36.8954454 6.20260224,38.5913557 4.0682605,38.5913557 C1.93391877,38.5913557 0.204282913,36.8954454 0.204282913,34.804465 L0.204282913,0.748946779 L28.5433305,0.748946779 L28.5433305,31.0151653'
                />
                <line
                  x1='21.1962325'
                  y1='7.58197199'
                  x2='8.60096639'
                  y2='7.58197199'
                />
                <line
                  x1='21.1962325'
                  y1='14.9394286'
                  x2='8.60096639'
                  y2='14.9394286'
                />
                <line
                  x1='21.1962325'
                  y1='23.3496022'
                  x2='8.60096639'
                  y2='23.3496022'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
