import React from 'react'

export default (active, className, color) => (
  <svg
    aria-label='closeIcon'
    role='img'
    width='17px'
    height='16px'
    viewBox='0 0 17 16'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
      id='closeIcon'
    >
      <g
        transform='translate(0.393564, 0.000000)'
        stroke={color}
        strokeWidth='1.33333333'
      >
        <line
          x1='0.333333333'
          y1='0.333333333'
          x2='15.6666667'
          y2='15.6666667'
        />
        <line
          x1='0.333333333'
          y1='0.333333333'
          x2='15.6666667'
          y2='15.6666667'
          transform='translate(8.000000, 8.000000) scale(-1, 1) translate(-8.000000, -8.000000) '
        />
      </g>
    </g>
  </svg>
)
