import React from 'react'

export default (active, className) => (
  <svg
    width='9px'
    height='10px'
    viewBox='0 0 9 10'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g
        transform='translate(-1722.000000, -219.000000)'
        stroke='#FFFFFF'
        strokeWidth='0.8'
      >
        <g transform='translate(983.000000, 205.000000)'>
          <g>
            <g
              transform='translate(740.000000, 15.000000)'
            >
              <path
                d='M2.54545455,7.54278545 C2.85413333,8.01148848 3.48421818,8.14113697 3.95275152,7.83245818 C4.06797576,7.75660364 4.1665697,7.65817939 4.24242424,7.54278545'
              />
              <path
                d='M5.76969697,3.39393939 L5.76969697,2.37575758 C5.76969697,1.06366061 4.70603636,7.05805784e-13 3.39393939,7.05805784e-13 C2.08184242,7.05805784e-13 1.01818182,1.06366061 1.01818182,2.37575758 L1.01818182,3.39393939 C1.01818182,4.53090909 6.53699317e-14,4.78545455 6.53699317e-14,5.43030303 C6.53699317e-14,5.99030303 1.30666667,6.44848485 3.39393939,6.44848485 C5.48121212,6.44848485 6.78787879,5.99030303 6.78787879,5.43030303 C6.78787879,4.78545455 5.76969697,4.53090909 5.76969697,3.39393939 Z'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
