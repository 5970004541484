export default {
  default: {
    wrapper:
      'wrapperdefault fixed inset-0 bg-hambergerbg h-screen overflow-y-auto w-full z-70',
    modalWrapper:
      ' font-montserratlight font-light mb-3 bg-white shadow-cardshadow text-center mx-auto ',
    Modalbutton:
      ' flex  toggle-button items-baseline text-formbutton text-lg  pt-15 pb-60 md:pb-45 lg:pb-55 lg:pt-25 font-montserratSemiBold font-semibold  lg:text-23',
    buttoninner:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttoninnerText:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttonicon: 'pl-2 fill-current text-formbutton focus:outline-none',
    modaltext:
      'signUpModalText px-30 font-normal md:px-70 lg:px-65 lg:pt-20 md:pt-0 lg:pt-25 text-base lg:text-lg leading-lightmedium lg:leading-widest break-normal break-words text-sign-head',
    cancel: 'flex justify-end p-5 focus:outline-none',
    subHeadingText: 'subHeadingText',
  },
  loginPopup: {
    wrapper:
      'wrapperdefault oktaLoginPopup fixed inset-0 flex items-center bg-hambergerbg h-screen overflow-y-auto w-full z-70',
    modalWrapper:
      ' font-montserratlight font-light mb-3 bg-white pt-10 pb-30 shadow-cardshadow text-center mx-auto ',
    Modalbutton:
      ' flex hidden toggle-button items-baseline text-formbutton text-lg  pt-15 pb-0 md:pb-0 lg:pb-0 lg:pt-25 font-montserratSemiBold font-semibold  lg:text-23',
    buttoninner:
      'flex items-center justify-center pt-8 pb-8 mx-auto whitespace-pre-line focus:outline-none btn lightSignup',
    buttoninnerText:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttonicon:
      'pl-2 fill-current text-formbutton whitespace-pre-line focus:outline-none',
    modaltext:
      'signUpModalText px-30 font-normal md:px-70 lg:px-65 lg:pt-20 md:pt-0 lg:pt-25 text-base items-center lg:text-lg leading-lightmedium lg:leading-widest break-normal break-words text-sign-head',
    cancel: 'flex justify-end p-5 focus:outline-none',
  },
  'receipt-scan': {
    wrapper:
      'RECEIPT-SCAN-MODAL fixed inset-0 bg-hambergerbg h-screen overflow-y-auto w-full z-20',
    modalWrapper:
      'RECEIPT-SCAN-MODAL receipt-scan-modal font-montserratlight font-light mb-3 bg-white shadow-cardshadow text-center mx-auto w-10/12 md:w-394 lg:w-494',
    Modalbutton:
      'RECEIPT-SCAN-MODAL text-center justify-center px-35 toggle-button items-baseline text-formbutton text-lg font-montserratBold font-bold lg:text-23 pb-75 ',
    buttoninner:
      'RECEIPT-SCAN-MODAL btn btn-primaryReceipt flex mx-auto px-08 lg:px-20 focus:outline-none pb-30',
    buttoninnerText:
      'RECEIPT-SCAN-MODAL text-receiptFeedback mx-auto flex flex-wrap font-montserratSemiBold font-semibold lg:text-18 leading-leading22 underline px-08 lg:px-20 focus:outline-none pb-30',
    buttonicon:
      'RECEIPT-SCAN-MODAL pl-2 fill-current text-formbutton focus:outline-none',
    modaltext:
      'RECEIPT-SCAN-MODAL px-45 md:px-85 lg:px-115 pt-30px pb-20 text-base lg:text-lg leading-relaxed break-normal break-words text-sign-head',
    cancel:
      'RECEIPT-SCAN-MODAL receipt-scan-popup flex justify-end p-2 focus:outline-none',
  },
  'receipt-scan-zoomImg': {
    wrapper:
      'RECEIPT-SCAN-MODAL fixed inset-0 bg-hambergerbg h-screen overflow-y-auto w-full z-20',
    modalWrapper:
      'RECEIPT-SCAN-MODAL receipt-scan-modal font-montserratlight font-light mb-6 bg-white md:w-wrapper75 lg:w-wrapper50 shadow-cardshadow text-center mx-auto w-11/12 lg:w-7/12',
    Modalbutton:
      'RECEIPT-SCAN-MODAL flex  toggle-button items-baseline text-formbutton text-lg font-montserratSemiBold font-semibold lg:text-23',
    buttoninner:
      'RECEIPT-SCAN-MODAL flex items-center justify-center w-full focus:outline-none pb-50',
    buttoninnerText:
      'RECEIPT-SCAN-MODAL flex items-center justify-center w-full focus:outline-none pb-50',
    buttonicon:
      'RECEIPT-SCAN-MODAL pl-2 fill-current text-formbutton focus:outline-none',
    modaltext:
      'RECEIPT-SCAN-MODAL receiptscan-zoom-modaltext overflow-hidden flex justify-center pb-45 text-base lg:text-lg leading-relaxed break-normal break-words text-sign-head',
    cancel:
      'RECEIPT-SCAN-MODAL receipt-scan-popup flex justify-end p-2 focus:outline-none',
  },
  causeDetails: {
    wrapper:
      'wrapperdefault fixed inset-0 bg-hambergerbg h-screen overflow-y-auto w-full z-50',
    modalWrapper:
      ' font-montserratlight font-light mb-3 bg-white shadow-cardshadow text-center mx-auto ',
    Modalbutton:
      'causeModalButton inline-grid toggle-button items-baseline text-formbutton text-lg  pt-15 pb-15 lg:pt-25 font-montserratSemiBold font-semibold  lg:text-23',
    buttoninner:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttoninnerText:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttonicon: 'pl-2 fill-current text-formbutton focus:outline-none',
    modaltext:
      'signUpModalText font-normal px-30 md:px-70 lg:px-65 lg:pt-20 md:pt-0 lg:pt-25 text-base lg:text-lg leading-lightmedium lg:leading-widest break-normal break-words text-sign-head',
    cancel: 'flex justify-end p-5 focus:outline-none',
    infoText:
      'infoText block text-center text-xs font-montserratlight font-light leading-seller18 text-sign-head',
  },
  'modal-see-more-rewards': {
    wrapper:
      'wrapperdefault flex flex-wrap items-center fixed inset-0 bg-hambergerbg h-screen overflow-y-auto w-full z-50',
    modalWrapper:
      'receipt-scan-modal font-montserratlight font-light mb-3 bg-white shadow-cardshadow text-center mx-auto ',
    Modalbutton:
      ' block toggle-button items-baseline text-formbutton text-lg pt-15 pb-60 md:pb-50 lg:pb-45 lg:pt-25 font-montserratSemiBold font-semibold lg:text-23',
    buttoninner:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-warning  ',
    buttoninnerText:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-warning',
    buttonicon: 'pl-2 fill-current text-formbutton focus:outline-none',
    modaltext:
      'signUpModalText px-30 md:px-70 lg:px-75 w-full md:w-11/12 lg:w-10/12 mx-auto pt-0 text-base lg:text-lg leading-lightmedium lg:leading-widest break-normal break-words text-sign-head',
    cancel: 'flex justify-end p-5 focus:outline-none',
    infoText:
      'infoText block text-center text-xs font-montserratlight font-light leading-seller18 text-sign-head',
  },
  'remove-ok-button-rewards': {
    wrapper:
      'wrapperdefault flex flex-wrap items-center fixed inset-0 bg-hambergerbg h-screen overflow-y-auto w-full z-50',
    modalWrapper:
      'receipt-scan-modal font-montserratlight font-light mb-3 bg-white shadow-cardshadow text-center mx-auto ',
    Modalbutton:
      ' block toggle-button items-baseline text-formbutton text-lg pt-15 pb-60 md:pb-50 lg:pb-45 lg:pt-25 font-montserratSemiBold font-semibold lg:text-23',
    buttoninner:
      'items-center justify-center mx-auto focus:outline-none hidden',
    buttoninnerText:
      'items-center justify-center mx-auto focus:outline-none hidden',
    buttonicon: 'pl-2 fill-current text-formbutton focus:outline-none',
    modaltext:
      'signUpModalText px-30 md:px-70 lg:px-25 w-full md:w-11/12 lg:w-10/12 mx-auto pt-0 text-base lg:text-lg leading-lightmedium lg:leading-widest break-normal break-words text-sign-head',
    cancel: 'flex justify-end p-5 focus:outline-none',
    infoText:
      'infoText block text-center text-xs font-montserratlight font-light leading-seller18 text-sign-head',
  },
  pledgeConfirmation: {
    wrapper:
      'wrapperdefault fixed inset-0 bg-hambergerbg h-screen overflow-y-auto w-full z-50',
    modalWrapper:
      ' pledgeWrapper font-montserratlight font-light mb-3 bg-white shadow-cardshadow pb-25 text-center mx-auto ',
    Modalbutton:
      'causeModalButton inline-grid toggle-button items-baseline text-formbutton text-lg  pt-0 pb-15 lg:pt-0 font-montserratSemiBold font-semibold  lg:text-23',
    buttoninner:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttoninnerText:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttonicon: 'pl-2 fill-current text-formbutton focus:outline-none',
    modaltext:
      'pledgeModal px-20 md:px-35 lg:px-30 xl:px-40 lg:pt-20 md:pt-0 lg:pt-25 text-base lg:text-lg leading-lightmedium lg:leading-widest break-normal break-words text-sign-head',
    cancel: 'flex justify-end p-5 focus:outline-none',
    infoText:
      'infoText block text-center text-xs font-montserratlight font-light leading-seller18 text-sign-head',
  },
  'pointshistory-modal': {
    wrapper:
      'wrapperdefault fixed inset-0 bg-hambergerbg h-screen overflow-y-auto w-full z-50',
    modalWrapper:
      ' font-montserratlight font-light mb-3 bg-white shadow-cardshadow text-center mx-auto ',
    Modalbutton:
      ' flex  toggle-button items-baseline text-formbutton text-lg  pt-15 pb-60 md:pb-45 lg:pb-55 lg:pt-25 font-montserratSemiBold font-semibold  lg:text-23',
    buttoninner:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-info text-white  ',
    buttoninnerText:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttonicon: 'pl-2 fill-current text-formbutton focus:outline-none',
    modaltext:
      'px-30 md:px-70 lg:px-65 lg:pt-20 md:pt-0 lg:pt-25 text-base lg:text-lg leading-lightmedium lg:leading-widest break-normal break-words text-sign-head',
    cancel: 'flex justify-end p-5 focus:outline-none',
    pointsRedeemPopupmsg: 'font-montserratlight font-light text-16 lg:text-18',
  },
  'print-coupons': {
    wrapper:
      'wrapperdefault fixed inset-0 bg-hambergerbg h-screen overflow-y-auto w-full z-50',
    modalWrapper:
      ' font-montserratlight font-light mb-3 bg-white shadow-cardshadow text-center mx-auto ',
    Modalbutton:
      ' flex  toggle-button items-baseline text-formbutton text-lg  pt-15 pb-60 md:pb-45 lg:pb-55 lg:pt-25 font-montserratSemiBold font-semibold  lg:text-23',
    buttoninner:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-info text-white  ',
    buttoninnerText:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttonicon: 'pl-2 fill-current text-formbutton focus:outline-none',
    modaltext:
      'print-coupons px-20 md:px-50 lg:px-65 lg:pt-20 md:pt-0 lg:pt-25 text-base lg:text-lg leading-lightmedium lg:leading-widest break-normal break-words text-sign-head',
    cancel: 'flex justify-end p-5 focus:outline-none',
  },
  modalCompleteProfile: {
    wrapper:
      'wrapperdefault complete-profile fixed inset-0 bg-hambergerbg h-screen overflow-y-auto w-full z-50',
    modalWrapper:
      ' w-full profileWrapper table pb-30 translate-y-0 font-montserratlight font-light bg-white shadow-cardshadow text-center mx-auto ',
    Modalbutton:
      'causeModalButton block   toggle-button items-baseline text-formbutton text-lg  pt-15 pb-15 lg:pt-25 font-montserratSemiBold font-semibold  lg:text-23',
    buttoninner:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttoninnerText:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttonicon: 'pl-2 fill-current text-formbutton focus:outline-none',
    modaltext:
      'px-0 lg:pt-20 md:pt-0 lg:pt-25 text-base lg:text-lg leading-lightmedium lg:leading-widest break-normal break-words text-sign-head',
    cancel:
      'flex justify-end pt-30 pr-25 md:pt-38 md:pr-42 xl:pt-50 xl:pr-60 focus:outline-none',
    infoText:
      'infoText block text-center text-xs font-montserratlight font-light leading-seller18 text-sign-head',
  },
  earnPointsBirthdayPromo: {
    wrapper:
      'earnPoints wrapperdefault fixed inset-0 bg-hambergerbg h-screen overflow-y-auto w-full z-50',
    modalWrapper:
      ' font-montserratlight font-light mb-3 pb-50 md:pb-60 bg-white shadow-cardshadow text-center mx-auto ',
    Modalbutton:
      'block toggle-button items-baseline text-formbutton text-lg  pt-15 pb-15 lg:pt-25 px-30 font-montserratSemiBold font-semibold  lg:text-23',
    buttoninner:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary uppercase ',
    modelSpinner: 'w-full flex justify-center items-center pt-10',
    buttoninnerText:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttonicon: 'pl-2 fill-current text-formbutton focus:outline-none',
    modaltext:
      'px-30 md:px-70 lg:px-65 lg:pt-20 md:pt-0 lg:pt-25 text-base lg:text-lg leading-lightmedium lg:leading-widest break-normal break-words text-sign-head',
    cancel: 'flex justify-end p-5 focus:outline-none',
    infoText:
      'infoText block text-center text-xs font-montserratlight font-light leading-seller18 text-sign-head',
    birthdayPromo: {
      birthdayIconWrapper: 'w-full flex flex-wrap justify-center items-center',
      iconWrapper: 'w-79i h-79',
      headingWrapper: ' pb-10 md:pb-15 pt-20 xl:pt-25 font-montserratSemiBold font-semibold text-22 leading-leading28 xl:text-30 xl:leading-wider',
      contentWrapper: 'xl:px-20',
    },
  },
  'print-redeemedCoupons': {
    wrapper:
      'wrapperdefault couponsDigitalWrapper fixed inset-0 bg-hambergerbg h-screen overflow-y-auto w-full z-70',
    modalWrapper:
      ' coupon-modal font-montserratlight font-light mb-3 bg-white shadow-cardshadow text-center mx-auto ',
    Modalbutton:
      ' flex justify-center toggle-button items-baseline text-formbutton text-lg  pt-24 pb-60 md:pb-45 lg:pb-55 lg:pt-25 font-montserratSemiBold font-semibold  lg:text-23',
    buttoninner:
      'btn-coupons bg-couponsBlue text-white hover:bg-transpatent border-2 border-couponsBlue hover:bg-white hover:text-couponsBlue cursor-pointer',
    buttoninnerText:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-secondary',
    buttonicon: 'pl-2 fill-current text-formbutton focus:outline-none',
    modaltext:
      'signUpModalText redeemedCoupons px-30 font-normal md:px-70 lg:px-65 lg:pt-20 md:pt-0 lg:pt-25 text-base lg:text-lg leading-lightmedium lg:leading-widest break-normal break-words text-sign-head',
    cancel: 'redeemedCoupons-Cancel flex justify-end p-5 focus:outline-none',
  },
  RestrictionModal: {
    wrapper:
      'wrapperdefault fixed inset-0 bg-hambergerbg h-screen overflow-y-auto w-full z-70',
    modalWrapper:
      ' font-montserratlight font-light mb-3 bg-white shadow-cardshadow text-center mx-auto ',
    Modalbutton:
      ' flex  toggle-button items-baseline text-formbutton text-lg  pt-15 pb-60 md:pb-45 lg:pb-55 lg:pt-25 font-montserratSemiBold font-semibold  lg:text-23',
    buttoninner:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttoninnerText:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttonicon: 'pl-2 fill-current text-formbutton focus:outline-none',
    modaltext:
      'px-30 font-normal md:px-50 lg:px-65 lg:pt-20 md:pt-0 lg:pt-25 text-base lg:text-lg leading-lightmedium lg:leading-widest break-normal break-words text-sign-head',
    cancel: 'flex justify-end p-5 focus:outline-none',
    restrictedText: 'pb-05 restrictedText',
    restrictedTitle: 'text-22 font-semibold pb-10 leading-leading28',
  },
  ChatModal: {
    wrapper:
      'wrapperdefault chatModalWrapper fixed inset-0 bg-hambergerbg h-screen overflow-y-auto w-full z-70',
    modalWrapper:
      'chatModal font-montserratlight rounded-2xl font-light mb-3 bg-white shadow-cardshadow text-center mx-auto ',
    Modalbutton: 'pb-30 hidden',
    buttoninner:
      'hidden flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttoninnerText:
      'hidden flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttonicon: 'pl-2 fill-current text-formbutton focus:outline-none',
    modaltext:
      'px-45 pb-35 font-normal md:px-50 text-base lg:text-lg leading-lightmedium lg:leading-widest break-normal break-words text-sign-head',
    cancel: 'flex justify-end p-5 focus:outline-none',
  },
  headsUpCoupons: {
    wrapper:
      'wrapperdefault digitalHeadsUp-Wrapper flex flex-wrap items-center fixed inset-0 bg-hambergerbg h-screen overflow-y-auto w-full z-70',
    modalWrapper:
      ' coupon-modal font-montserratlight font-light mb-3 bg-white shadowcardshadow text-center mx-auto ',
    Modalbutton:
      ' grid md:table toggle-button justify-start items-baseline text-formbutton text-lg px-13 md:px-40 xl:px-80 pt-40 pb-55 md:pb-60',
    buttoninner:
      'flex items-center w-160 justify-start mx-auto mb-35 md:mb-0 md:mr-40 xl:mr-70 focus:outline-none btn btn-info ',
    buttoninnerText:
      'flex items-center justify-center text-white w-160 mx-auto focus:outline-none btn bg-sign-head',
    buttonicon: 'pl-2 fill-current text-formbutton focus:outline-none',
    modaltext:
      'headsUpModalText text-left redeemedCoupons font-normal leading-lightmedium text-18 leading-leading32 pt-60 md:pt-10 px-13 md:px-40 xl:px-80 text-sign-head',
    cancel: 'redeemedCoupons-Cancel flex justify-end p-5 focus:outline-none',
  },
  modalOptOut: {
    wrapper:
      'wrapperdefault fixed inset-0 bg-hambergerbg h-screen overflow-y-auto w-full z-100',
    modalWrapper:
      ' font-montserratlight font-light mb-3 bg-white shadow-cardshadow text-center mx-auto ',
    Modalbutton:
      ' flex  toggle-button items-baseline text-formbutton text-lg  pt-15 pb-60 md:pb-45 lg:pb-55 lg:pt-25 font-montserratSemiBold font-semibold  lg:text-23',
    buttoninner:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttoninnerText:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttonicon: 'pl-2 fill-current text-formbutton focus:outline-none',
    modaltext:
      'modalOptOut px-30 font-normal md:px-70 lg:px-65 lg:pt-20 md:pt-0 lg:pt-25 text-base lg:text-lg leading-lightmedium lg:leading-widest break-normal break-words text-sign-head',
    cancel: 'flex justify-end p-5 focus:outline-none',
  },
  modalNetworkHiccup: {
    wrapper:
      'wrapperdefault fixed inset-0 bg-hambergerbg h-screen overflow-y-auto w-full z-100',
    modalWrapper:
      ' font-montserratlight font-light mb-3 bg-white shadow-cardshadow text-center mx-auto ',
    Modalbutton:
      ' flex  toggle-button items-baseline text-formbutton text-lg  pt-15 pb-60 md:pb-45 lg:pb-55 lg:pt-25 font-montserratSemiBold font-semibold  lg:text-23',
    buttoninner:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttoninnerText:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttonicon: 'pl-2 fill-current text-formbutton focus:outline-none',
    modaltext:
      'signUpModalText px-30 font-normal md:px-70 lg:px-65 lg:pt-20 md:pt-0 lg:pt-25 text-base lg:text-lg leading-lightmedium lg:leading-widest break-normal break-words text-sign-head',
    cancel: 'flex justify-end p-5 focus:outline-none',
  },
  'signup-donation-popup': {
    wrapper:
      'wrapperdefault fixed inset-0 bg-hambergerbg h-screen overflow-y-auto w-full z-70',
    modalWrapper:
      'formModal-Signup font-montserratlight font-light h-690 mb-3 bg-white shadow-cardshadow text-center mx-auto ',
    Modalbutton:
      ' flex  toggle-button items-baseline text-formbutton text-lg  pt-15 pb-60 md:pb-45 lg:pb-55 lg:pt-25 font-montserratSemiBold font-semibold  lg:text-23',
    buttoninner:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttoninnerText:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttonicon: 'pl-2 fill-current text-formbutton focus:outline-none',
    modaltext:
      'signUpModalText px-30 font-normal mx-auto md:w-10/12 xl:w-8/12 lg:text-18 lg:leading-widest leading-lightmedium md:px-60 lg:px-55 lg:pt-20 md:pt-0 lg:pt-25 text-base break-normal break-words',
    cancel: 'flex justify-end p-5 focus:outline-none',
  },
  'no-signup-donation-popup': {
    wrapper:
      'wrapperdefault fixed inset-0 bg-hambergerbg overflow-y-auto w-full z-70',
    modalWrapper:
      'formModal-Signup font-montserratlight font-light mt-100px mb-3 bg-white shadow-cardshadow text-center mx-auto ',
    Modalbutton:
      ' flex  toggle-button items-baseline text-formbutton text-lg  pt-15 pb-60 md:pb-45 lg:pb-55 lg:pt-25 font-montserratSemiBold font-semibold  lg:text-23',
    buttoninner:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttoninnerText:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttonicon: 'pl-2 fill-current text-formbutton focus:outline-none',
    modaltext:
      'signUpModalText px-30 font-normal mx-auto md:w-10/12 xl:w-8/12 lg:text-18 lg:leading-widest leading-lightmedium md:px-60 lg:px-55 lg:pt-20 md:pt-0 lg:pt-25 text-base break-normal break-words',
    cancel: 'flex justify-end p-5 focus:outline-none',
  },
  modalSessionExpire: {
    wrapper:
      'wrapperdefault fixed inset-0 bg-hambergerbg h-screen overflow-y-auto w-full z-100 modalSessionExpire',
    modalWrapper:
      ' font-montserratlight font-light mb-3 bg-white shadow-cardshadow text-center mx-auto ',
    Modalbutton:
      ' flex flex-row-reverse  toggle-button items-baseline text-formbutton text-lg  pt-15 pb-60 md:pb-45 lg:pb-55 lg:pt-25 font-montserratSemiBold font-semibold  lg:text-23',
    buttoninner:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-IOHBlue  ',
    buttoninnerText:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttonicon: 'pl-2 fill-current text-formbutton focus:outline-none',
    modaltext:
      'signUpModalText px-30 font-normal md:px-50 lg:px-36 lg:pt-20 md:pt-0 lg:pt-25 text-base lg:text-lg leading-lightmedium lg:leading-widest break-normal break-words text-sign-head',
    cancel: 'flex justify-end p-5 focus:outline-none',
  },
  earnPointsCompleteProfileEndcard: {
    wrapper:
      'wrapperdefault CompleteProfileEndcard fixed inset-0 bg-white h-screen overflow-y-auto w-full z-100',
    modalWrapper:
      ' font-montserratlight text-black font-light bg-white text-center mx-auto ',
    Modalbutton:
      ' flex flex-row-reverse  toggle-button items-baseline text-formbutton text-lg  pt-15 pb-60 md:pb-45 lg:pb-55 lg:pt-25 font-montserratSemiBold font-semibold  lg:text-23',
    buttoninner:
      'flex items-center justify-center mx-auto focus:outline-none rounded-3xl btn btn-survey  ',
    buttoninnerText:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttonicon: 'pl-2 fill-current text-formbutton focus:outline-none',
    modaltext:
      'px-30 font-normal md:px-50 lg:px-36 md:pt-0 text-base lg:text-lg leading-lightmedium lg:leading-widest break-normal break-words',
    cancel: 'flex justify-end p-5 focus:outline-none',
    birthdayIconWrapper: 'w-full flex flex-wrap justify-center items-center',
    iconWrapper: 'w-150 h-150 md:w-250i md:h-250',
    headingWrapper: ' pb-10 md:pb-25 pt-20 xl:pt-25 font-bold text-32 leading-wider lg:text-42 lg:leading-wider',
    contentWrapper: 'completeContent mx-auto lg:w-780 py-10 text-16 lg:text-22 font-normal leading-lightmedium lg:leading-leading33',
  },
  pointsRedeemPopupmsg: 'font-montserratlight font-light text-16 lg:text-18',
  button: 'block text-center font-montserratlight font-light align-baseline focus:outline-none text-lg',
  buttonLogin: 'block text-center font-montserratlight font-light xl:pt-08 align-baseline focus:outline-none text-lg',
  signintext: 'md:pr-04 text-navfocus md:text-14 lg:text-sm font-montserratSemiBold font-semibold',
  couponRewards: {
    mainWrapper: 'w-full text-center bg-white py-10 container',
    wrapper: 'w-11/12 px-10 lg:w-10/12 mx-auto lg:px-0  xl:px-45  text-center text-18 font-montserratlight font-light leading-wider',
    title: 'w-10/12 xl:w-8/12 xxl:w-7/12 pb-10 mx-auto font-montserratSemiBold font-semibold pt-30 text-24 leading-leading29 xl:text-38 leading-lightmedium xl:leading-leading42',
    subTitle: 'w-10/12 xl:w-7/12 mx-auto font-montserratlight font-light pb-05 text-16 xl:text-18 leading-lightmedium',
    printWrapper: 'w-full flex flex-wrap justify-center',
    printCouponsTitle: 'w-11/12 font-montserratSemiBold font-semibold text-22 xl:text-30 leading-widest xl:leading-wider pt-15 pb-10',
    printCouponsSubTitle: 'printCouponsSubTitle w-11/12 pb-25',
    printCouponsButton: 'underline text-secondary font-montserratSemiBold font-semibold pt-10',
  },
  'Deq-Article': {
    wrapper:
      'wrapperdefault DeqArticlePopup fixed inset-0 flex items-center h-screen overflow-y-auto w-full z-70',
    modalWrapper:
      ' font-montserratlight font-light mb-0 bg-white pt-0 pb-30 shadow-cardshadow text-center mx-auto ',
    Modalbutton:
      ' flex hidden toggle-button items-baseline text-formbutton text-lg  pt-15 pb-0 md:pb-0 lg:pb-0 lg:pt-25 font-montserratSemiBold font-semibold  lg:text-23',
    buttoninner:
      'flex items-center justify-center pt-8 pb-8 mx-auto whitespace-pre-line focus:outline-none btn lightSignup',
    buttoninnerText:
      'flex items-center justify-center mx-auto focus:outline-none btn btn-primary  ',
    buttonicon:
      'pl-2 fill-current text-formbutton whitespace-pre-line focus:outline-none',
    modaltext:
      'signUpModalText px-30 font-normal md:px-70 lg:px-65 lg:pt-20 md:pt-0 lg:pt-25 text-base items-center lg:text-lg leading-lightmedium lg:leading-widest break-normal break-words text-sign-head',
    cancel: 'flex justify-end top-20 relative z-999999 mt-0 mr-10px',
  },
}
