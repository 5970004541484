import { useEffect } from 'react'
import ModalV2 from '../Modal/ModalV2'
import LoginStore from '../UserManagement/Login-store'
import telemetry from '../../adapters/telemetry.adapter'
import { variants } from '../../constants/variants'

const CookiesDisabledPopup = () => {
  useEffect(() => {
    if (LoginStore.showCookiesDisabledodal) {
      telemetry.trackEvent('Cookies Disabled', {
        page: window?.location?.href,
      })
    }
  }, [])

  return (
    <ModalV2
      variant={variants?.paragraphModal?.cookieDisabled}
      okHandler={() => {
        LoginStore.showCookiesDisabledodal = false
      }}
    />
  )
}

export default CookiesDisabledPopup
