module.exports = {
  home: 'Home',
  causeDetail: 'causeDetail',
  causeLanding: 'causeLanding',
  articleDetail: 'articleDetail',
  articleListing: 'articleListing',
  signUp: 'signUp',
  waysToEarnPoints: 'waysToEarnPoints',
  rewardsDashboard: 'rewardsDashboard',
  rewardsListing: 'rewardsListing',
  rewardsDetail: 'rewardsDetail',
  coupons: 'coupons',
  couponsDigital: 'couponsDigital',
  explore: 'explore',
  aboutCarousel: 'about',
  relatedNews: 'relatedNews',
  howCanYouHelp: 'howCanYouHelp',
  tilesInContent: 'tilesInContent',
  footer: 'footer',
  about: 'about',
  receiptScan: 'receiptScan',
  siteDown: 'siteDown',
  siteMap: 'siteMap',
  signIn: 'signIn',
  campaign: 'campaign',
  refferaFriend: 'refferaFriend',
  layout: 'layout',
  myAccount: 'myAccount',
  hamburger: 'hamburger',
  survey: 'survey',
  search: 'search',
  messages: 'messages',
  earnPoints: 'earnPoints',
  rebates: 'rebates',
  impactSummary: 'impactSummary',
  alertBox: 'alertBox',
  needHelp: 'needHelp',
  footerActionTray: 'footerActionTray',
  tooltipRewards: 'tooltipRewards',
  tooltipEarnPoints: 'tooltipEarnPoints',
  PGBrands: 'PGBrands',
  sustainability: 'sustainability',
  olympics: 'olympics',
  authError: 'authError',
  offerrules: 'offerrules',
  olympicsSelectModal: 'olympicsSelectModal',
  revtraxDigitalCouponsCampaign: [
    'cascade-digital-coupons',
    'always-coupon',
    'always-discreet-coupon',
    'hbcyou',
    'tide-digital-coupon',
    'dawn-ducks',
    'gillette-labs-coupon',
    'old-spice-coupon',
    'always-discreet-coupon-special-offer',
    'olay-coupons',
    'hs-coupon',
    'oldspice-coupon-family-dollar',
  ],
}
