import React from 'react'

const CauseIcon = (active, className) => (active ? (
  <svg
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        transform='translate(1.000000, 1.000000)'
        stroke='#FFFFFF'
        strokeWidth='1.5'
      >
        <g
          transform='translate(0.000000, 4.000000)'
          strokeLinecap='round'
        >
          <path
            d='M4.66693259,16.6784173 L4.66693259,15.1697737 C1.5556442,11.5612484 -1.81188398e-13,9.62942652 -1.81188398e-13,9.37430798 C-1.81188398e-13,9.11918945 -1.81188398e-13,6.44105063 -1.81188398e-13,1.33989153 C0.136107449,0.446630511 0.622074984,-1.24344979e-14 1.45790261,-1.24344979e-14 C2.29373023,-1.24344979e-14 2.71164404,0.446630511 2.71164404,1.33989153 L2.71164404,5.8946689'
          />
          <path
            d='M9.43415868,17.0837503 C9.43415868,14.4534678 9.43415868,12.8427941 9.43415868,12.2517291 C9.43415868,11.3651316 9.24315151,10.5704391 8.63258629,9.99330703 C8.2255428,9.60855228 6.86429481,8.29730941 4.54884231,6.05957842 C3.8275458,5.77727831 3.26127481,5.85741945 2.85002936,6.30000184 C2.4387839,6.74258423 2.4387839,7.32244517 2.85002936,8.03958467 L6.14209402,11.3840838'
          />
        </g>
        <g
          transform='translate(17.000000, 13.000000) scale(-1, 1) translate(-17.000000, -13.000000) translate(12.000000, 4.000000)'
          strokeLinecap='round'
        >
          <path
            d='M4.66693259,16.6784173 L4.66693259,15.1697737 C1.5556442,11.5612484 -1.81188398e-13,9.62942652 -1.81188398e-13,9.37430798 C-1.81188398e-13,9.11918945 -1.81188398e-13,6.44105063 -1.81188398e-13,1.33989153 C0.136107449,0.446630511 0.622074984,-1.24344979e-14 1.45790261,-1.24344979e-14 C2.29373023,-1.24344979e-14 2.71164404,0.446630511 2.71164404,1.33989153 L2.71164404,5.8946689'
          />
          <path
            d='M9.43415868,17.0837503 C9.43415868,14.4534678 9.43415868,12.8427941 9.43415868,12.2517291 C9.43415868,11.3651316 9.24315151,10.5704391 8.63258629,9.99330703 C8.2255428,9.60855228 6.86429481,8.29730941 4.54884231,6.05957842 C3.8275458,5.77727831 3.26127481,5.85741945 2.85002936,6.30000184 C2.4387839,6.74258423 2.4387839,7.32244517 2.85002936,8.03958467 L6.14209402,11.3840838'
          />
        </g>
        <path
          d='M17.9587093,3.1534694 C17.7393603,1.68497458 16.8600804,0.632562841 15.4099985,0.16319454 C13.9811098,-0.299298249 12.8212562,0.437642069 11.793742,1.46908349 C11.6591656,1.6043019 11.2536701,2.04926213 11.1050827,2.22298341 C11.0500982,2.28715486 10.9491953,2.287957 10.8937399,2.22424392 C10.7028839,2.00548804 10.1918937,1.44112308 10.0486045,1.29719569 C9.02109036,0.265754264 8.01889022,-0.299298249 6.59000149,0.16319454 C5.13991961,0.632562841 4.26052196,1.68497458 4.04129068,3.1534694 C3.8784567,4.24381104 4.20989389,5.27215849 4.81248557,6.20825948 C5.39635663,7.11513954 6.15036153,7.88233211 6.93851093,8.61583468 C7.93882724,9.54666444 9.95076286,11.205038 10.7205449,11.8764318 C10.9122251,12.0436213 11.2023356,12.0408711 11.3906014,11.8699 C12.133892,11.1947247 14.0623502,9.54551852 15.0614891,8.61583468 C15.8496385,7.88233211 16.6036434,7.11513954 17.1875144,6.20825948 C17.7901061,5.27215849 18.1215433,4.24381104 17.9587093,3.1534694 Z'
        />
      </g>
    </g>
  </svg>
) : (
  <svg
    aria-labelledby='causeIcon'
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g stroke='none' id='causeIcon' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(1.000000, 1.000000)' strokeWidth='1.5'>
        <g
          transform='translate(0.000000, 4.000000)'
          stroke='#5E5E5E'
          strokeLinecap='round'
        >
          <path d='M4.66693259,16.6784173 L4.66693259,15.1697737 C1.5556442,11.5612484 -1.81188398e-13,9.62942652 -1.81188398e-13,9.37430798 C-1.81188398e-13,9.11918945 -1.81188398e-13,6.44105063 -1.81188398e-13,1.33989153 C0.136107449,0.446630511 0.622074984,-1.24344979e-14 1.45790261,-1.24344979e-14 C2.29373023,-1.24344979e-14 2.71164404,0.446630511 2.71164404,1.33989153 L2.71164404,5.8946689' />
          <path d='M9.43415868,17.0837503 C9.43415868,14.4534678 9.43415868,12.8427941 9.43415868,12.2517291 C9.43415868,11.3651316 9.24315151,10.5704391 8.63258629,9.99330703 C8.2255428,9.60855228 6.86429481,8.29730941 4.54884231,6.05957842 C3.8275458,5.77727831 3.26127481,5.85741945 2.85002936,6.30000184 C2.4387839,6.74258423 2.4387839,7.32244517 2.85002936,8.03958467 L6.14209402,11.3840838' />
        </g>
        <g
          transform='translate(17.000000, 13.000000) scale(-1, 1) translate(-17.000000, -13.000000) translate(12.000000, 4.000000)'
          stroke='#5E5E5E'
          strokeLinecap='round'
        >
          <path d='M4.66693259,16.6784173 L4.66693259,15.1697737 C1.5556442,11.5612484 -1.81188398e-13,9.62942652 -1.81188398e-13,9.37430798 C-1.81188398e-13,9.11918945 -1.81188398e-13,6.44105063 -1.81188398e-13,1.33989153 C0.136107449,0.446630511 0.622074984,-1.24344979e-14 1.45790261,-1.24344979e-14 C2.29373023,-1.24344979e-14 2.71164404,0.446630511 2.71164404,1.33989153 L2.71164404,5.8946689' />
          <path d='M9.43415868,17.0837503 C9.43415868,14.4534678 9.43415868,12.8427941 9.43415868,12.2517291 C9.43415868,11.3651316 9.24315151,10.5704391 8.63258629,9.99330703 C8.2255428,9.60855228 6.86429481,8.29730941 4.54884231,6.05957842 C3.8275458,5.77727831 3.26127481,5.85741945 2.85002936,6.30000184 C2.4387839,6.74258423 2.4387839,7.32244517 2.85002936,8.03958467 L6.14209402,11.3840838' />
        </g>
        <path
          d='M17.9587093,3.1534694 C17.7393603,1.68497458 16.8600804,0.632562841 15.4099985,0.16319454 C13.9811098,-0.299298249 12.8212562,0.437642069 11.793742,1.46908349 C11.6591656,1.6043019 11.2536701,2.04926213 11.1050827,2.22298341 C11.0500982,2.28715486 10.9491953,2.287957 10.8937399,2.22424392 C10.7028839,2.00548804 10.1918937,1.44112308 10.0486045,1.29719569 C9.02109036,0.265754264 8.01889022,-0.299298249 6.59000149,0.16319454 C5.13991961,0.632562841 4.26052196,1.68497458 4.04129068,3.1534694 C3.8784567,4.24381104 4.20989389,5.27215849 4.81248557,6.20825948 C5.39635663,7.11513954 6.15036153,7.88233211 6.93851093,8.61583468 C7.93882724,9.54666444 9.95076286,11.205038 10.7205449,11.8764318 C10.9122251,12.0436213 11.2023356,12.0408711 11.3906014,11.8699 C12.133892,11.1947247 14.0623502,9.54551852 15.0614891,8.61583468 C15.8496385,7.88233211 16.6036434,7.11513954 17.1875144,6.20825948 C17.7901061,5.27215849 18.1215433,4.24381104 17.9587093,3.1534694 Z'
          stroke='#309DD0'
        />
      </g>
    </g>
  </svg>
))

export default CauseIcon
