import Router from 'next/router'
import staticRoutes, { dawnDucks, loggedinUserRedirectForCoupon, olayCouponsCampaign } from '../../config/router.config'

export const redirectableBackPage = () => {
  const {
    forgotPassword, registration,
  } = staticRoutes
  const nonLoggedinPage = [forgotPassword, registration]
  let PreviousPage = new URL(document.referrer).pathname
  if (PreviousPage.slice(-1) === '/') {
    PreviousPage = PreviousPage.slice(0, PreviousPage.length - 1)
  }
  if (nonLoggedinPage.indexOf(PreviousPage) === 0) return false
  return true
}

export default (isCallbackInURL, callbackURL) => {
  if (isCallbackInURL) {
    Router.push(callbackURL)
  } else if (document.referrer === '') {
    Router.push('/')
  } else if (!redirectableBackPage())Router.push('/')
  else if (document.referrer.includes('coupons') && sessionStorage.getItem('promoCodeTab') === 'yes') {
    Router.push('/coupons#promoCodes')
  } else if (document.referrer === window.location.href)Router.push('/')
  else Router.push(document.referrer)
}
export const redirectBack = () => {
  if (document.referrer.split('/')[3] === '') Router.push(staticRoutes.earnPoints)
  else Router.push(document.referrer)
}
export const getParameterByName = (name) => {
  const match = RegExp(`[?&]${name}=([^&]*)`).exec(window?.location?.search)
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}
export const handleGoBack = (path) => {
  const segments = path.split('/').filter(Boolean)
  segments.pop()
  const newPath = `/${segments.join('/')}`
  return newPath
}

export const handleUserloggedin = (router, slug) => {
  if (loggedinUserRedirectForCoupon.includes(slug)) {
    router.replace(staticRoutes.coupons)
    return
  }

  if (slug === olayCouponsCampaign) {
    router.replace(staticRoutes.accountSetupOlayCoupons)
    return
  }

  if (slug === 'olaybody-coupon') {
    router.replace(staticRoutes.olaySuperSerumBodyCoupons)
    return
  }
  if (slug === 'tampax-coupon') {
    router.replace(staticRoutes.tampaxCoupon)
    return
  }

  if (slug === dawnDucks) {
    router.replace(staticRoutes.accountDawnDucks)
    return
  }

  if (slug.includes('-sample')) return

  router.replace(staticRoutes.homepage)
}

export const handleRedirectIfDisabled = (isDisabled) => {
  if (isDisabled === false) {
    Router.push(staticRoutes.homepage)
  }
}
