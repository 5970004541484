import React from 'react'
import PropTypes from 'prop-types'
import ButtonBaseDefault from './ButtonBase'

const Button = (props) => {
  const {
    children, sys, className, __typename, ...other
  } = props
  const { to } = props
  return (
    <ButtonBaseDefault className={className} {...other} target={to && to?.startsWith('https') && '_blank'}>
      {children}
    </ButtonBaseDefault>
  )
}

Button.propTypes = {
  sys: PropTypes.shape({
    id: PropTypes.string,
  }),
  __typename: PropTypes.string,
  className: PropTypes.string,
  handler: PropTypes.func,
  children: PropTypes.node,
  to: PropTypes.string,
}

Button.defaultProps = {
  sys: null,
  className: '',
  handler: () => null,
  __typename: null,
  to: '',
  children: null,
}

export default Button
