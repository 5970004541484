import React from 'react'

export default (active, className) => (
  <svg
    width='31px'
    height='31px'
    viewBox='0 0 31 31'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
    role='img'
    aria-label='grey search icon'
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
      id='grey-search-icon'
    >
      <g
        transform='translate(-2813.000000, -3833.000000)'
        stroke='#5E5E5E'
      >
        <g transform='translate(1847.000000, 3649.000000)'>
          <g transform='translate(443.000000, 179.000000)'>
            <g transform='translate(524.000000, 6.000000)'>
              <g>
                <line
                  x1='29.2776316'
                  y1='29.2775526'
                  x2='24.4736842'
                  y2='24.4736053'
                  strokeWidth='1.31578947'
                />
                <path
                  d='M12.6315789,0 C19.6081579,0 25.2631579,5.65578947 25.2631579,12.6315789 C25.2631579,19.6081579 19.6081579,25.2631579 12.6315789,25.2631579 C5.65578947,25.2631579 0,19.6081579 0,12.6315789 C0,5.65578947 5.65578947,0 12.6315789,0 Z'
                  strokeWidth='1.31578947'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
