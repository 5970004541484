import React from 'react'

const Dots = (active, className) => (active ? (
  <svg
    aria-labelledby='icondots'
    aria-hidden='true'
    focusable='false'
    width='20px'
    height='4px'
    viewBox='0 0 20 4'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g
      stroke='none'
      strokeWidth='1'
      id='icondots'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g
        transform='translate(-2555.000000, -518.000000)'
        stroke='#309DD0'
        strokeWidth='2'
      >
        <g
          transform='translate(2536.000000, 489.000000)'
        >
          <g
            transform='translate(20.000000, 30.000000)'
          >
            <g>
              <circle cx='1' cy='1' r='1' />
              <circle cx='17' cy='1' r='1' />
              <circle cx='9' cy='1' r='1' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
) : (
  <svg
    aria-labelledby='hamberMenu-icon'
    aria-hidden='true'
    focusable='false'
    width='20px'
    height='4px'
    viewBox='0 0 20 4'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >

    <g
      stroke='none'
      strokeWidth='1'
      id='hamberMenu-icon'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g
        transform='translate(-1501.000000, -517.000000)'
        stroke='#A4A4A4'
        strokeWidth='2'
      >
        <g
          transform='translate(983.000000, 466.000000)'
        >
          <g
            transform='translate(519.000000, 52.000000)'
          >
            <g>
              <circle cx='1' cy='1' r='1' />
              <circle cx='17' cy='1' r='1' />
              <circle cx='9' cy='1' r='1' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
))

export default Dots
