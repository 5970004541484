import React from 'react'

export default (active, className) => (
  <svg
    width='119px'
    height='109px'
    viewBox='0 0 119 109'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g id='spraksLeftPage-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
      <g id='PGGE-Account-Empty-Desktop-spraksLeft' transform='translate(-498.000000, -297.000000)' stroke='#FFF' strokeWidth='3.822'>
        <g id='screencapture-pggoodeveryday-my-account-2022-09-28-12_04_39-(1)' transform='translate(0.000000, -2.000000)'>
          <g id='spraksLeftCard-/-Feature' transform='translate(20.000000, 259.000000)'>
            <g id='SparksLeft' transform='translate(537.544000, 94.080000) scale(-1, 1) translate(-537.544000, -94.080000) translate(480.088000, 42.000000)'>
              <path d='M90.888,92.148 C97.5220444,92.148 102.9,86.7700444 102.9,80.136 C102.9,86.7700444 108.277956,92.148 114.912,92.148' id='spraksLeftPath1' transform='translate(102.900000, 86.142000) scale(-1, 1) translate(-102.900000, -86.142000) ' />
              <path d='M90.888,92.148 C97.5220444,92.148 102.9,97.5259556 102.9,104.16 C102.9,97.5259556 108.277956,92.148 114.912,92.148' id='spraksLeftPath2' transform='translate(102.900000, 98.154000) scale(-1, 1) translate(-102.900000, -98.154000) ' />
              <path d='M39.984,47.124 C46.6180444,47.124 51.996,41.7460444 51.996,35.112 C51.996,41.7460444 57.3739556,47.124 64.008,47.124' id='spraksLeftPath3' transform='translate(51.996000, 41.118000) scale(-1, 1) translate(-51.996000, -41.118000) ' />
              <path d='M39.984,47.124 C46.6180444,47.124 51.996,52.5019556 51.996,59.136 C51.996,52.5019556 57.3739556,47.124 64.008,47.124' id='spraksLeftPath4' transform='translate(51.996000, 53.130000) scale(-1, 1) translate(-51.996000, -53.130000) ' />
              <line x1='103.32' y1='31.6848' x2='112.9296' y2='31.6848' id='spraksLeftPath5' transform='translate(108.124800, 31.684800) scale(-1, 1) translate(-108.124800, -31.684800) ' />
              <line x1='108.1248' y1='26.88' x2='108.1248' y2='36.4896' id='spraksLeftPath6' transform='translate(108.124800, 31.684800) scale(-1, 1) translate(-108.124800, -31.684800) ' />
              <line x1='35.616' y1='88.4688' x2='45.2256' y2='88.4688' id='spraksLeftPath7' transform='translate(40.420800, 88.468800) scale(-1, 1) translate(-40.420800, -88.468800) ' />
              <line x1='40.4208' y1='83.664' x2='40.4208' y2='93.2736' id='spraksLeftPath8' transform='translate(40.420800, 88.468800) scale(-1, 1) translate(-40.420800, -88.468800) ' />
              <line x1='0' y1='4.8048' x2='9.6096' y2='4.8048' id='Path-Copy-spraksLeft' transform='translate(4.804800, 4.804800) scale(-1, 1) translate(-4.804800, -4.804800) ' />
              <line x1='4.8048' y1='0' x2='4.8048' y2='9.6096' id='Path-Copy-2-spraksLeft' transform='translate(4.804800, 4.804800) scale(-1, 1) translate(-4.804800, -4.804800) ' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

