import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import Head from 'next/head'
import { Montserrat } from 'next/font/google'
import Script from 'next/script'
import Router, { withRouter } from 'next/router'
import { observer } from 'mobx-react'
import {
  getCLS, getFID, getLCP, getTTFB, getFCP,
} from 'web-vitals'
import DOMPurify from 'isomorphic-dompurify'
import {
  changePassword, forgotPassword, login, myCoupons, dawnDucks,
  oldSpiceCoupon,
  alwaysUdo,
  headandShouldersCoupon,
  accountSetupOlayCoupons,
  olayCouponsCampaign,
  olaySuperSerumBodyCoupons,
  tideDigitalCoupon,
  tampaxCoupon,
  oldSpiceCouponfamilyDollar,
  accountOldSpiceCouponfamilyDollar,
} from '../../config/router.config'
import telemetry from '../adapters/telemetry.adapter'
import DataLayer from '../components/DataLayer/DataLayer'
import 'mobx-react-lite/batchingForReactDom'
import '../styles/main.css'
import 'slick-carousel/slick/slick.css'
import {
  securedPages,
  sessionClearing,
} from '../okta/forms/okta.helpers'
import { handleSession, tokenToExpireinMS } from '../helpers/login.helper'
import LoginStore from '../components/UserManagement/Login-store'
import { getUserprofile, storageAvailable } from '../adapters/serverside.adapters'
import ReceiptScanStore from '../containers/ReceiptScan/ReceiptScan.store'
import {
  defaultCharity,
  sessionExpiryMinute,
} from '../constants/texts'
import {
  fetchPointsBalanceApi,
  updateToOldProfileData,
} from '../helpers/api.helper'
import { triggeVirtualPageview } from '../helpers/gaEvent.helper'
import CookiesDisabledPopup from '../components/CookiesDisabledPopup/CookiesDisabledPopup'
import { standardEventForChatBotLoad } from '../containers/AuthContainer/standardEventErrorLog'
import { STATUS_1042, STATUS_1043 } from '../constants/status-code'

const montserratFont = Montserrat({
  subsets: ['latin'],
  variable: '--font-montserrat',
  weight: ['300', '400', '500', '600', '700', '800'],
})
let pypestreamIframeChecked = 0

function MyApp(props) {
  const {
    router: { asPath, pathname },
  } = props

  const blockGTM = !asPath.includes('/auth/') && (!pathname.includes('/signup/[slug]') || !LoginStore.isFormProcessing)

  const sendWVToGlobalGTM = ({ name, delta, id }) => {
    if (window.dataLayer !== undefined) {
      window.dataLayer.push({
        event: 'webVitalsEvent',
        WVname: name,
        WVid: id,
        WVdelta: delta,
      })
    }
  }

  const handleRedirection = () => {
    let exactPath = window?.location?.pathname
    if (exactPath.slice(-1) === '/') {
      exactPath = exactPath.slice(0, exactPath.length - 1)
    }
    if (securedPages().indexOf(exactPath) >= 0) {
      Router.push('/')
    }
  }

  const checkResetPasswardFromChatBot = async () => {
    const userLoggedIn = storageAvailable('localStorage') && localStorage.getItem('loggedIn') === 'true'
    const includeForgotPass = asPath.toLowerCase().includes(forgotPassword)
    const includeChangePass = asPath.toLowerCase().includes(changePassword)
    if (includeForgotPass && userLoggedIn) {
      await Router.push(changePassword)
    } else if (includeChangePass && !userLoggedIn) {
      await Router.push(login)
      LoginStore.showSignInPopup = true
    }
    LoginStore.forgotPasswordLinkLoader = false
  }

  const callAPI = async () => {
    const couponsPage = asPath.toLowerCase().includes('hbcyou')
      || asPath.toLowerCase().includes(myCoupons)
      || asPath.toLowerCase().includes(dawnDucks)

    const redirectToHome = asPath.toLowerCase().includes('signup')
      || asPath.toLowerCase().includes('login')

    if (asPath.toLowerCase().includes('hbcyou')) {
      Router.replace('/account-setup/hbcyou/')
    } else if (asPath.toLowerCase().includes('gillette-labs-coupon')) {
      Router.replace('/account-setup/gillette-labs-coupon/')
    } else if (asPath.toLowerCase().includes('olaybody-coupon')) {
      Router.replace(olaySuperSerumBodyCoupons)
    } else if (asPath.toLowerCase().includes('tampax-coupon')) {
      Router.replace(tampaxCoupon)
    } else if (asPath.toLowerCase().includes(olayCouponsCampaign)) {
      Router.replace(accountSetupOlayCoupons)
    } else if (asPath.toLowerCase().includes(oldSpiceCoupon)) {
      Router.replace('/account-setup/old-spice-coupon/')
    } else if (asPath.toLowerCase().includes(alwaysUdo)) {
      Router.replace('/account-setup/always-discreet-coupon-special-offer/')
    } else if (asPath.toLowerCase().includes(headandShouldersCoupon)) {
      Router.replace('/account-setup/hs-coupon/')
    } else if (asPath.toLowerCase().includes(tideDigitalCoupon)) {
      Router.replace('/account-setup/tide-digital-coupon/')
    } else if (asPath.toLowerCase().includes(oldSpiceCouponfamilyDollar)) {
      Router.replace(accountOldSpiceCouponfamilyDollar)
    } else if (redirectToHome && !couponsPage) {
      Router.push('/')
    }

    const response1 = await getUserprofile()
    if (response1?.status === 200) {
      const profileData = await response1.json()
      const data1 = updateToOldProfileData(profileData)
      await fetchPointsBalanceApi()
      LoginStore.profileData = data1.profile
      LoginStore.charityID = data1?.profile?.traitCharitableCause?.traitValue === null
        || data1?.profile?.traitCharitableCause?.traitValue === '018GPK'
        ? defaultCharity
        : data1?.profile?.traitCharitableCause?.traitValue
      LoginStore.rewardStatus = true
      LoginStore.userLoggedIn = true
      LoginStore.showSignInPopup = false
      LoginStore.simplifiedSignupFlow = false
      LoginStore.simplifiedSignupFlowWithCompleteProfileSurvey = false

      if (!localStorage.getItem('oktaCaptureToken_Expires')) {
        handleSession()
      }

      setTimeout(() => {
        sessionClearing(false, false)
      }, tokenToExpireinMS())

      setTimeout(() => {
        if (typeof window !== 'undefined') {
          const isSessionContinued = localStorage.getItem('sessionContinued')
          if (isSessionContinued === 'true') {
            LoginStore.showSessionExpiryModal = true
          }
        }
      }, tokenToExpireinMS(sessionExpiryMinute))
    } else {
      sessionClearing(false, false)
      localStorage.removeItem('loggedIn')
      handleRedirection()
    }
  }

  const previewEnable = async () => {
    if (process.env.BUILD_ENV === 'preview' && !LoginStore.ispreview) {
      const response = await fetch('/api/preview/', {
        method: 'get',
      })
      const data = await response.json()
      LoginStore.ispreview = data.preview
      if (!data.preview || !sessionStorage.getItem('preview')) {
        await fetch('/api/preview/', {
          method: 'post',
        })
        sessionStorage.setItem('preview', true)
        window.location.reload()
      }
    }
  }

  useEffect(() => {
    checkResetPasswardFromChatBot()
  }, [LoginStore.userLoggedIn])

  useEffect(() => {
    telemetry.initialize()
    if (blockGTM) {
      previewEnable()
      window.SEGMANTA__DYNAMIC_EMBED_CONFIG = {
        domain: 'https://sg.pggoodeveryday.com',
      }
      getCLS(sendWVToGlobalGTM)
      getFID(sendWVToGlobalGTM)
      getLCP(sendWVToGlobalGTM)
      getTTFB(sendWVToGlobalGTM)
      getFCP(sendWVToGlobalGTM)
      if (storageAvailable('localStorage') && localStorage.getItem('loggedIn') === 'true') {
        callAPI()
      } else {
        handleRedirection()
      }
    }
  }, [])

  useEffect(() => {
    const mainDataLayer = {
      url: window.location.pathname,
    }
    return () => {
      triggeVirtualPageview(mainDataLayer)
    }
  }, [asPath])

  useEffect(() => {
    // eslint-disable-next-line prefer-regex-literals
    const receiptPattern = new RegExp('.*?receiptscan.*')
    if (receiptPattern.test(window.location.search)) {
      ReceiptScanStore.setWidgetVisibility(true)
    }
    // if (asPath !== '/survey/') window.SEGMANTA__USER_METADATA = undefined
  }, [asPath, LoginStore.rewardStatus])

  useEffect(() => {
    const interval = setInterval(() => {
      pypestreamIframeChecked += 1
      const pypestreamIframe = document.getElementById('pypestream-iframe')
      if (pypestreamIframe) {
        clearInterval(interval)
        standardEventForChatBotLoad(STATUS_1043)
      }
      if (pypestreamIframeChecked > 10) {
        clearInterval(interval)
        standardEventForChatBotLoad(STATUS_1042)
      }
    }, 1000)
  }, [])

  const { Component, pageProps } = props
  const nonce = process.env.NONCE
  return (
    <>
      <Head nonce={nonce}>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, shrink-to-fit=no'
        />
      </Head>
      {blockGTM && (
        <Script
          async
          nonce={nonce}
          id='gtm-script1'
          strategy='afterInteractive'
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(`
            (
                  function(w,d,s,l,i) {
                  w[l]=w[l]||[];w[l].push({
                      'gtm.start' : new Date().getTime(),
                      event : 'gtm.js'
                  });
                  var f = d.getElementsByTagName(s)[0],
                  j=d.createElement(s),
                  dl = l!='dataLayer'?'&l='+l:'';

                  j.defer=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                  f.parentNode.insertBefore(j,f);
                  }
              )(window,document,'script','dataLayer','${process.env.GTM_ID}');
          `),
          }}
        />
      )}
      <DataLayer />
      {LoginStore.showCookiesDisabledodal && <CookiesDisabledPopup />}
      <div className={`${montserratFont.variable} font-Montserrat`}>
        <Component {...pageProps} />
        {blockGTM && (
          <noscript
            nonce={nonce}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(`<iframe title="googletagmanager" src='https://www.googletagmanager.com/ns.html?id=${process.env.GTM_ID}'
                                height='0' width='0' style='display:none;visibility:hidden'></iframe>`),
            }}
          />
        )}
      </div>
    </>
  )
}

export const getStaticProps = async ({ Component, ctx }) => {
  let pageProps = {}
  if (Component.getStaticProps) {
    pageProps = await Component.getStaticProps(ctx)
  }
  return {
    pageProps,
    Component,
  }
}

MyApp.propTypes = {
  pageProps: PropTypes.instanceOf(Object),
  Component: PropTypes.node,
  router: PropTypes.instanceOf(Object),
}
MyApp.defaultProps = {
  pageProps: {},
  Component: null,
  router: {},
}

export default withRouter(observer(MyApp))
