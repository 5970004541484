import React from 'react'

export default (active, className, color) => (
  <svg
    aria-labelledby='minusIcon'
    width='20px'
    height='19px'
    viewBox='0 0 20 19'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >

    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      id='minusIcon'
    >
      <g
        transform='translate(0.393564, 0.000000)'
        fill={color}
      >
        <g transform='translate(-1.793478, 7.206522)'>
          <path
            d='M22.2391304,0.84057971 L22.2391304,3.63768116 C22.2391304,3.87780496 22.0444716,4.07246377 21.8043478,4.07246377 L0.673913043,4.07246377 C0.433789239,4.07246377 0.239130435,3.87780496 0.239130435,3.63768116 L0.239130435,0.84057971 C0.239130435,0.600455906 0.433789239,0.405797101 0.673913043,0.405797101 L21.8043478,0.405797101 C22.0444716,0.405797101 22.2391304,0.600455906 22.2391304,0.84057971 Z'
          />
        </g>
      </g>
    </g>
  </svg>
)
