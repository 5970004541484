export default {
  wrapper: {
    default: 'submitBtn btn btn-primaryReceipt whitespace-pre-line',
    Blue: 'submitBtn btn btn-primary',
    DarkBlue: 'submitBtn btn btn-primaryReceipt whitespace-pre-line dawnCTA',
    CampaignBlue: 'submitBtn btn btn-campaignBlue whitespace-pre-line',
    lightBlueLogin:
      'submitBtn btn btn-lightLoginBtn whitespace-pre-line uppercase',
    Green: 'submitBtn btn btn-success',
    DarkPink: 'submitBtn btn btn-info',
    Red: 'submitBtn btn btn-warning',
    Teal: 'submitBtn btn btn-secondary',
    TealDigital:
      'submitBtn font-bold px-20 py-05 md:py-10 inline leading-small border-2 bg-couponsBlue hover:bg-white hover:border-3 hover:border-couponsBlue hover:text-couponsBlue text-white w-270 text-14 md:text-16 md:w-340',
    BrightBlue: 'submitBtn btn btn-brightBlue border-1',
    Black: 'submitBtn btn btn-black border-1',
    Disabled: 'submitBtn btn btn-disabled w-223 h-62',
    DarkPinkDigital: 'submitBtn btn btn-info w-223 h-62',
    couponsBlue:
      'submitBtn btn bg-couponsBlue text-white hover:bg-transpatent border-2 hover:border-couponsBlue hover:bg-white hover:text-couponsBlue cursor-pointer',
    blueBorder:
      'btn-border btn-primary-border text-primary uppercase cursor-pointer',
    'Blue-Transparent': 'submitBtn btn btn-primary-white',
    'DarkBlue-Transparent': 'submitBtn btn btn-primaryReceipt-white',
    'dawnBlue-Transparent':
      'submitBtn btn bg-white text-dawnBlue border-3 hover:bg-transparent hover:text-white',
    'Green-Transparent': 'submitBtn btn btn-success-white',
    'DarkPink-Transparent': 'submitBtn btn btn-info-white',
    'Red-Transparent': 'submitBtn btn btn-warning-white',
    'Teal-Transparent': 'submitBtn btn btn-secondary-white',
    'MyCoupon-Transparent': 'submitBtn btn btn-darkBlue-white',
    'couponsBlue-Transparent':
      'submitBtn btn text-couponsBlue bg-white hover:bg-transparent hover:text-white border-2',
    'BrightBlue-Transparent': 'submitBtn btn btn-brightBlue-white',
    'Teal-white':
      'text-white bg-transparent font-semibold underline  text-14 xl:text-18 leading-lightmedium xl:leading-widest pointer-events-none opacity-50',
    'Teal-white-disabled':
      'text-white bg-transparent font-semibold underline  text-14 xl:text-18 leading-lightmedium xl:leading-widest  cursor-pointer',
    'default-border':
      ' submitBtn btn hover:bgwhite border-3 border-white bg-transparent text-white hover:texttransparent ',
    'border-couponsBlue':
      ' submitBtn btn hover:bgwhite border-1 border-couponsBlue bg-transparent text-couponsBlue hover:texttransparent ',
    'border-black':
      ' submitBtn btn hover:bgwhite border-1 border-black bg-transparent text-black hover:texttransparent ',
    'border-teal-transparent':
      ' submitBtn btn hover:bgwhite border-3 border-white bg-transparent hover:bg-white hover:text-darkSecondaryTeal text-white hover:texttransparent ',
    'border-red-transparent':
      ' submitBtn btn border-3 border-white bg-transparent hover:bg-white hover:text-warning text-white hover:texttransparent ',
    'border-darkblue-transparent':
      ' submitBtn btn border-3 border-white bg-transparent hover:bg-white hover:text-primaryReceipt text-white hover:texttransparent ',
    'border-blue-transparent':
      ' submitBtn btn border-3 border-white bg-transparent hover:bg-white hover:text-primary text-white hover:texttransparent ',
    'border-green-transparent':
      ' submitBtn btn border-3 border-white bg-transparent hover:bg-white hover:text-success text-white hover:texttransparent ',
    'border-darkpink-transparent':
      ' submitBtn btn border-3 border-white bg-transparent hover:bg-white hover:text-info text-white hover:texttransparent ',
    'border-mycoupon-transparent':
      ' submitBtn btn hover:bgwhite border-3 border-white bg-transparent hover:bg-white text-white hover:text-darkBlue ',
    borderSustainability: 'submitBtn btn bg-sustainabilityBlue text-white hover:bg-transpatent border-2 cursor-pointer  hover:border-sustainabilityBlue hover:bg-white hover:text-sustainabilityBlue',
    'border-bordersustainability': 'submitBtn btn hover:bgwhite border-2 border-black bg-transparent text-black ',
  },
  headWrapper:
    'flex flex-wrap headWrapper pb-20 justify-center mx-5px mt-15px items-center pt-0 text-lg lg:text-23 font-montserratBold font-bold  text-formbutton focus:outline',
}

