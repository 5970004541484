import React from 'react'

export default (active, className) => (
  <svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0px'
    y='0px'
    viewBox='0 0 1000 1000'
    xmlSpace='preserve'
    className={className}
  >
    <g>
      <circle fill='#003DA6' cx='498.69' cy='486.12' r='316.75' />
      <path
        fill='#003DA6'
        d='M559.14,929.37l-15.82,33.99c-1.65,3.55-6.53,3.96-8.75,0.73l-21.27-30.87c-2.06-2.99-6.51-2.9-8.45,0.18
        l-19.95,31.74c-2.08,3.31-6.98,3.11-8.77-0.37l-17.23-33.3c-1.67-3.23-6.1-3.69-8.4-0.88l-23.77,28.99
        c-2.48,3.02-7.31,2.21-8.66-1.46l-12.92-35.19c-1.25-3.41-5.59-4.43-8.23-1.93l-27.22,25.78c-2.84,2.69-7.53,1.27-8.41-2.54
        l-8.41-36.53c-0.81-3.54-4.99-5.09-7.92-2.94l-30.24,22.16c-3.16,2.31-7.63,0.32-8.02-3.57l-3.76-37.3
        c-0.36-3.62-4.31-5.68-7.49-3.91l-32.77,18.2c-3.42,1.9-7.61-0.64-7.51-4.55l0.94-37.48c0.09-3.63-3.56-6.17-6.94-4.82l-34.8,13.95
        c-3.63,1.46-7.47-1.59-6.88-5.45l5.63-37.06c0.55-3.59-2.76-6.57-6.28-5.65l-36.27,9.48c-3.79,0.99-7.21-2.51-6.14-6.27
        l10.23-36.06c0.99-3.5-1.92-6.87-5.52-6.4l-37.17,4.86c-3.88,0.51-6.84-3.39-5.31-6.99l14.67-34.5c1.42-3.34-1.04-7.05-4.68-7.04
        l-37.49,0.16c-3.91,0.02-6.36-4.22-4.39-7.6l18.88-32.39c1.83-3.14-0.15-7.13-3.76-7.57l-37.21-4.54c-3.88-0.47-5.78-4.99-3.4-8.09
        l22.79-29.77c2.21-2.89,0.75-7.09-2.78-7.98l-36.35-9.17c-3.79-0.96-5.11-5.67-2.36-8.46l26.34-26.67
        c2.55-2.59,1.63-6.94-1.76-8.26l-34.91-13.65c-3.64-1.42-4.36-6.27-1.28-8.69l29.48-23.16c2.86-2.25,2.48-6.68-0.71-8.42
        l-32.93-17.92c-3.44-1.87-3.54-6.77-0.18-8.78l32.15-19.28c3.12-1.87,3.3-6.32,0.35-8.44l-30.42-21.91
        c-3.17-2.29-2.66-7.16,0.92-8.73l34.31-15.1c3.33-1.46,4.07-5.85,1.41-8.33l-27.44-25.55c-2.86-2.67-1.74-7.43,2.01-8.55
        l35.93-10.68c3.48-1.04,4.77-5.3,2.44-8.09L62.3,341.91c-2.51-3-0.8-7.59,3.06-8.23l36.99-6.1c3.59-0.59,5.4-4.66,3.44-7.72
        L85.56,288.3c-2.11-3.29,0.16-7.63,4.07-7.78l37.46-1.41c3.63-0.14,5.94-3.94,4.38-7.23l-16.1-33.85
        c-1.68-3.53,1.11-7.55,5.01-7.21l37.34,3.29c3.62,0.32,6.39-3.17,5.25-6.62l-11.73-35.6c-1.22-3.72,2.05-7.35,5.88-6.53l36.64,7.95
        c3.55,0.77,6.73-2.34,6.04-5.91l-7.18-36.79c-0.75-3.84,2.96-7.04,6.65-5.74l35.35,12.48c3.43,1.21,6.97-1.48,6.73-5.11l-2.51-37.4
        c-0.26-3.9,3.82-6.61,7.31-4.86l33.51,16.81c3.25,1.63,7.1-0.6,7.32-4.22l2.2-37.42c0.23-3.91,4.62-6.08,7.87-3.9l31.14,20.88
        c3.02,2.02,7.12,0.3,7.79-3.27l6.87-36.85c0.72-3.85,5.34-5.46,8.29-2.89l28.28,24.61c2.74,2.39,7.03,1.19,8.14-2.27l11.43-35.7
        c1.19-3.73,5.98-4.74,8.59-1.83l24.97,27.96c2.42,2.71,6.82,2.06,8.36-1.23l15.82-33.99c1.65-3.55,6.53-3.96,8.75-0.73l21.27,30.87
        c2.06,2.99,6.51,2.9,8.45-0.18l19.95-31.74c2.08-3.31,6.98-3.11,8.77,0.37l17.23,33.3c1.67,3.23,6.1,3.69,8.4,0.88l23.77-28.99
        c2.48-3.02,7.31-2.21,8.66,1.46l12.92,35.19c1.25,3.41,5.59,4.43,8.23,1.93l27.22-25.78c2.84-2.69,7.53-1.27,8.41,2.54l8.41,36.53
        c0.81,3.54,4.99,5.09,7.92,2.94l30.24-22.16c3.16-2.31,7.63-0.32,8.02,3.57l3.76,37.3c0.36,3.62,4.31,5.68,7.49,3.91l32.77-18.2
        c3.42-1.9,7.61,0.64,7.51,4.55l-0.94,37.48c-0.09,3.63,3.56,6.17,6.94,4.82l34.8-13.95c3.63-1.46,7.47,1.59,6.88,5.45l-5.63,37.06
        c-0.55,3.59,2.76,6.57,6.28,5.65l36.27-9.48c3.79-0.99,7.21,2.51,6.14,6.27l-10.23,36.06c-0.99,3.5,1.92,6.87,5.52,6.4l37.17-4.86
        c3.88-0.51,6.84,3.39,5.31,6.99l-14.67,34.5c-1.42,3.34,1.04,7.05,4.68,7.04l37.49-0.16c3.91-0.02,6.36,4.22,4.39,7.6l-18.88,32.39
        c-1.83,3.14,0.15,7.13,3.76,7.57l37.21,4.54c3.88,0.47,5.78,4.99,3.4,8.09l-22.79,29.76c-2.21,2.89-0.75,7.09,2.78,7.98l36.35,9.17
        c3.79,0.96,5.11,5.67,2.36,8.46l-26.34,26.67c-2.55,2.59-1.63,6.94,1.76,8.26l34.91,13.65c3.64,1.42,4.36,6.27,1.28,8.69
        l-29.48,23.16c-2.86,2.25-2.48,6.68,0.71,8.42l32.93,17.92c3.44,1.87,3.54,6.77,0.18,8.78l-32.15,19.28
        c-3.12,1.87-3.3,6.32-0.35,8.44l30.42,21.91c3.17,2.29,2.66,7.16-0.92,8.73l-34.31,15.1c-3.33,1.46-4.07,5.85-1.41,8.33
        l27.44,25.55c2.86,2.67,1.74,7.43-2.01,8.55l-35.93,10.68c-3.48,1.04-4.77,5.3-2.44,8.09l24.02,28.78c2.51,3,0.8,7.59-3.06,8.23
        l-36.99,6.1c-3.59,0.59-5.4,4.66-3.44,7.72l20.22,31.57c2.11,3.29-0.16,7.63-4.07,7.78l-37.46,1.41c-3.63,0.14-5.94,3.94-4.38,7.23
        l16.1,33.85c1.68,3.53-1.11,7.55-5.01,7.21l-37.34-3.29c-3.62-0.32-6.39,3.17-5.25,6.62l11.73,35.6c1.22,3.72-2.05,7.35-5.88,6.53
        l-36.64-7.95c-3.55-0.77-6.73,2.34-6.04,5.91l7.18,36.79c0.75,3.84-2.96,7.04-6.65,5.74l-35.35-12.48
        c-3.43-1.21-6.97,1.48-6.73,5.11l2.51,37.4c0.26,3.9-3.82,6.61-7.31,4.86l-33.51-16.81c-3.25-1.63-7.1,0.6-7.32,4.22l-2.2,37.42
        c-0.23,3.91-4.62,6.08-7.87,3.9l-31.14-20.88c-3.02-2.02-7.12-0.3-7.79,3.27l-6.87,36.85c-0.72,3.85-5.34,5.46-8.29,2.89
        l-28.28-24.61c-2.74-2.39-7.03-1.19-8.14,2.27l-11.43,35.7c-1.19,3.73-5.98,4.74-8.59,1.83l-24.97-27.96
        C565.07,925.42,560.67,926.07,559.14,929.37z'
      />
      <g>
        <g>
          <path
            fill='#FFFFFF'
            d='M459.98,534.33l6.86,72.64l-252.17,23.82l-5.42-57.37L319.77,448.6c10.67-12.21,17.64-22.44,20.91-30.68c3.27-8.24,4.52-16.38,3.76-24.4c-0.99-10.49-5.31-18.33-12.96-23.52c-7.64-5.18-18.26-7.14-31.83-5.86
            c-12.03,1.14-22.8,4.88-32.32,11.22c-9.52,6.35-17.03,14.91-22.55,25.7l-71.6-31.51c10.67-22.17,26.85-40.35,48.54-54.53
            c21.68-14.19,48.1-22.75,79.27-25.69c24.67-2.33,46.88-0.38,66.6,5.85c19.73,6.23,35.62,16.32,47.68,30.27
            c12.06,13.96,19.01,30.81,20.87,50.54c1.66,17.59-0.54,34.53-6.63,50.81c-6.1,16.3-19,35.34-38.73,57.11l-55.74,62.22
            L459.98,534.33z'
          />
          <path
            fill='#FFFFFF'
            d='M714.84,583.55l-76.57-94.07l-56.69,106.66l-104.58,9.88l102.52-174.94L451.51,281.64l103.18-9.74
            l73.6,87.35l55.03-99.5l99.02-9.35l-98.13,167.06l136.13,156.13L714.84,583.55z'
          />
        </g>
      </g>
      <g>
        <text transform='matrix(0.9956 -0.094 0.094 0.9956 222.5295 762.1945)' fill='#FFFFFF' fontWeight='700' fontFamily='Montserrat' fontSize='130.5272px' letterSpacing='1'>Do-Good</text>
      </g>
      <text transform='matrix(0.9956 -0.094 0.094 0.9956 732.326 436.1299)' fill='#FFFFFF' fontWeight='600' fontFamily='Montserrat' fontSize='51.6362px' letterSpacing='1'>the </text>
    </g>
  </svg>
)
