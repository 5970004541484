import React from 'react'

export default (active, className) => (
  <svg
    width='66px'
    height='66px'
    viewBox='0 0 66 66'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-687.000000, -1533.000000)'>
        <g transform='translate(687.000000, 1533.000000)'>
          <circle fill='#2BAD9A' cx='33' cy='33' r='33' />
          <g transform='translate(18.480000, 17.160000)' stroke='#FFFFFF' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2.5'>
            <line x1='6.81956947' y1='6.37347032' x2='17.0489237' y2='6.37347032' transform='translate(11.934247, 6.373470) scale(-1, 1) translate(-11.934247, -6.373470) ' />
            <line x1='6.81956947' y1='12.5264384' x2='17.0489237' y2='12.5264384' transform='translate(11.934247, 12.526438) scale(-1, 1) translate(-11.934247, -12.526438) ' />
            <line x1='6.81956947' y1='19.5584018' x2='17.0489237' y2='19.5584018' transform='translate(11.934247, 19.558402) scale(-1, 1) translate(-11.934247, -19.558402) ' />
            <path d='M26.7911658,28.5673516 C26.7911658,26.8681069 28.1540649,25.4908676 29.8356164,25.4908676 L11.5689125,25.4908676 C9.88736092,25.4908676 8.52446184,26.8681069 8.52446184,28.5673516 L8.52446184,31.6438356 L26.7911658,31.6438356 L26.7911658,28.5673516 Z' transform='translate(19.180039, 28.567352) scale(-1, 1) translate(-19.180039, -28.567352) ' />
            <path d='M14.1282987,25.3084163 C15.8618256,25.3084163 16.7389432,26.7264943 16.7389432,28.476126 C16.7389432,30.2257576 18.1439683,31.6438356 19.8774951,31.6438356 C21.6110219,31.6438356 23.016047,30.2257576 23.016047,28.476126 L23.016047,0 L0,0 L0,25.3084163 L14.1282987,25.3084163 Z' transform='translate(11.508023, 15.821918) scale(-1, 1) translate(-11.508023, -15.821918) ' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
