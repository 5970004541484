import React from 'react'

const PlusIcon = (active, className, color) => (active ? (
  <svg
    width='20px'
    height='20px'
    viewBox='0 0 20 20'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        transform='translate(-18.000000, -18.000000)'
        fill={color}
      >
        <path
          d='M28.6221361,18 C29.0655747,18 29.4285714,18.3996103 29.4285714,18.8882695 L29.428,26.571 L37.1117305,26.5714286 C37.6003897,26.5714286 38,26.9346494 38,27.378088 L38,28.6221361 C38,29.0655747 37.6003897,29.4285714 37.1117305,29.4285714 L29.428,29.428 L29.4285714,37.1119772 C29.4285714,37.6003897 29.0655747,38 28.6221361,38 L27.378088,38 C26.9346494,38 26.5714286,37.6003897 26.5714286,37.1119772 L26.571,29.428 L18.8880228,29.4285714 C18.3996103,29.4285714 18,29.0655747 18,28.6221361 L18,27.378088 C18,26.9346494 18.3996103,26.5714286 18.8880228,26.5714286 L26.571,26.571 L26.5714286,18.8882695 C26.5714286,18.3996103 26.9346494,18 27.378088,18 L28.6221361,18 Z'
        />
      </g>
    </g>
  </svg>
) : (
  <svg
    width='20px'
    height='20px'
    viewBox='0 0 20 20'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        transform='translate(-18.000000, -18.000000)'
        fill={color}
      >
        <path
          d='M28.6221361,18 C29.0655747,18 29.4285714,18.3996103 29.4285714,18.8882695 L29.428,26.571 L37.1117305,26.5714286 C37.6003897,26.5714286 38,26.9346494 38,27.378088 L38,28.6221361 C38,29.0655747 37.6003897,29.4285714 37.1117305,29.4285714 L29.428,29.428 L29.4285714,37.1119772 C29.4285714,37.6003897 29.0655747,38 28.6221361,38 L27.378088,38 C26.9346494,38 26.5714286,37.6003897 26.5714286,37.1119772 L26.571,29.428 L18.8880228,29.4285714 C18.3996103,29.4285714 18,29.0655747 18,28.6221361 L18,27.378088 C18,26.9346494 18.3996103,26.5714286 18.8880228,26.5714286 L26.571,26.571 L26.5714286,18.8882695 C26.5714286,18.3996103 26.9346494,18 27.378088,18 L28.6221361,18 Z'
        />
      </g>
    </g>
  </svg>
))

export default PlusIcon
