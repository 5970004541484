import React from 'react'
import crypto from 'crypto'

export default (active, className) => {
  const randomID = crypto.randomBytes(12).toString('hex')
  return (
    <svg
      width='79px'
      height='79px'
      viewBox='0 0 79 79'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      className={className}
    >
      <defs>
        <polygon
          id={`path-${randomID}`}
          points='0 0.000219444444 79 0.000219444444 79 79 0 79'
        />
        <linearGradient
          x1='122.238125%'
          y1='151.536121%'
          x2='-1.85068472%'
          y2='-26.7100122%'
          id={`linearGradient-${randomID}`}
        >
          <stop stopColor='#FF6F61' offset='0%' />
          <stop stopColor='#FF465B' offset='100%' />
        </linearGradient>
      </defs>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          transform='translate(-676.000000, -4388.000000)'
        >
          <g
            transform='translate(528.000000, 4251.000000)'
          >
            <g transform='translate(148.000000, 137.000000)'>
              <g>
                <mask id={`mask-${randomID}`} fill='white'>
                  <use xlinkHref={`#path-${randomID}`} />
                </mask>
                <g />
                <path
                  d='M39.5,0.000219444444 C61.3149722,0.000219444444 79,17.6852472 79,39.5002194 C79,61.3151917 61.3149722,79.0002194 39.5,79.0002194 C17.6850278,79.0002194 0,61.3151917 0,39.5002194 C0,17.6852472 17.6850278,0.000219444444 39.5,0.000219444444'
                  fill={`url(#linearGradient-${randomID})`}
                  mask={`url(#mask-${randomID})`}
                />
              </g>
              <g
                transform='translate(20.000000, 21.000000)'
                stroke='#FFFFFF'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2.15'
              >
                <path
                  d='M3.07875806,15.4461129 L35.5923065,15.4461129 L35.5923065,35.4924355 C35.5923065,37.7367581 33.7738226,39.5580161 31.5295,39.5580161 L7.14295161,39.5580161 C4.89862903,39.5580161 3.07875806,37.7367581 3.07875806,35.4924355 L3.07875806,15.4461129 Z'
                />
                <polygon
                  points='0.733870968 15.4468065 38.2506774 15.4468065 38.2506774 9.19377419 0.733870968 9.19377419'
                />
                <path
                  d='M19.3757581,7.6287129 C17.8014032,5.01264839 16.2006935,3.08319677 14.5777903,1.84174516 C11.2210161,-0.722996774 7.86979032,1.64616452 8.4398871,4.10687419 C9.10153226,6.96568065 12.7468226,8.14055161 19.3757581,7.6287129 Z'
                />
                <path
                  d='M19.8479258,7.6287129 C21.4236677,5.01264839 23.0229903,3.08319677 24.6458935,1.84174516 C28.0026677,-0.722996774 31.3538935,1.64616452 30.7837968,4.10687419 C30.1221516,6.96568065 26.4768613,8.14055161 19.8479258,7.6287129 Z'
                />
                <line
                  x1='19.5599645'
                  y1='15.7779065'
                  x2='19.5599645'
                  y2='39.4944871'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
