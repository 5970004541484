import React from 'react'

export default (active, className) => (
  <svg
    width='20px'
    height='20px'
    viewBox='0 0 20 20'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
    role='img'
    aria-label='info icon'
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g
        transform='translate(-2761.000000, -1416.000000)'
        fill='#267EA6'
        fillRule='nonzero'
      >
        <g transform='translate(1833.000000, 217.000000)'>
          <g transform='translate(193.000000, 337.000000)'>
            <g transform='translate(722.000000, 696.000000)'>
              <g transform='translate(14.000000, 167.000000)'>
                <g>
                  <path d='M8.5712,6.83168 C8.8176,6.83168 9.02,6.75248 9.1784,6.59408 C9.3368,6.43568 9.416,6.24032 9.416,6.008 C9.416,5.78976 9.33504,5.60848 9.17312,5.46416 C9.0112,5.31984 8.81056,5.24768 8.5712,5.24768 C8.33184,5.24768 8.1312,5.32336 7.96928,5.47472 C7.80736,5.62608 7.7264,5.8144 7.7264,6.03968 C7.7264,6.26496 7.80736,6.45328 7.96928,6.60464 C8.1312,6.756 8.33184,6.83168 8.5712,6.83168 Z M9.22592,13.4 L9.22592,7.76096 L7.90592,7.76096 L7.90592,13.4 L9.22592,13.4 Z' />
                  <path d='M8.8,-0.36 C3.45388362,-0.36 -0.88,3.97388362 -0.88,9.32 C-0.88,14.6661164 3.45388362,19 8.8,19 C14.1461164,19 18.48,14.6661164 18.48,9.32 C18.48,3.97388362 14.1461164,-0.36 8.8,-0.36 Z M8.8,1.4 C13.1740952,1.4 16.72,4.94590478 16.72,9.32 C16.72,13.6940952 13.1740952,17.24 8.8,17.24 C4.42590478,17.24 0.88,13.6940952 0.88,9.32 C0.88,4.94590478 4.42590478,1.4 8.8,1.4 Z' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
