import React from 'react'

const TakeSurveyIcon = (active, className) => (active ? (
  <svg
    width='18.4px'
    height='24.7px'
    viewBox='0 0 18.4 24.7'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g transform='translate(-178.3, -5525)'>
        <g transform='translate(179, 5525)'>
          <path d='M15.7557604,24 L1.24423963,24 C0.559907834,24 0,23.4596548 0,22.7992328 L0,5.20076716 C0,4.54034522 0.559907834,4 1.24423963,4 L15.7557604,4 C16.4400922,4 17,4.54034522 17,5.20076716 L17,22.7992328 C17,23.4596548 16.4400922,24 15.7557604,24 Z' id='Stroke-1' stroke='#5E5E5E' strokeWidth='1.4' />
          <path d='M11,2.5 C11,3.88124439 9.88049656,5 8.5,5 C7.11875561,5 6,3.88124439 6,2.5 C6,1.11950344 7.11875561,0 8.5,0 C9.88049656,0 11,1.11950344 11,2.5' fill='#5E5E5E' />
          <path d='M6.47449955,1 C6.47449955,1.2838929 6.53085554,1.55333617 6.63196481,1.80067998 C2.95639424,2.53676158 2,5 2,5 L15,5 C15,5 14.042777,2.53676158 10.3680352,1.80067998 C10.4683157,1.55333617 10.5246717,1.2838929 10.5246717,1' fill='#5E5E5E' />
          <path d='M9,2.5 C9,2.77586207 8.77586207,3 8.5,3 C8.22361546,3 8,2.77586207 8,2.5 C8,2.22361546 8.22361546,2 8.5,2 C8.77586207,2 9,2.22361546 9,2.5' fill='#FFFFFF' />
          <path d='M4,10.5 L13,10.5 L4,10.5 Z' stroke='#339DD0' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M4,14.5 L13,14.5 L4,14.5 Z' stroke='#339DD0' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M4,18.5 L13,18.5 L4,18.5 Z' stroke='#339DD0' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
        </g>
      </g>
    </g>
  </svg>
) : (
  <svg
    width='18.4px'
    height='24.7px'
    viewBox='0 0 18.4 24.7'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g transform='translate(-178.3, -5525)'>
        <g transform='translate(179, 5525)'>
          <path d='M15.7557604,24 L1.24423963,24 C0.559907834,24 0,23.4596548 0,22.7992328 L0,5.20076716 C0,4.54034522 0.559907834,4 1.24423963,4 L15.7557604,4 C16.4400922,4 17,4.54034522 17,5.20076716 L17,22.7992328 C17,23.4596548 16.4400922,24 15.7557604,24 Z' id='Stroke-1' stroke='#5E5E5E' strokeWidth='1.4' />
          <path d='M11,2.5 C11,3.88124439 9.88049656,5 8.5,5 C7.11875561,5 6,3.88124439 6,2.5 C6,1.11950344 7.11875561,0 8.5,0 C9.88049656,0 11,1.11950344 11,2.5' fill='#5E5E5E' />
          <path d='M6.47449955,1 C6.47449955,1.2838929 6.53085554,1.55333617 6.63196481,1.80067998 C2.95639424,2.53676158 2,5 2,5 L15,5 C15,5 14.042777,2.53676158 10.3680352,1.80067998 C10.4683157,1.55333617 10.5246717,1.2838929 10.5246717,1' fill='#5E5E5E' />
          <path d='M9,2.5 C9,2.77586207 8.77586207,3 8.5,3 C8.22361546,3 8,2.77586207 8,2.5 C8,2.22361546 8.22361546,2 8.5,2 C8.77586207,2 9,2.22361546 9,2.5' fill='#FFFFFF' />
          <path d='M4,10.5 L13,10.5 L4,10.5 Z' stroke='#339DD0' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M4,14.5 L13,14.5 L4,14.5 Z' stroke='#339DD0' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M4,18.5 L13,18.5 L4,18.5 Z' stroke='#339DD0' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
        </g>
      </g>
    </g>
  </svg>
))

export default TakeSurveyIcon
