import React, { useEffect, useState } from 'react'
import Head from 'next/head'
import DOMPurify from 'isomorphic-dompurify'
import LoginStore from '../UserManagement/Login-store'

const DataLayer = () => {
  const [user, setUserData] = useState(undefined)

  useEffect(() => {
    const { profileData } = LoginStore
    if (!profileData) return
    setUserData({
      firstname: profileData.name,
      email: profileData?.emailAddress,
      id: profileData.consumerId || profileData.uuid,
      marketingProgramNbr: profileData.marketingProgramNbr,
      sourceID: profileData.sourceId,
    })
  }, [])

  return user ? (
    <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(`PGdataLayer.user=${JSON.stringify(user)}`),
        }}
      />
    </Head>
  ) : null
}

export default DataLayer
