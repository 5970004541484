import { makeAutoObservable } from 'mobx'

const customFieldReference = {
  rewardType: 'Reward_Type',
  charityId: 'Charity_ID',
  status: 'Reward_Status',
  description: 'Reward_Detail',
  rules: 'Reward_Rules',
  merchant: 'Other_Details',
}
export default class Reward {
  id

  name

  cost

  type

  eligible

  inventoryIsAvailable = true

  charityId

  status

  description

  rules

  merchantDetails

  available

  availableQuantity

  websiteImage

  constructor({
    rewardTypeExternalReference,
    rewardTypeName,
    rewardTypePointCost,
    memberIsEligible,
    rewardCustomFields,
    availableQuantity,
    websiteImage,
  }) {
    makeAutoObservable(this)
    this.id = rewardTypeExternalReference
    this.name = rewardTypeName
    this.cost = rewardTypePointCost
    this.eligible = memberIsEligible === 1
    this.availableQuantity = availableQuantity
    this.websiteImage = websiteImage

    rewardCustomFields.rewardCustomFieldItem.map(
      ({ rewardCustomFieldExternalReference, rewardCustomFieldValue }) => {
        switch (rewardCustomFieldExternalReference) {
          case customFieldReference.rewardType:
            this.type = rewardCustomFieldValue.dropDownValue
            break
          case customFieldReference.charityId:
            this.charityId = rewardCustomFieldValue.dropDownValue
            break
          case customFieldReference.status:
            this.status = rewardCustomFieldValue.dropDownValue
            this.available = rewardCustomFieldValue.dropDownValue !== 'Unavailable'
            break
          case customFieldReference.description:
            this.description = rewardCustomFieldValue.textValue
            break
          case customFieldReference.rules:
            this.rules = rewardCustomFieldValue.textValue
            break
          case customFieldReference.merchant:
            this.merchantDetails = rewardCustomFieldValue.textValue
            break
          default:
            break
        }
        return null
      },
    )
  }
}
