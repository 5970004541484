import React from 'react'

export default (active, className) => (
  <svg
    role='img'
    width='27px'
    height='27px'
    viewBox='0 0 27 27'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
    aria-label='blue search icon'
  >
    <title>Search Icon</title>
    <desc>Search Icon</desc>
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
      id='blue-search-icon'
    >
      <g
        transform='translate(-2783.000000, -527.000000)'
        stroke='#4CC5D9'
        strokeWidth='1.31578947'
      >
        <g transform='translate(1833.000000, 438.000000)'>
          <g
            transform='translate(1.000000, 52.000000)'
          >
            <g transform='translate(950.000000, 37.000000)'>
              <g
                transform='translate(0.000000, 1.000000)'
              >
                <line
                  x1='24.3980263'
                  y1='24.3979605'
                  x2='20.3947368'
                  y2='20.3946711'
                />
                <path
                  d='M10,0.526315789 C15.523125,0.526315789 20,5.00381579 20,10.5263158 C20,16.0494408 15.523125,20.5263158 10,20.5263158 C4.4775,20.5263158 0,16.0494408 0,10.5263158 C0,5.00381579 4.4775,0.526315789 10,0.526315789 Z'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
