import React from 'react'
import crypto from 'crypto'

const randomID = () => crypto.randomBytes(12).toString('hex')
const PointsIcon = (active, className) => (active ? (
  <svg
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g
        transform='translate(1.000000, 1.000000)'
        stroke='#FFFFFF'
        strokeWidth='1.75'
      >
        <path
          d='M11,16.5 C14.0375661,16.5 16.5,14.0375661 16.5,11 C16.5,14.0375661 18.9624339,16.5 22,16.5'
        />
        <path
          d='M11,16.5 C14.0375661,16.5 16.5,18.9624339 16.5,22 C16.5,18.9624339 18.9624339,16.5 22,16.5'
        />
        <path
          d='M0,5.5 C3.03756612,5.5 5.5,3.03756612 5.5,-4.4408921e-16 C5.5,3.03756612 7.96243388,5.5 11,5.5'
        />
        <path
          d='M0,5.5 C3.03756612,5.5 5.5,7.96243388 5.5,11 C5.5,7.96243388 7.96243388,5.5 11,5.5'
        />
        <line x1='1.1' y1='18.7' x2='5.5' y2='18.7' />
        <line x1='3.3' y1='16.5' x2='3.3' y2='20.9' />
        <line x1='16.5' y1='3.3' x2='20.9' y2='3.3' />
        <line x1='18.7' y1='1.1' x2='18.7' y2='5.5' />
      </g>
    </g>
  </svg>
) : (
  <svg
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
      id={`pointsIcon-${randomID()}`}
    >
      <g transform='translate(1.000000, 1.000000)' strokeWidth='1.75'>
        <path
          d='M11,16.5 C14.0375661,16.5 16.5,14.0375661 16.5,11 C16.5,14.0375661 18.9624339,16.5 22,16.5'
          stroke='#5E5E5E'
        />
        <path
          d='M11,16.5 C14.0375661,16.5 16.5,18.9624339 16.5,22 C16.5,18.9624339 18.9624339,16.5 22,16.5'
          stroke='#5E5E5E'
        />
        <path
          d='M0,5.5 C3.03756612,5.5 5.5,3.03756612 5.5,-4.4408921e-16 C5.5,3.03756612 7.96243388,5.5 11,5.5'
          stroke='#5E5E5E'
        />
        <path
          d='M0,5.5 C3.03756612,5.5 5.5,7.96243388 5.5,11 C5.5,7.96243388 7.96243388,5.5 11,5.5'
          stroke='#5E5E5E'
        />
        <line x1='1.1' y1='18.7' x2='5.5' y2='18.7' stroke='#309DD0' />
        <line x1='3.3' y1='16.5' x2='3.3' y2='20.9' stroke='#309DD0' />
        <line x1='16.5' y1='3.3' x2='20.9' y2='3.3' stroke='#309DD0' />
        <line x1='18.7' y1='1.1' x2='18.7' y2='5.5' stroke='#309DD0' />
      </g>
    </g>
  </svg>
))

export default PointsIcon
