import React from 'react'

const ProfileIcon = (active, className) => (active ? (
  <svg role='img' width='23px' height='23px' viewBox='0 0 24 24' version='1.1' className={className}>
    <title>Profile Menu</title>
    <desc>Profile Menu</desc>
    <g stroke='none' id='profile-icon' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-1697.000000, -233.000000)' stroke='#46B5D9'>
        <g transform='translate(1488.000000, 217.000000)'>
          <g transform='translate(168.000000, 16.000000)'>
            <g transform='translate(41.000000, 0.000000)'>
              <g>
                <circle cx='12' cy='12' r='11.04' />
                <rect x='8.64' y='6.72' width='6.72' height='9.6' rx='3.36' />
                <path d='M6.72,21.12 C8.18666667,19.84 9.94666667,19.2 12,19.2 C14.0533333,19.2 15.8133333,19.84 17.28,21.12' strokeLinecap='square' />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
) : (
  <svg
    role='img'
    aria-label='Profile Icon'
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <title>Profile Icon</title>
    <desc>Profile Icon</desc>
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      id='profileIcon'
      fillRule='evenodd'
    >
      <g
        transform='translate(-3188.000000, -238.000000)'
      >
        <g
          transform='translate(1832.000000, 200.000000)'
        >
          <g
            transform='translate(1310.000000, 38.000000)'
          >
            <g
              transform='translate(5.000000, 0.000000)'
            >
              <g
                transform='translate(41.000000, 0.000000)'
              >
                <circle
                  stroke='#5E5E5E'
                  cx='12'
                  cy='12'
                  r='11.04'
                />
                <rect
                  stroke='#5D5D5D'
                  x='8.64'
                  y='6.72'
                  width='6.72'
                  height='9.6'
                  rx='3.36'
                />
                <path
                  d='M6.72,21.12 C8.18666667,19.84 9.94666667,19.2 12,19.2 C14.0533333,19.2 15.8133333,19.84 17.28,21.12'
                  stroke='#5D5D5D'
                  strokeLinecap='square'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
)

export default ProfileIcon
