import React from 'react'
import crypto from 'crypto'

export default (active, className) => {
  const randomID = crypto.randomBytes(12).toString('hex')
  return (
    <svg
      width='79px'
      height='79px'
      viewBox='0 0 79 79'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      className={className}
    >
      <defs>
        <polygon id={`path-${randomID}`} points='0 0.000219444444 79 0.000219444444 79 79 0 79' />
        <linearGradient
          x1='22.9334275%'
          y1='-87.8671954%'
          x2='59.9638036%'
          y2='204.905955%'
          id={`linearGradient-${randomID}`}
        >
          <stop stopColor='#A400A6' offset='0%' />
          <stop stopColor='#E04748' offset='100%' />
        </linearGradient>
      </defs>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-2513.000000, -429.000000)'>
          <g transform='translate(1832.000000, 207.000000)'>
            <g transform='translate(681.000000, 222.000000)'>
              <g>
                <mask id={`mask-${randomID}`} fill='white'>
                  <use xlinkHref={`#path-${randomID}`} />
                </mask>
                <g />
                <path
                  d='M39.5,0.000219444444 C61.3149722,0.000219444444 79,17.6852472 79,39.5002194 C79,61.3151917 61.3149722,79.0002194 39.5,79.0002194 C17.6850278,79.0002194 0,61.3151917 0,39.5002194 C0,17.6852472 17.6850278,0.000219444444 39.5,0.000219444444'
                  fill={`url(#linearGradient-${randomID})`}
                  mask={`url(#mask-${randomID})`}
                />
              </g>
              <g transform='translate(24.000000, 18.000000)'>
                <path
                  d='M28.2162368,42.35285 L2.30534211,42.35285 C1.08323684,42.35285 0.0840526316,41.4159026 0.0840526316,40.2696132 L0.0840526316,9.74640263 C0.0840526316,8.60011316 1.08323684,7.66316579 2.30534211,7.66316579 L28.2162368,7.66316579 C29.4383421,7.66316579 30.4375263,8.60011316 30.4375263,9.74640263 L30.4375263,40.2696132 C30.4375263,41.4159026 29.4383421,42.35285 28.2162368,42.35285 Z'
                  stroke='#FFFFFF'
                  strokeWidth='2.15'
                />
                <path
                  d='M14.8622474,7.12351579 C14.8622474,7.5852 11.6485632,3.06793684 11.8341421,3.46964737 C5.09106316,4.66685789 3.33598421,8.67264737 3.33598421,8.67264737 L27.1851421,8.67264737 C27.1851421,8.67264737 25.4289316,4.66685789 18.6881158,3.46964737 C18.8714316,3.06793684 16.8719316,7.92806842 16.8719316,7.46751579'
                  fill='#FFFFFF'
                />
                <path
                  d='M15.2611289,5.42071579 C14.6613921,5.42071579 14.1770763,4.93413684 14.1770763,4.33666316 C14.1770763,3.73692632 14.6613921,3.25261053 15.2611289,3.25261053 C15.8586026,3.25261053 16.3451816,3.73692632 16.3451816,4.33666316 C16.3451816,4.93413684 15.8586026,5.42071579 15.2611289,5.42071579 M15.2611289,0.000452631579 C12.8655763,0.000452631579 10.9249184,1.94224211 10.9249184,4.33666316 C10.9249184,6.73221579 12.8655763,8.67287368 15.2611289,8.67287368 C17.65555,8.67287368 19.5973395,6.73221579 19.5973395,4.33666316 C19.5973395,1.94224211 17.65555,0.000452631579 15.2611289,0.000452631579'
                  fill='#FFFFFF'
                />
                <path
                  d='M7.93687105,17.9486526 L23.1136079,17.9486526 L7.93687105,17.9486526 Z'
                  stroke='#FFFFFF'
                  strokeWidth='2.15'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M7.93687105,25.5371342 L23.1136079,25.5371342 L7.93687105,25.5371342 Z'
                  stroke='#FFFFFF'
                  strokeWidth='2.15'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M7.93687105,33.1256158 L23.1136079,33.1256158 L7.93687105,33.1256158 Z'
                  stroke='#FFFFFF'
                  strokeWidth='2.15'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
