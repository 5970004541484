import React from 'react'
import crypto from 'crypto'

export default (active, className, color) => {
  const randomID = crypto.randomBytes(12).toString('hex')
  return (
    <svg
      width='30px'
      height='25px'
      viewBox='0 -1 30 27'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      className={className}
    >

      <g id={`pgBrandheart-open-${randomID}`} stroke={color} strokeWidth={color ? '0' : '1'} fill={color || '#fff'} fillRule='evenodd' strokeLinejoin='round'>
        <path d='M15,4.55304707 C15.7322165,3.2723563 16.5074596,2.25943526 17.4340598,1.51280915 C18.6861602,0.504376586 20.1696879,0 22.1286725,0 C24.3014537,0 26.2693493,0.888846711 27.6934925,2.32381285 C29.1178904,3.7585225 30,5.74076223 30,7.92966428 C30,13.124243 26.6569708,15.6889588 22.2163807,19.0937893 C20.1519935,20.6782858 17.8367661,22.452645 15.5256123,24.7822928 C15.2359463,25.0725691 14.7653266,25.0725691 14.4756607,24.7822928 C12.1634248,22.4529014 9.84940679,20.6768111 7.78361933,19.0923145 C3.3446204,15.6886382 0,13.1210369 0,7.92947192 C0,5.74210878 0.882109578,3.75807365 2.30803503,2.323364 C3.7304598,0.888654348 5.69835531,0 7.87107287,0 C9.82999389,0 11.3135852,0.504184222 12.5656856,1.51261679 C13.4923494,2.2607818 14.2675289,3.27344636 14.9997454,4.5528547 L15,4.55304707 Z' stroke='#5E5E5E' />
      </g>
    </svg>
  )
}
