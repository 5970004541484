import {
  fetchMemberPoints,
  issueInteraction,
  fetchRestrictions,
  reSendGiftCard,
  issueReward,
  getUserprofile,
  revtraxUIDRegistration,
  readMessage,
} from '../adapters/serverside.adapters'
import AimiaStore from '../containers/AIMIA/aimia.store'
import LoginStore from '../components/UserManagement/Login-store'
import { throwAIMIAError } from '../okta/forms/okta.helpers'

/**
 * This function validate the traits value feild and if it is empty string then return
 * null value otherwise returns traitvalue
 * @param {*} value pass traitvalue to validate
 */
const validateCDPValue = (value) => {
  if (!value) return null
  return value.trim() === '' ? null : value
}

const setTraitsValue = (traits = []) => {
  const alltraits = {
    profileCompleted: false,
    profile: {
      uuid: '',
      name: '',
      familyName: null,
      consumerId: '',
      loyaltyId: null,
      emailAddress: '',
      birthday: null,
      gender: null,
      phone: null,
      marketingProgramNbr: '',
      sourceId: '',
      emailVerified: '',
    },
    address: {
      zip: null,
      address1: null,
      address2: null,
      territory: null,
      city: null,
    },
    traits: {
      traitCharitableCause: {
        traitValue: null,
      },
      traitCampaignName: {
        traitValue: null,
      },
      traitSendWelcomeEmail: {
        traitValue: null,
      },
      traitLoyaltyProgramUser: {
        traitValue: null,
      },
      traitFavoriteBrands: '',
      traitGUID: {
        traitValue: null,
      },
      umoCohortClassification: {
        traitValue: '',
      },
    },
  }
  traits.forEach((trait) => {
    switch (trait.traitName) {
      case 'firstName':
        alltraits.profile.name = validateCDPValue(trait.values)
        break
      case 'birthDate':
        alltraits.profile.birthday = validateCDPValue(trait.values)
        break
      case 'gender':
        alltraits.profile.gender = validateCDPValue(trait.values)
        break
      case 'emailVerified':
        alltraits.profile.emailVerified = validateCDPValue(trait.values) === 'true'
        break
      case 'campaignName':
        alltraits.traits.traitCampaignName.traitValue = validateCDPValue(
          trait.values,
        )
        break
      case 'charitableCause':
        alltraits.traits.traitCharitableCause.traitValue = validateCDPValue(
          trait.values,
        )
        break
      case 'loyaltyProgramUser':
        alltraits.traits.traitLoyaltyProgramUser.traitValue = validateCDPValue(
          trait.values,
        )
        break
      case 'favoriteBrands':
        alltraits.traits.traitFavoriteBrands = trait.values
        break
      case 'couponGUID':
        alltraits.traits.traitGUID.traitValue = trait.values
        break
      case 'umoCohortClassification':
        alltraits.traits.umoCohortClassification.traitValue = trait.values
        break
      default:
        break
    }
  })

  return alltraits
}

/**
 * This function converts the profiledata to required format
 * @param {*} profileData pass profile data from login api response
 */
export const updateToOldProfileData = (profileData) => {
  const alltraits = setTraitsValue(profileData.traits)
  const response = {
    authToken: profileData.authToken,
    enrolledForRewards: profileData.enrolledForRewards === true,
    optedOutUser: profileData.optedOutUser,
    causeRewardEligible: profileData.causeRewardEligible,
    profileCompleted: alltraits.profileCompleted,
    pggeUser: true, // not available in new json
    profile: {
      ...alltraits.profile,
      ...alltraits.traits,
      uuid: profileData.ids?.consumerId,
      consumerId: profileData.ids?.consumerId,
      loyaltyId: profileData.loyaltyId,
      emailAddress: profileData.ids?.email,
      phone:
        profileData.ids?.phoneNumber !== ''
          ? profileData.ids?.phoneNumber
          : null, // or profileData.ids?.phoneNumber2
      marketingProgramNbr: profileData.ids?.marketingProgramNumber,
      sourceId: profileData.ids?.sourceId,
      address: alltraits.address,
      optIns: profileData.opts,
    },
  }
  return response
}

/**
 * This function fetchs the points and store it in AimiaStore.totalPointsAvailable
 */
export const fetchPointsBalanceApi = async () => {
  setTimeout(async () => {
    const response = await fetchMemberPoints()
    const apiResponseStatus = response?.status
    const data = await response.json()
    if (apiResponseStatus === 200) {
      AimiaStore.totalPointsAvailable = data.pointsBalance
    } else {
      LoginStore.showErrorModal = true
      throwAIMIAError('fetchPointsBalance', data)
    }
  }, 500)
}

/**
 * This function used to call issue intraction api
 * @param {*} id pass interaction reference id
 * @returns boolean depends on the success of api call
 */
export const issueInteractionApi = async (id) => {
  const response = await issueInteraction(id)
  if (response.status === 200) {
    return true
  }
  LoginStore.showErrorModal = true
  throwAIMIAError('issueInteraction')
  return false
}

/**
 * This function used to call read message api
 * @param {*} id pass message id
 * @returns boolean depends on the success of api call
 */
export const readMessageApi = async (id) => {
  const response = await readMessage(id)
  if (response.status === 200) {
    return true
  }
  LoginStore.showErrorModal = true
  return throwAIMIAError('readMessage')
}

/**
 * This function used to call resend gift card api
 * @param {*} orderReference pass order reference
 * @returns response
 */
export const resendGiftCardApi = async (orderReference) => {
  const response = await reSendGiftCard(orderReference)
  if ([200, 204].includes(response.status)) {
    return response
  }
  LoginStore.showErrorModal = true
  throwAIMIAError('resendGiftCardApi')
  return null
}

/**
 * This function used to call issue reward api
 * @param {*} rewardReference pass reward reference
 * @param {*} quantity pass the rewsrd quantity
 * @param {*} rewardType pass type of reward
 * @returns response
 */
export const issueRewardApi = async (rewardReference, quantity, rewardType) => {
  const response = await issueReward(rewardReference, rewardType, quantity)
  return response
}

/**
 * This function used to call coupon restriction api
 * @param {*} guid pass reward reference
 * @returns response
 */
export const fetchCouponRestrictionApi = async (guid) => {
  const response = await fetchRestrictions(guid)
  const data = await response.json()
  if (response.status === 200) {
    return data
  }
  LoginStore.showErrorModal = true
  return throwAIMIAError('fetchCouponRestrictions', data)
}

/**
 * This function used to call revtrax Registration Api
 */
export const revtraxRegistrationApi = async () => {
  const response = await revtraxUIDRegistration()
  if (response?.status) return response
  LoginStore.showErrorModal = true
  const data = await response.json()
  return throwAIMIAError('fetchCouponRestrictions', data)
}

export const clearSessionCharityPoints = () => {
  sessionStorage.removeItem('charityLevelPoints')
}

/**
 * This function used to check reward eligiblity
 */
export const rewardEligiblityCheck = async (
  rewardName,
  apiCallRequired = true,
) => {
  if (apiCallRequired) AimiaStore.pointsHistory = null
  await AimiaStore.fetchMemberHistoryAction()
  const earnedRewards = await AimiaStore?.earnRewards.filter(
    (item) => item?.typeCode === rewardName,
  )
  return earnedRewards?.length > 0
}

/**
 * This function used to fetch the user profile data
 */
export const profileAPI = async () => {
  const response = await getUserprofile()
  if (response.status === 200) {
    const profileData = await response.json()
    const data = updateToOldProfileData(profileData)
    LoginStore.profileData = data.profile
  }
}

/**
 * This function used to check Reward Transaction Type
 * @param {*} rewardIds pass reward id list
 * @returns boolean
 */
export const handleCheckRewardTransactionType = async (rewardIds = [], callRequired = true) => {
  if (AimiaStore.pointsHistory && callRequired) {
    await AimiaStore.fetchMemberHistoryAction()
  }
  const pointsHistory = AimiaStore?.pointsHistory
  const rewardAvailable = pointsHistory?.filter((item) => rewardIds.includes(item?.typeCode))

  if (rewardAvailable?.length > 0) return false
  return true
}

