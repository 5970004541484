import React from 'react'

const Logo = (active, className) => (
  <svg
    width='185px'
    height='57px'
    viewBox='0 0 131.04 40.96'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <defs>
      <radialGradient id='radial-gradient' cx='-1632.85' cy='39.13' fx='-1632.85' fy='39.13' r='3.23' gradientTransform='translate(29723.57 -707.14) scale(18.2)' gradientUnits='userSpaceOnUse'>
        <stop offset='.02' stopColor='#fff' />
        <stop offset='.03' stopColor='#fbfeff' />
        <stop offset='.09' stopColor='#bae7f9' />
        <stop offset='.14' stopColor='#87d5f4' />
        <stop offset='.19' stopColor='#63c9f1' />
        <stop offset='.23' stopColor='#4cc1ef' />
        <stop offset='.26' stopColor='#44beee' />
        <stop offset='.28' stopColor='#37b8eb' />
        <stop offset='.35' stopColor='#19ace5' />
        <stop offset='.41' stopColor='#07a4e0' />
        <stop offset='.45' stopColor='#00a1df' />
        <stop offset='.78' stopColor='#0045ab' />
        <stop offset='.82' stopColor='#003da6' />
        <stop offset='.96' stopColor='#0059b6' />
      </radialGradient>
      <radialGradient id='radial-gradient-2' cx='-1657.55' cy='19.69' fx='-1657.55' fy='19.69' r='1.08' gradientTransform='translate(54362.31 -633.96) scale(32.79)' gradientUnits='userSpaceOnUse'>
        <stop offset='0' stopColor='#00a1df' />
        <stop offset='.13' stopColor='#0092d7' />
        <stop offset='.37' stopColor='#006cc1' />
        <stop offset='.62' stopColor='#003da6' />
        <stop offset='.73' stopColor='#043799' />
        <stop offset='.93' stopColor='#102777' />
        <stop offset='1' stopColor='#152169' />
      </radialGradient>
      <radialGradient id='radial-gradient-3' cx='-1632.34' cy='39.46' fx='-1632.34' fy='39.46' r='1.31' gradientTransform='translate(29723.57 -700.73) scale(18.2)' gradientUnits='userSpaceOnUse'>
        <stop offset='.32' stopColor='#44beee' stopOpacity='0' />
        <stop offset='.34' stopColor='#41baec' stopOpacity='.04' />
        <stop offset='.49' stopColor='#2a98d9' stopOpacity='.38' />
        <stop offset='.63' stopColor='#187dca' stopOpacity='.65' />
        <stop offset='.75' stopColor='#0b69bf' stopOpacity='.84' />
        <stop offset='.85' stopColor='#035db8' stopOpacity='.96' />
        <stop offset='.92' stopColor='#0059b6' />
      </radialGradient>
    </defs>
    <path className='cls4' fill='url(#radial-gradient)' d='M5.03,20.71C5.03,12.04,10.5,4.66,18.17,1.81h0C8.07,2.2,0,10.51,0,20.71s8.09,18.53,18.2,18.91h0c-7.69-2.84-13.18-10.24-13.18-18.91h.01Z' />
    <path className='cls3' fill='url(#radial-gradient-2)' d='M18.92,1.8c-.25,0-.5,0-.74,.01h0C10.51,4.66,5.04,12.04,5.04,20.71s5.49,16.07,13.18,18.91h0c.24,0,.47,0,.71,0,10.44,0,18.91-8.47,18.91-18.92S29.38,1.79,18.93,1.79h-.01Z' />
    <path className='cls2' fill='url(#radial-gradient-3)' d='M5.03,20.71C5.03,12.04,10.5,4.66,18.17,1.81h0C8.07,2.2,0,10.51,0,20.71s8.09,18.53,18.2,18.91h0c-7.69-2.84-13.18-10.24-13.18-18.91h.01Z' />
    <path className='cls5' fill='#fff' fillRule='evenodd' d='M18.6,18.91c1.25-3.1-1.09-3.94-2.62-3.94h-5.07c.42,.29,.35,.67,.27,.91l-2.95,9.43c-.12,.37-.56,.77-.8,.83h4.1c-.23-.04-.55-.36-.43-.8l1.07-3.41s4.93,.67,6.42-3.02h0Zm-3.98-3.07c.53,0,2.02,.07,1.06,2.88-1.01,2.95-3.23,2.34-3.23,2.34l1.64-5.23h.53Z' />
    <path className='cls5' fill='#fff' fillRule='evenodd' d='M21.87,26.15h2.75c-.88-.28-2.19-1.12-2.7-1.71,.42-.46,.94-1.04,1.03-2.21h-1.43c.17,.11,.27,.29,.28,.49,0,.38-.14,.74-.37,1.04-.42-.62-.87-1.33-1.02-2.37,.56-.28,1.35-.58,1.98-1.13,.59-.52,.73-1.01,.74-1.33v-.04c0-.33-.13-.65-.37-.89-.41-.41-1.07-.58-1.75-.45-.47,.09-.9,.3-1.26,.62-.83,.78-.94,2.07-.94,2.65,0,.14,0,.25,0,.31-.05,.02-.13,.05-.13,.05-.61,.22-2.23,.66-2.81,1.99-.12,.31-.18,.63-.18,.96,0,.41,.1,.8,.31,1.15,.41,.64,1.11,1.05,2.22,1.03,1.21-.02,2.4-.85,2.61-1,.21,.19,.75,.7,1.03,.83h0Zm0-7.45c-.14,.9-1.31,1.81-1.59,1.93-.05-.51-.03-1.3,.4-1.95,.28-.43,.65-.62,.95-.52,.24,.08,.27,.31,.24,.54h0Zm-1.62,6.01c-.16,.1-.8,.43-1.4,.48-.55,.05-1.48-.16-1.48-1.28,0-.95,1.04-1.65,1.59-1.89,.26,.97,.69,1.88,1.29,2.68h0Z' />
    <path className='cls5' fill='#fff' fillRule='evenodd' d='M30.26,15.85c1.64-.39,2.99,.89,3.11,.98l.47-1.49c-.65-.19-5.35-1.79-8.67,2.01-2.04,2.33-2.54,7.12,.05,8.4,2.77,1.37,5.84-.21,6.06-.32l1.27-4.04c.14-.48,.45-.81,.99-1.01h-4.41c.65,.27,.6,.75,.54,.95l-1.26,4.04c-.17,.03-3.71,.92-1.73-5.28,.43-1.33,1.62-3.77,3.58-4.24h0Z' />
    <g>
      <path fill='#fff' className='cls1' d='M86,23.13h-4.86c.05,.5,.3,.96,.69,1.27,.4,.32,.89,.48,1.47,.48,.72,0,1.29-.24,1.73-.73l.6,.7c-.27,.32-.62,.57-1.01,.73-.99,.38-2.08,.32-3.03-.14-.48-.25-.87-.63-1.13-1.09-.27-.48-.41-1.03-.4-1.58,0-.55,.13-1.09,.39-1.57,.25-.46,.62-.83,1.07-1.09,.47-.26,1-.4,1.54-.39,.54,0,1.07,.12,1.53,.39,.44,.26,.81,.64,1.05,1.09,.26,.49,.39,1.05,.38,1.6,0,.11,0,.22-.02,.32h0Zm-4.24-2.01c-.35,.32-.57,.77-.62,1.24h3.84c-.05-.47-.26-.92-.62-1.24-.75-.64-1.85-.64-2.61,0h0Z' />
      <polygon className='cls1' fill='#fff' points='92.71 19.78 90.09 25.76 88.98 25.76 86.36 19.78 87.49 19.78 89.54 24.58 91.64 19.78 92.71 19.78' />
      <path className='cls1' fill='#fff' d='M98.94,23.13h-4.86c.05,.5,.3,.96,.69,1.27,.4,.32,.89,.48,1.47,.48,.71,0,1.29-.24,1.73-.73l.6,.7c-.27,.32-.62,.57-1.01,.73-.99,.38-2.08,.32-3.03-.14-.48-.25-.87-.62-1.13-1.09-.27-.48-.41-1.03-.4-1.58,0-.55,.13-1.09,.39-1.57,.25-.46,.62-.83,1.07-1.09,.47-.26,1-.4,1.54-.39,.54,0,1.07,.12,1.53,.39,.44,.26,.81,.64,1.05,1.09,.26,.49,.39,1.05,.38,1.6,0,.11,0,.22-.02,.32h0Zm-4.24-2.01c-.35,.32-.57,.77-.62,1.24h3.84c-.05-.47-.26-.92-.62-1.24-.75-.64-1.85-.64-2.61,0h0Z' />
      <path className='cls1' fill='#fff' d='M102.34,19.99c.37-.18,.82-.27,1.34-.27v1.05c-.08,0-.17-.01-.25-.01-.59,0-1.05,.18-1.38,.53s-.5,.85-.5,1.5v2.99h-1.08v-5.99h1.04v1.01c.19-.35,.48-.62,.84-.79v-.02Z' />
      <path className='cls1' fill='#fff' d='M110.56,19.78l-2.9,6.59c-.26,.61-.56,1.04-.9,1.29-.36,.25-.8,.39-1.24,.37-.29,0-.58-.05-.85-.14-.26-.08-.49-.22-.68-.41l.46-.81c.28,.28,.67,.44,1.07,.44,.24,0,.47-.07,.66-.21,.18-.14,.34-.38,.48-.72l.19-.42-2.64-5.97h1.13l2.09,4.79,2.07-4.79h1.06Z' />
      <path className='cls1' fill='#fff' d='M117.05,17.38v8.38h-1.04v-.95c-.23,.33-.55,.59-.91,.76-.38,.18-.8,.26-1.22,.26-.54,0-1.08-.12-1.56-.38-.45-.25-.83-.62-1.08-1.08-.27-.49-.4-1.04-.39-1.6,0-.56,.12-1.1,.39-1.59,.25-.45,.63-.82,1.08-1.07,.48-.26,1.01-.39,1.56-.38,.41,0,.81,.08,1.19,.24,.36,.16,.67,.41,.9,.72v-3.3h1.08Zm-2.05,7.23c.31-.17,.56-.43,.73-.75,.35-.69,.35-1.51,0-2.2-.17-.31-.42-.57-.73-.74-.64-.35-1.42-.35-2.06,0-.31,.17-.56,.43-.73,.74-.35,.69-.35,1.51,0,2.2,.17,.31,.42,.57,.73,.75,.64,.35,1.42,.35,2.06,0h0Z' />
      <path className='cls1' fill='#fff' d='M123.19,20.33c.44,.41,.66,1.01,.66,1.82v3.61h-1.03v-.79c-.19,.29-.46,.51-.77,.64-.38,.15-.78,.23-1.19,.22-.67,0-1.21-.16-1.61-.48-.38-.31-.6-.78-.6-1.27s.21-.96,.58-1.28c.38-.32,.99-.48,1.83-.48h1.72v-.21c0-.47-.13-.83-.4-1.07s-.67-.37-1.19-.37c-.35,0-.7,.06-1.04,.18-.31,.1-.6,.26-.86,.47l-.45-.81c.33-.26,.7-.45,1.11-.57,.44-.13,.9-.2,1.37-.2,.83,0,1.46,.2,1.9,.61l-.03-.02Zm-1.08,4.39c.3-.18,.53-.46,.65-.79v-.84h-1.67c-.92,0-1.38,.31-1.38,.93,0,.28,.12,.55,.35,.71,.23,.17,.56,.26,.97,.26,.38,.01,.75-.09,1.08-.28h0Z' />
      <path className='cls1' fill='#fff' d='M131.04,19.78l-2.9,6.59c-.26,.61-.56,1.04-.9,1.29-.36,.25-.8,.39-1.24,.37-.29,0-.58-.05-.85-.14-.26-.08-.49-.22-.68-.41l.46-.81c.28,.28,.67,.44,1.07,.44,.24,0,.47-.07,.66-.21,.18-.14,.34-.38,.48-.72l.19-.42-2.64-5.97h1.13l2.09,4.79,2.07-4.79h1.06Z' />
    </g>
    <path className='cls1' fill='#fff' d='M52.49,26.3c.65,.25,1.39,.2,2.13-.15,1.45-.67,2.83-2.45,3.62-4.64,.56-1.56,.76-3.16,.56-4.51-.04-.31-.11-.63-.21-.93,.09-.04,.17-.09,.24-.16,.5-.5,.99-.73,1.44-.68,.75,.07,1.3,.84,1.31,.86v.02c-.6,.84-1.09,1.76-1.43,2.74-.56,1.56-.76,3.16-.56,4.51,.21,1.5,.9,2.54,1.92,2.93,.27,.1,.56,.16,.86,.15,.44,0,.88-.11,1.28-.3,1.45-.67,2.83-2.45,3.62-4.64,.56-1.56,.76-3.16,.56-4.51-.22-1.5-.9-2.54-1.92-2.93-.66-.25-1.39-.2-2.13,.15-.44,.21-.84,.48-1.19,.81-.34-.39-1.1-1.12-2.15-1.22-.87-.09-1.72,.25-2.52,1-.28-.33-.63-.58-1.03-.74-.65-.25-1.39-.2-2.13,.15-1.45,.67-2.83,2.45-3.62,4.64-.56,1.56-.76,3.16-.56,4.51,.21,1.51,.9,2.55,1.92,2.94h0Zm11.88-10.77c.21-.1,.44-.16,.67-.17,.12,0,.24,.02,.35,.06,1.05,.4,1.51,2.8,.51,5.61-.65,1.81-1.77,3.31-2.87,3.82-.39,.18-.73,.21-1.02,.1-1.05-.4-1.52-2.8-.51-5.61,.65-1.81,1.77-3.31,2.87-3.82h0Zm-11.89,3.83c.65-1.81,1.77-3.31,2.87-3.82,.21-.1,.44-.16,.67-.17,.12,0,.24,.02,.35,.06,1.05,.4,1.51,2.8,.51,5.61-.65,1.81-1.77,3.31-2.87,3.82-.39,.18-.73,.21-1.02,.1-1.05-.4-1.52-2.8-.51-5.61h0Z' />
    <path className='cls1' fill='#fff' d='M87.07,.83c-1.16-.9-2.74-1.08-4.22-.49-1.29,.51-2.47,1.57-3.42,3.07-.98,1.55-1.7,3.55-2.15,5.94-1.02-.02-2.04-.12-3.05-.3-.3-.06-4.39-.89-8.86-1.08-6.34-.27-10.15,.9-11.33,3.47-.16,.36,0,.78,.36,.94s.78,.01,.95-.34c.48-1.04,1.69-1.79,3.6-2.23,1.65-.38,3.79-.52,6.35-.41,4.43,.18,8.62,1.05,8.66,1.05h.02c1.01,.18,2.04,.29,3.07,.32-.25,1.55-.47,2.92-.67,4.11-.28-.36-.66-.64-1.09-.8-.65-.25-1.39-.2-2.13,.15-1.45,.67-2.83,2.45-3.62,4.64-.56,1.56-.76,3.16-.56,4.51,.21,1.5,.9,2.54,1.92,2.93,.65,.25,1.39,.2,2.13-.15,.71-.33,1.41-.92,2.02-1.71,.05,.68,.22,1.33,.61,1.71,.26,.26,.61,.4,.97,.4,.1,0,.21-.01,.31-.03,.38-.08,.62-.46,.55-.84-.07-.38-.44-.64-.82-.57h0c-.06-.09-.22-.44-.18-1.47,.02-.48,.08-.95,.16-1.42v-.02s.07-.43,.22-1.38c.24-.83,.38-1.68,.41-2.54,.29-1.78,.69-4.24,1.22-7.53,1.73-.09,3.3-.46,4.69-1.09,2.05-.93,3.62-2.43,4.41-4.22,.82-1.87,.61-3.73-.53-4.61h0Zm-11.23,17.29c-.16,1.01-.29,1.79-.38,2.38-.05,.17-.11,.35-.17,.52-.65,1.81-1.77,3.31-2.87,3.82-.39,.18-.73,.21-1.02,.1-1.05-.4-1.52-2.8-.51-5.61,.65-1.81,1.77-3.31,2.87-3.82,.21-.1,.44-.16,.67-.17,.12,0,.24,.02,.35,.06,.66,.25,1.08,1.28,1.07,2.71h-.01Zm10.44-13.26c-.37,.85-1.35,2.43-3.68,3.49-1.14,.52-2.43,.83-3.84,.94,.5-2.42,1.74-6.49,4.62-7.62,.39-.16,.8-.24,1.22-.24,.58,0,1.14,.18,1.6,.53,.51,.39,.7,1.51,.09,2.9h-.01Z' />
    <path className='cls1' fill='#fff' d='M47.53,30.13c.56-3.45,1.08-6.59,1.5-9.12,.28-.9,.43-1.83,.46-2.77,.35-2.13,.56-3.4,.57-3.43,.06-.39-.21-.75-.59-.81-.39-.06-.75,.19-.83,.57l-.06,.34c-.28-.36-.66-.64-1.09-.81-.66-.25-1.39-.2-2.13,.15-1.45,.67-2.83,2.45-3.62,4.64-.56,1.56-.76,3.16-.56,4.51,.22,1.5,.9,2.54,1.92,2.93,.66,.25,1.39,.2,2.13-.15,.59-.27,1.17-.73,1.7-1.32-.27,1.63-.56,3.4-.86,5.25-1.88,0-3.55,.34-5,.98-1.91,.85-3.37,2.25-4.13,3.96-.89,2.02-.63,4.18,.61,5.14,.67,.51,1.49,.78,2.33,.77,.49,0,.98-.08,1.44-.24,2.49-.81,4.58-3.53,5.74-7.48,.08-.25,.02-.53-.16-.72-.18-.19-.45-.27-.71-.21-.26,.07-.45,.27-.52,.52-1.45,4.95-3.85,6.2-4.81,6.52-.92,.3-1.81,.19-2.45-.31-.62-.48-.85-1.89-.17-3.42,.61-1.38,1.81-2.53,3.39-3.23,2.3-1.02,4.93-1,7.37-.63,.91,.14,1.81,.35,2.72,.51m-7.12-7.4c-.39,.18-.73,.21-1.02,.11-1.05-.4-1.51-2.8-.51-5.61,.65-1.81,1.77-3.31,2.87-3.82,.21-.1,.44-.16,.67-.17,.12,0,.24,.02,.35,.06,.65,.24,1.07,1.25,1.07,2.64-.13,.76-.27,1.64-.43,2.6-.04,.12-.08,.25-.13,.38-.65,1.81-1.77,3.31-2.87,3.82' />
    <path className='cls1' fill='#fff' d='M52.01,33.3c.31-.23,.36-.7,.14-1.02-.07-.09-.14-.17-.22-.26-.91-1-3.54-1.95-5.1-1.97l.18,1.51c1.42,.2,3.13,.51,3.98,1.61,.23,.31,.71,.36,1.02,.14' />
    <path className='cls1' fill='#fff' d='M58.9,36.01h-1.36v2.35h-2.5v-8.84h4.04c.8,0,1.49,.13,2.08,.4,.56,.24,1.03,.63,1.36,1.14,.32,.49,.48,1.07,.48,1.74,0,.59-.14,1.17-.45,1.67-.31,.49-.76,.88-1.28,1.12l1.91,2.78h-2.68l-1.61-2.35h0Zm1.19-4.19c-.26-.23-.65-.34-1.16-.34h-1.39v2.59h1.39c.51,0,.9-.11,1.16-.33,.26-.22,.39-.54,.39-.95s-.13-.73-.39-.96h0Zm11.39,4.59v1.93h-7.1v-8.84h6.93v1.93h-4.45v1.49h3.93v1.87h-3.93v1.61h4.62Zm15.12-6.89l-2.86,8.84h-2.68l-1.69-5.38-1.77,5.38h-2.68l-2.87-8.84h2.58l1.78,5.65,1.87-5.65h2.3l1.78,5.7,1.86-5.7h2.38Zm6.51,7.13h-3.74l-.69,1.72h-2.55l3.9-8.84h2.46l3.91,8.84h-2.6l-.69-1.72h0Zm-.73-1.84l-1.14-2.83-1.14,2.83h2.28Zm8.6,1.21h-1.36v2.35h-2.5v-8.84h4.04c.8,0,1.49,.13,2.08,.4,.56,.24,1.03,.63,1.36,1.14,.32,.49,.48,1.07,.48,1.74,0,.59-.14,1.17-.45,1.67-.31,.49-.76,.88-1.28,1.12l1.91,2.78h-2.68l-1.6-2.35h0Zm1.19-4.19c-.26-.23-.65-.34-1.16-.34h-1.39v2.59h1.39c.51,0,.9-.11,1.16-.33s.39-.54,.39-.95-.13-.73-.39-.96h0Zm4.29-2.32h4.18c.97,0,1.83,.18,2.57,.54,.72,.33,1.32,.87,1.74,1.54,.41,.67,.62,1.44,.62,2.34s-.21,1.67-.62,2.34c-.42,.67-1.02,1.21-1.74,1.54-.75,.36-1.61,.54-2.57,.54h-4.18v-8.84Zm4.08,6.85c.76,0,1.37-.22,1.82-.65,.46-.43,.69-1.02,.69-1.78s-.23-1.35-.69-1.78-1.07-.64-1.82-.65h-1.58v4.85h1.58Zm7.37,1.92c-.67-.18-1.22-.41-1.64-.71l.82-1.84c.87,.56,1.88,.86,2.92,.87,.96,0,1.44-.24,1.44-.72,0-.25-.14-.44-.41-.56-.27-.12-.71-.25-1.32-.38-.57-.12-1.12-.27-1.67-.46-.44-.16-.84-.43-1.15-.79-.32-.36-.48-.85-.48-1.47,0-.52,.15-1.03,.44-1.46,.29-.43,.73-.78,1.32-1.03,.59-.25,1.3-.38,2.15-.38,.58,0,1.16,.06,1.72,.2,.52,.11,1.03,.31,1.49,.57l-.77,1.86c-.84-.46-1.66-.68-2.45-.68-.5,0-.86,.07-1.09,.22s-.34,.34-.34,.57,.13,.41,.4,.53,.7,.24,1.3,.37c.57,.11,1.13,.27,1.67,.46,.44,.16,.84,.43,1.15,.78,.32,.36,.48,.84,.48,1.46,0,.51-.15,1.02-.44,1.44-.29,.43-.74,.77-1.33,1.03s-1.3,.38-2.15,.39c-.7,0-1.4-.09-2.08-.26h.02Z' />
  </svg>
)
export default Logo
