import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import LoginStore from '../UserManagement/Login-store'
import Typography from '../Typography/Typography'
import richtextstyles from './RichText.styles'
import twStyles from './RichText.tw-styles'
import { articlePAgeEvent } from '../../helpers/gaEvent.helper'
import { oktaAuthentication } from '../../helpers/login.helper'
import { oktaSignup } from '../../constants/texts'
import { storageAvailable } from '../../adapters/serverside.adapters'

const handler = (event) => {
  articlePAgeEvent(event.target.href)
}

const handleRedirection = (pageSource, href) => {
  if (!storageAvailable('sessionStorage')) {
    LoginStore.showCookiesDisabledodal = true
    return
  }
  if (pageSource === 'couponsDigital' && href === '/signup/') {
    LoginStore.sendOTPPendingWithRegistration = true
    localStorage.setItem('sendOTPPendingWithRegistration', true)
    const exactPath = window?.location?.href
    oktaAuthentication(oktaSignup, exactPath)
  }
}

const RichText = ({
  body, pageSource, variant, className, ...other
}) => {
  const pageStyle = twStyles[pageSource] || twStyles.default
  const style = pageStyle[variant] || pageStyle

  return (
    <>
      <Typography
        className={`${style || ''} ${className || ''}`}
        markdown={body}
        renderers={{
          link: ({ href, title, children }) => {
            let target
            if (/^http/.test(href)) target = '_blank'
            return (
              <Link
                href={(href === '/signup/' && pageSource === 'couponsDigital') ? '#' : href}
                passHref
                onClick={(event) => {
                  if (pageSource === 'articleDetail') handler(event)
                  else handleRedirection(pageSource, href)
                }}
                target={target}
                rel={target === '_blank' && 'noopener'}
                aria-label={(children && children[0]?.props?.value) || href}
                title={title}
                prefetch={false}
              >
                {children}
              </Link>
            )
          },
        }}
        {...other}
      />
      <style jsx global>
        {richtextstyles}
      </style>
    </>
  )
}

RichText.propTypes = {
  body: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node,
  pageSource: PropTypes.string,
  variant: PropTypes.string,
}

RichText.defaultProps = {
  body: '',
  className: '',
  href: null,
  children: null,
  pageSource: '',
  variant: '',
}

export default RichText
