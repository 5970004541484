import React from 'react'

export default (active, className) => (
  <svg width='119px' height='84px' viewBox='0 0 119 84' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' className={className}>
    <g id='heartSparksPage-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='PGGE-Account-empty-Tablet' transform='translate(-325.000000, -631.000000)'>
        <g id='My-Favorite-Brands' transform='translate(0.000000, 526.000000)'>
          <g id='Sparks' transform='translate(326.000000, 106.000000)'>
            <path d='M98,69.6 C103.246705,69.6 107.5,65.3467051 107.5,60.1 C107.5,65.3467051 111.753295,69.6 117,69.6' id='heartSparksPath1' stroke='#C7008E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M98,69.6 C103.246705,69.6 107.5,73.8532949 107.5,79.1 C107.5,73.8532949 111.753295,69.6 117,69.6' id='heartSparksPath2' stroke='#C7008E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M0,25.6 C5.24670512,25.6 9.5,21.3467051 9.5,16.1 C9.5,21.3467051 13.7532949,25.6 19,25.6' id='heartSparksPath3' stroke='#C7008E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M0,25.6 C5.24670512,25.6 9.5,29.8532949 9.5,35.1 C9.5,29.8532949 13.7532949,25.6 19,25.6' id='heartSparksPath4' stroke='#C7008E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M33.9,77.4 L41.5,77.4 M37.7,73.6 L37.7,81.2' id='heartSparksCombined-Shape1' stroke='#C7008E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M88.5,3.8 L96.1,3.8 M92.3,0 L92.3,7.6' id='heartSparksCombined-Shape2' stroke='#C7008E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            <g id='heart' transform='translate(28.000000, 23.000000)' fill='#C7008E'>
              <path d='M30,9.10609413 C31.464433,6.54471261 33.0149193,4.51887052 34.8681196,3.0256183 C37.3723204,1.00875317 40.3393757,0 44.2573451,0 C48.6029075,0 52.5386985,1.77769342 55.3869851,4.64762569 C58.2357808,7.51704499 60,11.4815245 60,15.8593286 C60,26.2484859 53.3139416,31.3779176 44.4327613,38.1875786 C40.303987,41.3565717 35.6735323,44.9052899 31.0512246,49.5645857 C30.4718927,50.1451381 29.5306533,50.1451381 28.9513214,49.5645857 C24.3268496,44.9058029 19.6988136,41.3536221 15.5672387,38.184629 C6.6892408,31.3772764 0,26.2420738 0,15.8589438 C0,11.4842176 1.76421916,7.5161473 4.61607006,4.646728 C7.4609196,1.7773087 11.3967106,0 15.7421457,0 C19.6599878,0 22.6271704,1.00836844 25.1313713,3.02523358 C26.9846988,4.52156361 28.5350578,6.54689272 29.9994908,9.10570941 L30,9.10609413 Z' id='Fill-1-heartSparks' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
