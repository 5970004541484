import React from 'react'
import crypto from 'crypto'

export default (active, className) => {
  const randomID = crypto.randomBytes(12).toString('hex')
  return (
    <svg
      width='79px'
      height='79px'
      viewBox='0 0 79 79'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      className={className}
    >
      <defs>
        <polygon
          id={`path-${randomID}`}
          points='0 0.000219444444 79 0.000219444444 79 79 0 79'
        />
        <linearGradient
          x1='20.0120702%'
          y1='0%'
          x2='119.19468%'
          y2='122.64688%'
          id={`linearGradient-${randomID}`}
        >
          <stop stopColor='#41B243' offset='0%' />
          <stop stopColor='#75C145' offset='100%' />
        </linearGradient>
      </defs>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g transform='translate(-676.000000, -343.000000)'>
          <g
            transform='translate(528.000000, 207.000000)'
          >
            <g transform='translate(148.000000, 136.000000)'>
              <g id='Group-3'>
                <mask id={`mask-${randomID}`} fill='white'>
                  <use xlinkHref={`#path-${randomID}`} />
                </mask>
                <g />
                <path
                  d='M39.5,0.000219444444 C61.3149722,0.000219444444 79,17.6852472 79,39.5002194 C79,61.3151917 61.3149722,79.0002194 39.5,79.0002194 C17.6850278,79.0002194 0,61.3151917 0,39.5002194 C0,17.6852472 17.6850278,0.000219444444 39.5,0.000219444444'
                  fill={`url(#linearGradient-${randomID})`}
                  mask={`url(#mask-${randomID})`}
                />
              </g>
              <path
                d='M26.9328556,60.5446125 L26.9328556,57.7850986 C21.2426611,51.1864042 18.3975639,47.6522514 18.3975639,47.1859319 L18.3975639,32.4919319 C18.6466333,30.8581681 19.5353833,30.0418347 21.0638139,30.0418347 C22.5922444,30.0418347 23.3570083,30.8581681 23.3570083,32.4919319 L23.3570083,40.8220431'
                stroke='#FFFFFF'
                strokeWidth='2.16481944'
                strokeLinecap='round'
              />
              <path
                d='M52.0671444,60.5446125 L52.0671444,57.7850986 C57.7573389,51.1864042 60.6024361,47.6522514 60.6024361,47.1859319 L60.6024361,32.4919319 C60.3533667,30.8581681 59.4646167,30.0418347 57.9361861,30.0418347 C56.4077556,30.0418347 55.6429917,30.8581681 55.6429917,32.4919319 L55.6429917,40.8220431'
                stroke='#FFFFFF'
                strokeWidth='2.16481944'
                strokeLinecap='round'
              />
              <path
                d='M43.3483972,61.2861153 L43.3483972,52.4490875 C43.3483972,50.8273931 43.6973139,49.3746708 44.8142861,48.3180458 C45.5593,47.6147264 48.0488972,45.2161986 52.2830778,41.1246569 C53.6030361,40.6078653 54.6388139,40.7548931 55.3904111,41.5635458 C56.1431056,42.3732958 56.1431056,43.4332125 55.3904111,44.7454903 L49.3699528,50.8625042'
                stroke='#FFFFFF'
                strokeWidth='2.16481944'
                strokeLinecap='round'
              />
              <path
                d='M35.6511639,61.2861153 L35.6511639,52.4490875 C35.6511639,50.8273931 35.3022472,49.3746708 34.185275,48.3180458 C33.4413583,47.6147264 30.9517611,45.2161986 26.7164833,41.1246569 C25.3976222,40.6078653 24.3618444,40.7548931 23.60915,41.5635458 C22.8575528,42.3732958 22.8575528,43.4332125 23.60915,44.7454903 L29.6307056,50.8625042'
                stroke='#FFFFFF'
                strokeWidth='2.16481944'
                strokeLinecap='round'
              />
              <path
                d='M52.6490014,28.4932153 C52.2474181,25.8072153 50.6388903,23.8826875 47.9869042,23.0246597 C45.3744181,22.1787014 43.2534875,23.5260903 41.3739458,25.4122153 C41.1281681,25.6601875 40.3864458,26.4743264 40.1143347,26.7914236 C40.0133903,26.9088264 39.8290569,26.9110208 39.7281125,26.7936181 C39.3780986,26.3942292 38.4443625,25.3617431 38.1821264,25.0984097 C36.3025847,23.2122847 34.4702236,22.1787014 31.8566403,23.0246597 C29.2046542,23.8826875 27.5961264,25.8072153 27.1956403,28.4932153 C26.8971958,30.4879653 27.5039597,32.3686042 28.6055708,34.0802708 C29.6742653,35.7392708 31.0523764,37.1415208 32.4941264,38.4834236 C34.3231958,40.1863125 38.0032792,43.2190347 39.4110153,44.4468264 C39.7610292,44.7529514 40.2920847,44.7474653 40.6366125,44.4347569 C41.9960708,43.2003819 45.5225431,40.1841181 47.3494181,38.4834236 C48.7911681,37.1415208 50.1703764,35.7392708 51.2379736,34.0802708 C52.3406819,32.3686042 52.9463486,30.4879653 52.6490014,28.4932153 Z'
                stroke='#FFFFFF'
                strokeWidth='2.16481944'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
