import {
  triggerLogoutEvent,
  triggerProfileLogoutSubmitErrorEvent,
} from '../../helpers/gaEvent.helper'
import LoginStore from '../../components/UserManagement/Login-store'
import staticRoutes from '../../../config/router.config'
import { logout } from '../../adapters/serverside.adapters'
import telemetry from '../../adapters/telemetry.adapter'

const allowedAge = 18

export const baseUrl = process.env.OKTA_ENVIRONMENT === 'production'
  ? 'https://api.pgsvc.com'
  : 'https://api-nonprod.pgsvc.com'

export const loggedInStatus = () => {
  if (typeof localStorage === 'undefined') return false
  const status = localStorage.getItem('janrainCaptureToken') && LoginStore.profileData
  return status
}

export const maskEmail = (input) => input.replace(
  /^(.)(.*)(.@.*\.)(.*)$/,
  (_, a, b, c, d) => a + b.replace(/./g, '*') + c + d.replace(/./g, '*'),
)

export const throwAIMIAError = (api, errorCode) => {
  telemetry.trackEvent(`aimiaerror${api}`, errorCode)
  // throw new Error(
  //   `${api} AIMIA API error code:${errorCode}
  // email:${maskEmail(LoginStore.profileData.emailAddress)},
  // consumerId:${LoginStore.profileData.consumerId
  //   || LoginStore.profileData.uuid}`,
  // )
}

export const throwRevtraxError = (apiName, errorCode) => {
  telemetry.trackEvent(apiName, errorCode)
}

export const loggedInForgotPasswordStatus = () => {
  if (typeof localStorage === 'undefined') return false
  const status = localStorage.getItem('janrainCaptureToken')
    && localStorage.getItem('janrainCaptureToken').length > 6
    && LoginStore.profileData
  return status
}

export const getSearchQueryParameter = (name) => {
  const searchParamsObject = new URLSearchParams(window.location.search)
  return searchParamsObject.has(name) ? searchParamsObject.get(name) : undefined
}

export const isLoggedIn = () => {
  try {
    return LoginStore.profileData
  } catch (err) {
    return false
  }
}

const provinceArr = [
  'Alabama',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'Washington D.C',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
]

export const provinceNames = () => provinceArr
  && provinceArr.map((el, i) => ({
    id: i,
    value: el,
    innerText: el,
  }))
export const securedPages = () => {
  const {
    myAccount,
    rewardsOptout,
    pointsDetails,
    manageCoupons,
    accountSetupPage,
  } = staticRoutes

  const pageArr = [
    myAccount,
    rewardsOptout,
    pointsDetails,
    manageCoupons,
    accountSetupPage,
  ]
  return pageArr
}

export const oktaHandleGoToHomePage = () => {
  window.location = '/'
  return ''
}

let oktaLogouted = false
export const oktaLogout = () => {
  if (oktaLogouted) return
  oktaLogouted = true
  window.location.href = `https://${process.env.AUTH0_DOMAIN}/v2/logout?client_id=${process.env.AUTH0_CLIENT_ID}&returnTo=${process.env.SITE_DOMAIN}/`
}

export const sessionClearing = async (
  callGAEvent = false,
  apiCallRequired = true,
  timeout = 3000,
) => {
  const logged = localStorage.getItem('loggedIn')
  if (!logged) return
  sessionStorage.removeItem('digital-coupons')
  localStorage.removeItem('helloAlertShown')
  sessionStorage.removeItem('charityLevelPoints')
  localStorage.removeItem('failedPasswordChangeCount')
  sessionStorage.removeItem('oktaCaptureProfileData')
  sessionStorage.removeItem('unreadMessageCount')
  sessionStorage.removeItem('couponsCollection')
  sessionStorage.removeItem('firstCoupon')
  sessionStorage.removeItem('campaignSource')
  sessionStorage.removeItem('promoCodeTab')
  sessionStorage.removeItem('brandName')
  sessionStorage.removeItem('memberHistory')
  sessionStorage.removeItem('completeProfileOverlay')
  localStorage.removeItem('pageVisitCount')
  sessionStorage.removeItem('showCompleteProfile')
  sessionStorage.removeItem('uidRegistered')
  sessionStorage.removeItem('selectedRebate')
  sessionStorage.removeItem('couponIdAddToCart1')
  sessionStorage.removeItem('couponIdAddToCart2')
  sessionStorage.removeItem('OfferExpired')
  sessionStorage.removeItem('takeASurvey')
  sessionStorage.removeItem('receiptScan')
  sessionStorage.removeItem('RUBSuccess')
  sessionStorage.removeItem('learnAboutCause')
  // sessionStorage.removeItem('fetchMemberInteraction')
  sessionStorage.removeItem('assignedRewards')
  sessionStorage.removeItem('interactedToTaskTracker')
  sessionStorage.removeItem('taskTrackerAnimationShown')
  sessionStorage.removeItem('redeemRewards')
  sessionStorage.removeItem('HideCauseSelectionForPGGEuserWithSurvey')
  sessionStorage.removeItem('sampleSignUp')
  localStorage.removeItem('basketItems')
  localStorage.removeItem('digitalbasketItems')
  sessionStorage.removeItem('SampleOrdered')
  localStorage.removeItem('usedAddedCoupon')
  localStorage.removeItem('usedAddedDigitalCoupon')
  localStorage.removeItem('loggedIn')
  localStorage.removeItem('allTasksCompleted')
  localStorage.removeItem('welcomeOverlayShown')
  localStorage.removeItem('allTaskCompleted')
  localStorage.removeItem('WelcomeOverlayDisplayed')
  localStorage.removeItem('loginCount')
  localStorage.removeItem('pageVisitCountForOnboarding')
  sessionStorage.removeItem('oktaCaptureToken_Expires')
  localStorage.removeItem('oktaCaptureToken_Expires')
  localStorage.removeItem('disableCompleteProfileOverlay')
  localStorage.removeItem('sendOTPPendingWithRegistration')
  localStorage.removeItem('articlePendingUrl')
  localStorage.removeItem('sitemapBackUrl')
  localStorage.removeItem('redirectionUrlAfterSimplifiedSignup')
  localStorage.removeItem('simplifiedSignupFlowWithCompleteProfileSurvey')
  localStorage.removeItem('retailers')
  localStorage.removeItem('sessionContinued')
  localStorage.removeItem('user-action')
  localStorage.removeItem('redirect-path')
  localStorage.removeItem('open-surveyId')
  localStorage.removeItem('showSurveyAfterCampaignSignup')
  localStorage.removeItem('redirectionUrlAfterSkipping2Screen')
  localStorage.removeItem('localGuid')
  if (apiCallRequired) await logout()
  localStorage.removeItem('JWTtoken')
  setTimeout(() => {
    oktaLogout()
  }, timeout)
  localStorage.removeItem('sessionContinued')
  if (callGAEvent) {
    triggerLogoutEvent()
    triggerProfileLogoutSubmitErrorEvent()
  }
  localStorage.removeItem('consumer_id')
  localStorage.removeItem('blueBarSignup')
}

export const oktaHandleRedirection = (
  redirectTo = '/',
  securedRedirect = false,
) => {
  if (
    securedRedirect
    && (securedPages().indexOf(redirectTo) >= 0
      || securedPages().includes(redirectTo.split('?')[0]))
  ) {
    window.location = '/'
  } else {
    window.location = redirectTo
  }
}

export const genderArr = ['Female', 'Male', 'Other', 'Rather not say']

export const genders = genderArr.map((el, i) => ({
  id: i,
  value: el.charAt(0),
  name: el,
  innerText: el,
}))

const monthsArr = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
]
export const monthNames = monthsArr.map((el, i) => ({
  id: i,
  value: el,
  innerText: el,
}))

export const years = (scope = 100) => {
  const date = new Date()
  const currentYear = date.getFullYear() - allowedAge
  return Array.from({ length: scope }, (el, i) => ({
    id: i,
    value: currentYear - i,
    innerText: currentYear - i,
  }))
}

export const formatDate = (data) => {
  const arr = data.replace(' ', '-').split('-')
  return `${arr[1]}/${arr[2]}/${arr[0]}`
}

export const formatDateInMMDDYY = (data) => {
  const arr = data?.split('-')
  return `${arr[1]}/${arr[2].slice(0, 2)}/${arr[0].slice(2, 4)}`
}

export const verifyDOB = (
  year,
  month,
  mimimumAge = allowedAge,
  nullCheck = true,
) => {
  if (nullCheck) {
    if (year === undefined || month === undefined) return false
  }
  const date = new Date()
  const ageYearDiffernce = date.getFullYear() - mimimumAge
  if (ageYearDiffernce === year) {
    return parseInt(month, 10) <= date.getMonth() + 1
  }
  return ageYearDiffernce > year
}

export const hasExceededFailedUpdateAttempts = () => {
  if (localStorage && localStorage.getItem('failedPasswordChangeCount')) {
    let failedPasswordCount = JSON.parse(
      localStorage.getItem('failedPasswordChangeCount'),
    )
    if (failedPasswordCount >= 3) {
      localStorage.removeItem('failedPasswordChangeCount')
      return false
    }
    failedPasswordCount += 1
    localStorage.setItem(
      'failedPasswordChangeCount',
      JSON.stringify(failedPasswordCount),
    )
    return true
  }
  if (localStorage && !localStorage.getItem('failedPasswordChangeCount')) {
    const failedPasswordCount = 1
    localStorage.setItem(
      'failedPasswordChangeCount',
      JSON.stringify(failedPasswordCount),
    )
    return true
  }
  return true
}
