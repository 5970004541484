/* eslint-disable import/no-cycle */
import telemetry from '../../adapters/telemetry.adapter'
import { errorEventMessage } from '../../constants/texts'
import LoginStore from '../../components/UserManagement/Login-store'
import { backend } from '../../constants/urls'
import {
  STATUS_0000, STATUS_1003, STATUS_1010, STATUS_1011, STATUS_1015, STATUS_1052,
  STATUS_1060, STATUS_1068, STATUS_1071, STATUS_1186, STATUS_1187, STATUS_1188,
  STATUS_1189, STATUS_1191, STATUS_1275, STATUS_1276,
} from '../../constants/status-code'

const standardEventLog = require('../../constants/standard-event-log.json')

const standardEvent = (errorCode, headers, payload, response, responseData, error) => {
  const eventError = error ? { error } : {}
  const eventPayload = payload ? { payload } : {}
  telemetry.logCustomEvent(errorCode, {
    headers,
    api: response.url,
    response: responseData,
    ...eventPayload,
    ...eventError,
  })
  return errorCode
}

const standardEventForSuccess = (errorCode, headers, payload, response) => {
  if (response.url.includes(backend.favoriteBrand)) return 0

  if (errorCode === STATUS_1003) {
    return standardEvent(errorCode, headers, payload, response)
  }
  telemetry.logCustomEvent(errorCode, { api: response.url })
  return errorCode
}

export function getCodeByRequestType(requestType, message) {
  if (requestType.includes(backend.favoriteBrand)) {
    requestType = (/.*\d+.*/.test(requestType)) ? `${backend.favoriteBrand}/` : backend.favoriteBrand
  }

  const errorCode = Object.keys(standardEventLog).filter(
    (key) => standardEventLog[key].requestType !== ''
      && requestType.split('?')[0] === standardEventLog[key].requestType
      && standardEventLog[key].message === message,
  )[0]
  return errorCode || STATUS_0000
}

export function getCodeByRequestTypeForSuccess(requestType) {
  const errorCode = Object.keys(standardEventLog).filter(
    (key) => standardEventLog[key].requestType !== ''
    && requestType.split('?')[0] === standardEventLog[key].requestType
    && standardEventLog[key].status === 'success',
  )[0]
  return errorCode || STATUS_0000
}

const RESPONSE_STATUS = [204, 400, 404, 401, 403, 422, 429, 500, 502, 503]
export const standardEventForCommonError = async (
  requestType,
  headers,
  payload,
  response,
) => {
  try {
    if (requestType === backend.loginUrl) {
      const profileData = await response?.clone().json()
      localStorage.setItem('consumer_id', profileData?.ids?.consumerId)
    }

    if (payload?.newPassword) {
      payload.newPassword = '********'
    }
    if (payload?.phoneNumber) {
      payload.phoneNumber = '********'
    }

    let errorCode = STATUS_0000
    if (response.status === 200) {
      LoginStore.errorModalCode = null
      errorCode = standardEventForSuccess(
        getCodeByRequestTypeForSuccess(requestType),
        headers,
        payload,
        response,
      )
      return errorCode
    }

    if (requestType.includes('udc/')) {
      const responseData = await response.clone().json()
      errorCode = getCodeByRequestType(requestType, responseData.message)
      LoginStore.errorModalCode = errorCode
      return standardEvent(errorCode, headers, payload, response, responseData)
    }

    if (RESPONSE_STATUS.includes(response.status)) {
      const responseData = await response.clone().json()

      if (responseData?.internalError?.message?.toLowerCase().includes('Member Not Found'.toLowerCase())) {
        standardEvent(STATUS_1015, headers, payload, response, responseData)
        LoginStore.setErrorModalCode(STATUS_1015)
        return errorCode
      }

      switch (responseData.message) {
        case errorEventMessage.badRequest:
          errorCode = getCodeByRequestType(requestType, errorEventMessage.badRequest)
          break
        case errorEventMessage.invalidJWT:
          errorCode = getCodeByRequestType(requestType, errorEventMessage.invalidJWT)
          break
        case errorEventMessage.resourceNotFound:
          errorCode = getCodeByRequestType(requestType, errorEventMessage.resourceNotFound)
          break
        case errorEventMessage.invalidsubscriptionKey:
          errorCode = getCodeByRequestType(requestType, errorEventMessage.invalidsubscriptionKey)
          break
        case errorEventMessage.forbidden:
          errorCode = getCodeByRequestType(requestType, errorEventMessage.forbidden)
          break
        case errorEventMessage.unprocessableEntity:
          errorCode = getCodeByRequestType(requestType, errorEventMessage.unprocessableEntity)
          break
        case errorEventMessage.unauthorizedAccess:
          errorCode = getCodeByRequestType(requestType, errorEventMessage.unauthorizedAccess)
          break
        case errorEventMessage.serviceNotAvailable:
          errorCode = getCodeByRequestType(requestType, errorEventMessage.serviceNotAvailable)
          break
        case errorEventMessage.internalServerError:
          errorCode = STATUS_1275
          break
        case errorEventMessage.badGateway:
          errorCode = STATUS_1276
          break
        default:
          errorCode = STATUS_0000
          break
      }

      if (responseData.message.toLowerCase().includes(errorEventMessage.rateLimit)) {
        errorCode = getCodeByRequestType(requestType, errorEventMessage.rateLimitExceeded)
      }

      LoginStore.setErrorModalCode(errorCode)

      if (requestType === backend.issueReward && response.status === 422) return errorCode

      errorCode = standardEvent(errorCode, headers, payload, response, responseData)
      return errorCode
    }

    return errorCode
  } catch (error) {
    return standardEvent(STATUS_0000, headers, payload, response, null, error)
  }
}

export const standardEventForPrintCoupon = (errorCode, coupons, error) => {
  telemetry.logCustomEvent(errorCode, { coupons, error })
}

export const standardEventForCouponsAddtoBasket = (errorCode, coupons) => {
  telemetry.logCustomEvent(errorCode, { coupons })
}

export const standardEventForInvalidProgramId = (errorCode) => {
  const program_id = localStorage.getItem('content_id')
  localStorage.removeItem('content_id')
  const campaignPageUrl = LoginStore.traitCampaignName
  telemetry.logCustomEvent(errorCode, {
    program_id,
    campaignPageUrl,
  })
  LoginStore.setErrorModalCode(errorCode)
}

export const standardEventForOkta = (errorCode) => {
  const exactPath = localStorage.getItem('exactPath')

  if (errorCode === STATUS_1010 || errorCode === STATUS_1071) {
    const auth0Code = localStorage.getItem('auth0Code')
    telemetry.logCustomEvent(errorCode, { code: auth0Code, urlpath: exactPath })
  }

  if (errorCode === STATUS_1011) {
    const auth0State = localStorage.getItem('auth0State')
    telemetry.logCustomEvent(errorCode, { state: auth0State, url: exactPath })
  }

  LoginStore.setErrorModalCode(errorCode)
}

export const standardEventForIssueReward = async (
  rewardType,
  payload,
  response,
) => {
  const responseData = await response.clone().json()

  if (response.status === 422 && responseData?.internalError?.errorCode === 'e2417') {
    standardEvent(STATUS_1191, null, payload, response, responseData)
    LoginStore.setErrorModalCode(STATUS_1191)
    return
  }

  if (response.status === 422 && responseData?.internalError?.errorCode === 'e2412') {
    standardEvent(STATUS_1189, null, payload, response, responseData)
    LoginStore.setErrorModalCode(STATUS_1189)
    return
  }

  if (rewardType === 'Gift_Card') {
    standardEvent(STATUS_1186, null, payload, response, responseData)
    LoginStore.setErrorModalCode(STATUS_1186)
  }
  if (rewardType === 'Sweeps') {
    standardEvent(STATUS_1187, null, payload, response, responseData)
    LoginStore.setErrorModalCode(STATUS_1187)
  }
  if (rewardType === 'Charity_Donation') {
    standardEvent(STATUS_1188, null, payload, response, responseData)
    LoginStore.setErrorModalCode(STATUS_1188)
  }
}

export const standardEventForIneligibilityPopup = (errorCode, details) => {
  telemetry.logCustomEvent(errorCode, details)
}

export const standardEventForAsset = (errorCode, assetDetails) => {
  telemetry.logCustomEvent(errorCode, assetDetails)
}

export const standardEventForSurveyStart = (errorCode, surveyDetails) => {
  telemetry.logCustomEvent(errorCode, surveyDetails)
}

let brandsLoaded = false
export const standardEventForFavoriteBrand = (errorCode, brand, response) => {
  if (errorCode === STATUS_1060 && !brandsLoaded) {
    telemetry.logCustomEvent(errorCode, {})
    brandsLoaded = true
    return
  }
  if (errorCode === STATUS_1052 || errorCode === STATUS_1068) {
    telemetry.logCustomEvent(errorCode, { brand, api: response.url })
  }
}

export const standardEventForCampaignLoad = (errorCode, iFrameUrl) => {
  telemetry.logCustomEvent(errorCode, { iFrameUrl })
}

export const standardEventForSearchLoad = (errorCode) => {
  telemetry.logCustomEvent(errorCode, {})
}

export const standardEventForHelpLoad = (errorCode) => {
  telemetry.logCustomEvent(errorCode, {})
}

export const standardEventForRebateLoad = (errorCode, iFrameUrl) => {
  telemetry.logCustomEvent(errorCode, { iFrameUrl })
}

export const standardEventForReceiptUpload = (errorCode, response) => {
  LoginStore.errorModalCode = errorCode
  telemetry.logCustomEvent(errorCode, { api: response.url })
}

export const standardEventForHistoryLoad = (errorCode, response) => {
  telemetry.logCustomEvent(errorCode, { api: response.url })
}

export const standardEventForChatBotLoad = (errorCode, pageUrl) => {
  telemetry.logCustomEvent(errorCode, { url: pageUrl })
}

export default standardEvent
