import React from 'react'

export default (active, className) => (
  <svg
    width='17px'
    height='15px'
    viewBox='0 0 17 15'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g
        transform='translate(-3219.000000, -384.000000)'
        strokeWidth='1.26'
      >
        <g transform='translate(1833.000000, 208.000000)'>
          <g
            transform='translate(1082.000000, 164.000000)'
          >
            <g transform='translate(304.000000, 12.000000)'>
              <path
                d='M0.63,4.7549 C2.908,4.7549 4.755,2.9079 4.755,0.6299 C4.755,2.9079 6.602,4.7549 8.88,4.7549'
                stroke='#FF6F61'
              />
              <path
                d='M0.63,4.7549 C2.908,4.7549 4.755,6.6019 4.755,8.8799 C4.755,6.6019 6.602,4.7549 8.88,4.7549'
                stroke='#FF6F61'
              />
              <line
                x1='9.4615'
                y1='12.6533'
                x2='12.7615'
                y2='12.6533'
                stroke='#C7008E'
              />
              <line
                x1='11.1114'
                y1='11.0039'
                x2='11.1114'
                y2='14.3039'
                stroke='#C7008E'
              />
              <line
                x1='13.005'
                y1='3.1054'
                x2='16.305'
                y2='3.1054'
                stroke='#2BAD9A'
              />
              <line
                x1='14.6549'
                y1='1.4551'
                x2='14.6549'
                y2='4.7551'
                stroke='#2BAD9A'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
