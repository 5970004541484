import React from 'react'

export default (active, className) => (
  <svg
    width='22px'
    height='22px'
    viewBox='0 0 22 22'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >

    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-484.000000, -329.000000)'>
        <g transform='translate(190.000000, 162.000000)'>
          <g transform='translate(295.000000, 168.000000)'>
            <circle stroke='#2BAD9A' strokeWidth='1.76' fill='#2BAD9A' cx='8.8' cy='8.8' r='8.8' />
            <path d='M9.41536232,3.96 C9.52101679,3.96 9.60666667,4.04564987 9.60666667,4.15130435 L9.606,7.993 L13.4486957,7.99333333 C13.5543501,7.99333333 13.64,8.07898321 13.64,8.18463768 L13.64,9.41536232 C13.64,9.52101679 13.5543501,9.60666667 13.4486957,9.60666667 L9.606,9.606 L9.60666667,13.4486957 C9.60666667,13.5543501 9.52101679,13.64 9.41536232,13.64 L8.18463768,13.64 C8.07898321,13.64 7.99333333,13.5543501 7.99333333,13.4486957 L7.993,9.606 L4.15130435,9.60666667 C4.04564987,9.60666667 3.96,9.52101679 3.96,9.41536232 L3.96,8.18463768 C3.96,8.07898321 4.04564987,7.99333333 4.15130435,7.99333333 L7.993,7.993 L7.99333333,4.15130435 C7.99333333,4.04564987 8.07898321,3.96 8.18463768,3.96 L9.41536232,3.96 Z' fill='#FFFFFF' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
