import React from 'react'

export default (active, className) => (
  <svg width='22px' height='22px' viewBox='0 0 22 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' className={className}>
    <g id='plusPage-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='PGGE-Account-popup-Desktop-plus' transform='translate(-817.000000, -1074.000000)' fill='#A400A6'>
        <g id='My-Fav-Brands-plus' transform='translate(16.000000, 649.000000)'>
          <g id='Group-2-plus' transform='translate(585.000000, 424.000000)'>
            <g id='plusIcons-/-Plus' transform='translate(216.000000, 1.000000)'>
              <path d='M12.3985507,0 C12.6386745,-3.12893959e-16 12.8333333,0.194658804 12.8333333,0.434782609 L12.833,9.166 L21.5652174,9.16666667 C21.8053412,9.16666667 22,9.36132547 22,9.60144928 L22,12.3985507 C22,12.6386745 21.8053412,12.8333333 21.5652174,12.8333333 L12.833,12.833 L12.8333333,21.5652174 C12.8333333,21.8053412 12.6386745,22 12.3985507,22 L9.60144928,22 C9.36132547,22 9.16666667,21.8053412 9.16666667,21.5652174 L9.166,12.833 L0.434782609,12.8333333 C0.194658804,12.8333333 -9.23705556e-14,12.6386745 -9.23705556e-14,12.3985507 L-9.23705556e-14,9.60144928 C-9.23705556e-14,9.36132547 0.194658804,9.16666667 0.434782609,9.16666667 L9.166,9.166 L9.16666667,0.434782609 C9.16666667,0.194658804 9.36132547,1.5513233e-16 9.60144928,0 L12.3985507,0 Z' id='plusCombined-Shape' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
