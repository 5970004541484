import React from 'react'
import crypto from 'crypto'

const arrowDown = (color, className, rotate = 0) => {
  const randomID = crypto.randomBytes(12).toString('hex')
  return (
    <svg
      aria-label='Arrow'
      role='img'
      focusable='false'
      width='22px'
      height='22px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      className={className}
    >
      <title>Arrow Icon</title>
      <desc>Arrow Icon</desc>
      <defs id={`arrowIcon-${randomID}`}>
        <path
          d='M18.72875,11.2325 L6.72175,0.2925 C6.32775,-0.0975 5.68975,-0.0975 5.29475,0.2925 C4.90175,0.6825 4.90175,1.3225 5.29475,1.7125 L16.57775,11.9925 L5.29475,22.2825 C4.90175,22.6725 4.90175,23.3025 5.29475,23.7025 C5.68975,24.0925 6.32775,24.0925 6.72175,23.7025 L18.72875,12.7625 C18.93875,12.5525 19.02875,12.2725 19.01475,11.9925 C19.02875,11.7225 18.93875,11.4425 18.72875,11.2325'
          id={`arrowpath-${randomID}`}
        />
      </defs>
      <g
        stroke={color}
        strokeWidth='1.2'
        fill='none'
        fillRule='evenodd'
      >
        <mask id={`mask-${randomID}`} fill='white'>
          <use xlinkHref={`#arrowpath-${randomID}`} />
        </mask>
        <use
          fill={color}
          transform='translate(12.008079, 11.997500) rotate(-270.000000) translate(-12.008079, -11.997500) '
          xlinkHref={`#arrowpath-${randomID}`}
        />
      </g>
      <style jsx>
        {`
        svg {
          transform: rotate(${rotate}deg);
        }
      `}
      </style>
    </svg>
  )
}
export default arrowDown
