module.exports = {
  defaultCharity: process.env.DEFAULT_CHARITY,
  sweeps_yearOfPG: process.env.SWEEPS_YEAR_OF_PG,
  MAX_FILE_SIZE: 10240,
  SIGNIN: 'Log In',
  signUpText: 'SIGN UP',
  logInText: 'LOG IN',
  CampaignTemplateV1: 'CampaignTemplateV1',
  myCause: 'MY CAUSE',
  childWarningText: "We're sorry, but we can't accept your messages or personal information because you do not meet our eligibility requirements. But that doesn't mean you can't explore the rest of our site and learn more about us! Check out [https://www.pg.com](https://www.pg.com/) for information about P&G and its brands.",
  articleListingReadMore: 'Read More',
  helpTitle: 'Have a question?',
  cantFindRetailer: 'Can’t find your retailer? Let us know.',
  currentlyUnavailable: 'CURRENTLY UNAVAILABLE',
  oops: 'Oops!',
  errorMessage: 'Something went wrong. Please try again.',
  errorMessageOptOut:
    'Oops! It looks like you’ve previously opted out of our program. To reactivate your account using the same email address, please visit our [Help Page](/help). We’re happy to help get you back to saving on the brands you love!',
  MonthlySweepsIdNonProd: '701',
  MonthlySweepsIdProd: '622',
  comingsoon: 'coming soon',
  completed: 'COMPLETED',
  impact: '/impact/',
  totalImpact: '2,500,000+',
  alertBoxText: {
    signin: 'LOG IN',
    or: 'OR',
    signup: ' SIGN UP',
    signupNow: 'SIGN UP NOW',
  },
  couponsText: {
    basketIcon: 'BASKET',
    countryCode: '+1',
    tryAgainmessage: 'Failed to send please try again later.',
    digitalCouponbasketTitle: 'My brandSAVER Coupons',
    sendtoCoupon24: 'SEND TO COUPON24',
    continueToCoupon24: 'CONTINUE TO COUPON24',
    howdigitalCouponswork: 'Learn how brandSAVER coupons work',
    goBackToDigitalCart: 'Go back to brandSAVER cart',
    basketTitle: 'MY COUPONS',
    deletePrompt: {
      text:
        'Are you sure you want to delete this coupon? It will be removed from your basket.',
      goBack: 'Never mind',
      yes: 'Yes, delete it',
    },
    printConfModal: {
      dontShowAgain: "Don't show again.",
      cancelLabel: 'Cancel',
    },
    noMoreCouponsToPrint: "We're sorry, there are no offers available at this time. We hope you'll check out our rewards program where you can turn your simple actions into ways to do good for others and yourself. [Click here](/rewards) to learn more.",
    registrationSuccessMessage: 'You’ve created your account. You can now start using Digital Coupons.',
    digitalCoupon: 'DIGITAL COUPONS',
    specialCharacterHelpText: 'Do not use letters or special characters.',
    invalidMobileNoerror: 'The phone number You’ve provided is invalid.',
    sendDownloadLink: 'SEND DOWNLOAD LINK',
    downloadLinkSent: 'Success!',
    downloadLinknotSent: 'Oops, something went wrong. Try again.',
  },
  okdeleteText: 'YES, DELETE',
  goBackText: 'Go back',
  almostFinishedLabel: 'Almost done!',
  setAsMyCause: 'SET AS MY CAUSE',
  dayLimitText: 'Maximum of three images per receipt.',
  invalidExtension: 'Uploaded extension is not supported',
  selectCauseModalError:
    'Please select a cause so you can make your first donation.',
  loginContextText: {
    receiptScan: 'Please log in to scan your receipt and earn rewards points.',
    deq: 'Please log in to take this survey and earn rewards points.',
    quiz: 'Please log in to take this quiz and earn rewards points.',
    featuredPromo: 'Please log in to take the survey',
    feedbackSurvey: 'Please log in take the feedback survey',
    churnPrevention: 'Please log in to get the reward',
    donation: 'Please log in to make a donation to this cause.',
    couponsLoginText: 'Please login to clip this coupon.',
    LinkYourAccountLoginText: 'Please login to link your accounts.',
    giftCards: 'Please log in to redeem this gift card.',
    sweepsstakes: 'Please log in to enter this sweepstakes.',
    generic: 'Please log in to get started.',
    digitalSubscription: 'Please log in to redeem this digital subscriptions.',
    localExperiences: 'Please log in to redeem this local experiences.',
    samples: 'Please log in to get the sample.',
    addToHome: 'Log in to earn 25 points for adding us to your home screen.',
    charityPledge: 'Please log in to make a pledge to this cause.',
    dashBoard: 'Please log in to view your dashboard',
    sweepstakesReward: 'Log in with your P&G everyday account to claim your sweepstakes entries.',
    pgBrandLanding: 'Please log in to save your brand preferences.',
    rateLimitingTelemetryEvent: 'Forgot password Failure due to rate limit',
  },
  charityName: {
    382: 'Bonus Act of Good',
    619: 'Bonus Act of Good',
  },
  skipToContent: 'Skip to main content',
  rewardsTitle: {
    NFL_Sweeps: 'NFL SWEEPSTAKES ENTRIES',
    sweepstakesEntries: 'SWEEPSTAKES ENTRIES',
    causeDonation: 'A BONUS ACT OF GOOD',
    digitalSubscription: 'DIGITAL SUBSCRIPTIONS',
    localExperiences: 'LOCAL EXPERIENCES',
    giftCards: 'DIGITAL REWARDS',
    samples: 'SAMPLES',
    additionalGiftCards: 'ADDITIONAL GIFT CARDS',
  },
  receiptScanDetails: {
    receiptDate: 'Receipt date',
    retailer: 'Retailer',
    brands: 'Brands',
    receiptPatternRegex: '.*?receiptscan.*',
  },
  viewDetails: 'View Details',
  rewardsEmptyText: "We're sorry but we are temporarily out of stock. More rewards are on the way. Please try again later.",
  donationSuccessShareText: 'Share this to inspire others to do good.',
  causePage: 'causePage',
  charityPage: 'charityPage',
  causeWithPledgePage: 'causeWithPledgePage',
  notNow: 'Not now',
  backToNewFeed: 'Back to news feed',
  searchPlaceholder: 'What are you looking for?',
  searchOf: 'of',
  searchResult: 'results',
  searchLoad: 'MORE RESULTS',
  resendMailText: 'Resend Email',
  serveySubtitle: '__1__ [icon](causeSurveyMini) + __25__ [icon](sparksSurveyMini)',
  feebBackSurveyUrl: 'https://pgsurveys.segmanta.com/7edfq8',
  NPSSurveyUrl: {
    stageUrl: 'https://pgsurveys.segmanta.com/aife51',
    prodUrl: 'https://pgsurveys.segmanta.com/7dbc8h',
  },
  rewardMessage: {
    rewardHeading: 'Congrats! You’ve earned a reward.',
    freePhotoHeading: 'Congrats! You’ve earned a free photo book.',
    body: 'Click below to visit your Messages, where you’ll find all the details to access your reward.',
  },
  rewardMessageforFeaturedSurvey: {
    heading: 'Congrats!',
    body: 'You’ve been <b>automatically entered 5 times </b>into the Year’s Worth of P&G Products sweepstakes. Good luck!',
  },
  enrollmentLocationExternalReference: {
    general: 'PGGE_Web',
    pampersSweepsTransition: 'Pampers_EnrollSweeps',
    pampersGiftCardTransition: 'Pampers_Acquisition',
    pggesweepstransition: 'Everyday_Acquisition',
    pggeGiftCardTransition: 'PGE_Acquisition',
    sourceEnrollment: 'Source_Enrollment',
    MLSBrandSaver: 'PGGE_BrandSaver',
    pggeUberTransition: 'PGGE_UberAcquisition',
    pggeDunkinTransition: 'PGGE_DunkinAcq',
    pggeSBUXAcquisitionTransition: 'PGGE_SBUXAcquisition',
    youScoreWeDonate: 'PGGE_Olympics_Acquis',
    changingForGood: 'PGGE_VitalVoices',
    score: 'PGGE_BrandSaver',
    genGgiveAway: 'PGGE_GenG',
    twogetherMusicFestival: 'PGGE_2024TMF',
    cincinnatiMusicFestival: process.env.CINCINNATI_MUSIC_FESTIVAL,
    musicFestival: 'PGGE_2023RTB',
  },
  submittedText: 'Submitted:',
  totalPointsEarned: 'Total points earned*:',
  receiptTotal: 'Receipt total:',
  retailer: 'Retailer:',
  receiptID: 'Receipt ID:',
  receiptScanCode: 'RCPT_SCN01',
  receiptRejected: 'Rejected',
  receiptUploaded: 'Uploaded',
  receiptApproved: 'Approved',
  totalCommunityID: 'COMMUNITY',
  totalEqualityID: 'EQUALITY',
  totalPlanetID: 'ENVIRONMENT',
  messageCenterLoggedOutContent: 'PLEASE LOG IN TO ACCESS YOUR MESSAGES.',
  pointsHistoryLoggedOutContent: 'Please log in to access your points history.',
  connectWithUs: 'Connect with Us',
  addressFormFieldLabels: {
    firstName: 'First name',
    lastName: 'Last name',
    addressLine1: 'Address 1',
    addressLine2: 'Address 2',
    cityName: 'City',
    province: 'State',
    postalCode: 'Zip',
    state: 'State',

  },
  submitAddressCtaText: 'GET MY SAMPLE',
  loginConfirmationForBonusSurveyDescription: 'We’ve made a donation to <b>{text}</b>. Complete this survey to collect your $5 Uber voucher! Check your ‘Messages’ notification center when you’re finished to get your reward.',
  emailVerificationLabels: {
    emailSendingInProgressText: 'Verification email sending in progress',
    emailSendingFailedText: ' OOPs! Verification email send failed. Please try again.',
  },
  OFFER_TYPE: {
    REWARD_CATALOG: 'REWARD_CATALOG',
    OFFER_CATALOG: 'OFFER_CATALOG',
    OFFER: 'OFFER',
    REWARD: 'REWARD',
  },
  rewardType: {
    sweeps: 'Sweeps',
  },
  welcomeText: 'Welcome, {name}',
  tcInfo: 'To complete, please accept the terms and conditions and verify your email by clicking on the “click here to proceed”.',
  errorMessages: {
    ALREADY_AVAILED: 'Whoops, looks like You’ve already clipped this coupon.',
    INVALID_INCENTIVE: 'Hmm, we can’t find the deal you’re looking for. Try again.',
    INVENTORY_OUT_OF_STOCK: 'Oh no! This coupon is out of stock. Try another deal!',
    INCENTIVE_EXPIRED: 'Yikes. Time has run out on this coupon, and it’s expired.',
    CONSUMER_NOT_ELIGIBLE: 'Well, this is awkward. The requirements to access this deal haven’t been met. Try again.',
    INVALID_PROMOCODE: 'Sorry, this code is invalid. Try another.',
    TRY_AGAIN: 'If at first you don’t succeed, try clipping this coupon again.',
    GENERIC_ERROR: 'Sorry! The coupon You’ve selected is no longer available.',

  },
  formInputMaxLength: {
    firstNameMaxLength: 14,
    lastNameMaxLength: 15,
    zipCodeMaxLength: 10,
    addressCityMaxLength: 30,
    address1MaxLength: 35,
    address2MaxLength: 35,
    OTPmaxLength: 6,
    emailMaxLength: 50,
    passwordMaxLength: 60,
  },
  recaptchaLoadingMessage: 'Hang tight, CAPTCHA loading.',
  recaptchaNetworkErrorMessage: 'Something went wrong. Please make sure you are not using a VPN, ad-blockers or browser plug-ins and try again',
  restrictedCountryErrorTwo: 'P&G Good Everyday is only accessible in the U.S. and certain U.S. territories. Learn more about P&G: <strong>[pg.com](https://www.pg.com/)</strong> ',
  YOUR_PRIVACY_CHOICE: 'Your Privacy Choices',

  CLEAR_ALL: 'Clear All',
  ALL: 'All',
  MY_FAVORITES: 'My Favorites',
  DESKTOP_PER_PAGE: 12,
  MOBILE_PER_PAGE: 10,
  DESKTOP_FOR_ALL: 24,
  MOBILE_FOR_ALL: 20,
  FEATURED: 'Most Loved',
  NO_FAV_FOUND: 'No favorites found!',
  NOTHING_TO_SHOW: 'No categories selected!',
  REWARDS_REDEMPTION_LIMIT: 20,
  iohVideoGatingCampaign: 'ioh-video-gating',
  stateInfoText: 'Please change Zip Code to modify State',
  cityInfoText: 'Please change Zip Code to modify City',
  sweepsstakesCampaignText: '*New bundles weekly, products subject to change.',
  networkErrorMessage: 'Network hiccup! Please try again.',
  networkErrorCta: 'Try again',
  testEmail: 'dummy@dummy.com',
  testName: 'dummy',
  testId: 'dummy',
  testmarketingProgramNbr: 'dummy',
  birthDayInterationType: 'BirthdayBonus2',
  halfBirthDayInterationType: 'Half_Birthday_Bonus',
  aprilFoolInterationType: 'April_Fools_50',
  aprilFoolInterationTypeFilter: 'APR_FOOL_BONUS',
  oktaLogin: 'login',
  oktaSignup: 'signup',
  pageSpinnerText: 'Hang tight! Epic rewards & savings are only a few seconds away.',
  ineligibleCascadePromoSubHeading: 'It seems you\'ve already received a Cascade Platinum Plus sample, but don\'t miss out on P&G coupons special for you!',
  swifferMopDescription: 'To claim your free sample, log in and complete our form. Once it arrives, share on social with hashtags __#PowerMopClean__ and __#GiftedbySwiffer__!',
  sessionContinueCta: 'Continue',
  sessionLogoutCta: 'Log Out',
  sessionExpiryErrorMessage: 'Your session is going to expire in {{remainingTime}} minutes. Click \'Continue\' to keep exploring exclusive P&G rewards and savings!',
  sessionExpiryMinute: 5 * 60 * 1000,
  changePasswordAny3Validation: 'At least 3 of the following:',
  voteNow: 'Vote Now',
  offerOver: 'This offer is over - but check out more benefits',
  tideScholarshipPageLoadingText: 'Our scholarship page is loading!',
  tideScholarshipSubtext: 'if the page doesn’t load, please refresh your browser window.',
  profileTypeCodes: ['Sweep_YO_PG_Complete', 'Survey_TellMe', 'PRF_BONUS01', 'US_COMP_PROF_PT'],
  hideFooterOn: ['hbcyou', 'sustainability', 'tide-digital-coupon', 'over', 'tide-scholarship', 'gillette-labs-coupon', 'old-spice-coupon', 'always-discreet-coupon-special-offer', 'hs-coupon', 'tampax-coupon', 'oldspice-coupon-family-dollar'],
  showCloseButtonOnAccountPages: ['hbcyou', 'tide-digital-coupon', 'dawn-ducks', 'gillette-labs-coupon', 'old-spice-coupon', 'always-discreet-coupon-special-offer', 'olay-coupons', 'hs-coupon', 'oldspice-coupon-family-dollar'],
  programIds: ['111769883', '111769881'],
  defaultProgramId: '111512919',
  cookiesDisabledPopup: {
    heading: "We can't sign you in.",
    subHeading: "Your browser is currently set to block cookies. You need to allow cookies to use this service. Cookies are small text files stored on your computer that tell us when you're signed in. To learn how to allow cookies, check the online help in your web browser.",
  },
  errorEventMessage: {
    badRequest: 'Bad Request',
    resourceNotFound: 'Resource not found',
    invalidsubscriptionKey: 'Access denied due to invalid subscription key. Make sure to provide a valid key for an active subscription.',
    forbidden: 'Forbidden',
    unauthorizedToken: 'Unauthorized Token',
    unprocessableEntity: 'Unprocessable entity',
    unauthorizedAccess: 'Unauthorized Access',
    rateLimitExceeded: 'Rate limit exceeded',
    rateLimit: 'rate limit',
    serviceNotAvailable: 'Service not available',
    internalServerError: 'Internal server error',
    badGateway: 'Bad Gateway',
  },
  completeProfileEndCard: {
    link: 'Continue',
    iconName: 'PGGECircleLogo',
    heading: 'We appreciate you!',
    text: 'It’s been great getting to know you! You\'ve earned <strong>25 points + a sweepstakes entry for our $1,000 P&G Prize Pack.</strong> Want to earn even more points for awesome rewards? Close out this survey and check out all the amazing rewards and savings P&G Good Everyday has to offer!',
  },
}
