import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'
import { observer } from 'mobx-react'
import Link from 'next/link'
import redirectToCallbackURL from '../../helpers/redirect.helpers'
import Icon from '../Icon/Icon'
import Button from '../Button'
import SubmitButton from '../SubmitButton/SubmitButton'
import modalStyles from './Modal.tw-styles'
import { sessionClearing } from '../../okta/forms/okta.helpers'
import modalstyles from './Modal.styles'
import { variants } from '../../constants/variants'
import RichText from '../RichText/RichText'
import Typography from '../Typography/Typography'
import LoginStore from '../UserManagement/Login-store'
import LayoutStore from '../Layout/Layout.store'
import { STATUS_0000 } from '../../constants/status-code'

const ModalV2 = ({
  children,
  redirectUrl,
  logout,
  closeHandler,
  cancelHandler,
  okHandler,
  styles,
  HideOkButton,
  info,
  okHandlerRedirectUrl,
  pageSource,
  showSpinner,
  setShowSpinner,
  iconName,
  dataTestId,
  variant,
  heading,
  replacingValue,
  addedStyle,
  onLoginCLick,
  onSignupClick,
  buttonClick,
  okay,
  cancel,
}) => {
  const twStyles = modalStyles[styles] || modalStyles.default
  let paragraphData = {}; let richTextData = {}; let typographyData = {}; let iconData = {}
  let headingData = {}; let cancelText = ''; let okText = ''; let couponData = {}; let loginData = {}
  let contactData = {}; let birthdayData = {}
  const data = LayoutStore.ModalContent || LoginStore.ModalContent
  if (variant) {
    for (let i = 0; i < data.length; i += 1) {
      if (data[i] && data[i].variant === variant
        && Object.values(variants?.paragraphModal).includes(data[i].variant)) {
        paragraphData = data[i]
        okText = data[i].okText
        cancelText = data[i].cancelText
        break
      }
      if (data[i] && data[i].variant === variant && variant === variants?.layoutOptOutError) {
        okText = data[i].okText
        cancelText = data[i].cancelText
        richTextData = data[i]; break
      }
      if (data[i] && data[i].variant === variant
        && variant === variants?.layoutCountryRestrictedError) {
        okText = data[i].okText
        cancelText = data[i].cancelText
        typographyData = data[i]; break
      }
      if (data[i] && data[i].variant === variant && variant === variants?.layoutNetworkError) {
        okText = data[i].okText
        cancelText = data[i].cancelText
        paragraphData = data[i]; break
      }
      if (data[i] && data[i].variant === variant
        && variant === variants?.layoutCompleteProfileEndCard) {
        okText = data[i].okText
        cancelText = data[i].cancelText
        iconData = data[i]; break
      }
      if (data[i] && data[i].variant === variant
        && variant === variants?.rewardDetailRedemptionEmailStatus) {
        okText = data[i].okText
        cancelText = data[i].cancelText
        headingData = data[i]; break
      }
      if (data[i] && data[i].variant === variant
        && variant === variants?.couponCascadeThanksPopup) {
        okText = data[i].okText
        cancelText = data[i].cancelText
        couponData = data[i]; break
      }
      if (data[i] && data[i].variant === variant
        && [variants?.revtraxDigitalCouponLogin, variants?.loginForm].includes(variant)) {
        okText = data[i].okText
        cancelText = data[i].cancelText
        loginData = data[i]; break
      }
      if (data[i] && data[i].variant === variant && variant === variants?.contactShowChatPopup) {
        okText = data[i].okText
        cancelText = data[i].cancelText
        contactData = data[i]; break
      }
      if (data[i] && data[i].variant === variant
        && Object.values(variants?.birthDayModal).includes(variant)) {
        okText = data[i].okText
        cancelText = data[i].cancelText
        birthdayData = data[i]; break
      }
    }
  }
  const onConfirmation = () => {
    if (okHandler) okHandler()
    else if (logout) sessionClearing()
    else if (redirectUrl) redirectToCallbackURL(redirectUrl, redirectUrl)
    closeHandler()
  }

  const onCancelation = () => {
    if (logout) {
      sessionClearing()
    }
    cancelHandler()
  }

  const closeBtnHandler = () => {
    if (variant === variants.paragraphModal.sessionExpired) {
      closeHandler()
      return
    }

    if (
      pageSource === 'earnPointsBirthdayPromo'
      || pageSource === 'alertBox'
      || cancelText
    ) {
      onCancelation()
    } else {
      onConfirmation()
    }
  }

  const handleEcsPress = (event) => {
    if (event.code === 'Escape') {
      closeBtnHandler()
    }
  }

  const handleFocusin = () => {
    document.querySelector('[role=dialog] button').focus()
  }

  useEffect(() => {
    setShowSpinner(false)
    const headerElm = document.getElementsByTagName('header')[0]
    headerElm?.addEventListener('focusin', handleFocusin)
    window.addEventListener('keydown', handleEcsPress)
    return () => {
      window.removeEventListener('keydown', handleEcsPress)
      headerElm?.removeEventListener('focusin', handleFocusin)
    }
  }, [])

  const ErrorModalCode = LoginStore.errorModalCode && LoginStore.errorModalCode !== STATUS_0000 ? (<p>{`Error Code: ${LoginStore.errorModalCode}`}</p>) : null

  const variantLogic = () => {
    switch (variant) {
      case variants?.paragraphModal?.headerReward:
      case variants?.paragraphModal?.layoutRateQuoteLimitExceededError:
      case variants?.paragraphModal?.sitemapLogout:
      case variants?.paragraphModal?.usermenuLogout:
      case variants?.layoutNetworkError:
      case variants?.paragraphModal?.accountSetupIneligiblePromoContent:
      case variants?.paragraphModal?.accountSetupCascadeIneligiblePopup:
      case variants?.paragraphModal?.accountSetupIneligiblePromoForSpecialSample:
      case variants?.paragraphModal?.rewardDetailRedeemFailure:
      case variants?.paragraphModal?.rewardOptoutModal:
      case variants?.paragraphModal?.accountSetupAlreadyAccountAvailable:
      case variants?.paragraphModal?.campaignLandingProfileIncomplete:
      case variants?.paragraphModal?.updatePasswordChangedSuccessfully:
      case variants?.paragraphModal?.updatePasswordInValidToken:
      case variants?.paragraphModal?.digitalCouponUnlinkingConfirmation:
      case variants?.paragraphModal?.cookieDisabled:
      case variants?.paragraphModal?.couponCardBasketLengthError:
      case variants?.paragraphModal?.earnPointsOutOfStock:
        return (
          <>
            <p>{paragraphData[heading] || paragraphData.heading1 || ''}</p>
            <p>{paragraphData.heading2 || paragraphData.subHeading || paragraphData.subHeading2 || ''}</p>
            {ErrorModalCode}
          </>
        )
      case variants?.paragraphModal?.layoutError:
        return (
          <>
            <p>{paragraphData[heading] || paragraphData.heading1 || ''}</p>
            <p>{paragraphData.heading2 || paragraphData.subHeading || ''}</p>
            {paragraphData.subHeading2 && (
              <Typography
                className={twStyles.subHeadingText}
                markdown={paragraphData.subHeading2}
                renderers={{
                  link: (props) => (
                    <Link
                      href={props.href}
                      aria-label={props.href}
                      onClick={() => {
                        LoginStore.showErrorModal = false
                      }}
                      prefetch={false}
                    >
                      {props.children}
                    </Link>
                  ),
                }}
              />
            )}
            {ErrorModalCode}
          </>
        )
      case variants?.paragraphModal?.accountSetupInvalidProgramIdPopup:
        return (
          <>
            <p>{paragraphData[heading] || paragraphData.heading1 || ''}</p>
            <p>{paragraphData.subHeading || paragraphData.subHeading2 || ''}</p>
            {ErrorModalCode}
          </>
        )
      case variants?.paragraphModal?.receiptWidgetMaximumUpload:
      case variants?.paragraphModal?.receiptWidgetDeleteConfirmation:
      case variants?.paragraphModal?.receiptWidgetInvalidFilename:
      case variants?.paragraphModal?.receiptWidgetInvalidExtension:
      case variants?.paragraphModal?.receiptWidgetFileSizeExceeded:
      case variants?.paragraphModal?.receiptScanModalShow:
        return (
          <h2>{paragraphData.heading1 || paragraphData.subHeading2}</h2>
        )
      case variants?.layoutOptOutError:
        return (
          <RichText body={richTextData.subHeading2} />
        )
      case variants?.paragraphModal?.pointsHistoryErrorResendNoContent:
        return (
          <p className={twStyles.pointsRedeemPopupmsg}>
            {paragraphData.heading1}
          </p>
        )
      case variants?.revtraxDigitalCouponLogin:
        return (
          <>
            <div className=''>
              <div className=''>{loginData.heading2}</div>
            </div>
            <div>
              <SubmitButton
                buttonText={loginData.heading1}
                id='login-submit-btn'
                onClick={onLoginCLick}
              />
            </div>
            {!LoginStore.PGEUserRedirectedFromEmail && (
              <div className='text-center text-sm lg:text-base lg:leading-leading20 font-montserratlight font-light px-0 md:px-30 lg:px-10 mxl:px-40 leading-leading20 text-input-br pt-30'>
                {loginData.subHeading}
              </div>
            )}
            <div>
              <SubmitButton
                buttonText={okText}
                variant='blueBorder'
                id='signup-submit-btn1'
                onClick={onSignupClick}
              />
            </div>
          </>
        )
      case variants?.contactShowChatPopup:
        return (
          <>
            <div className='flex justify-center pb-10 font-regular'>
              <span className='w-55 h-55 flex chatIcon justify-center items-center rounded-full bg-primary'>
                <Icon name='chatBot' className='' />
              </span>
            </div>
            <RichText className='font-bold pb-10 leading-widest'>{contactData.heading1}</RichText>
            <RichText className='pb-10 text-14 leading-light bg-white'>{contactData.subHeading2}</RichText>
            <Button onClick={buttonClick} className='w-full flex justify-center mx-auto cursor-pointer mt-10px focus:outline-none btn btn-primary  '>
              {contactData.subHeading}
            </Button>
          </>
        )
      case variants?.layoutCountryRestrictedError:
        return (
          <>
            <h2 className={twStyles.restrictedTitle}>
              {typographyData.heading1}
            </h2>
            <Typography
              className={twStyles.restrictedText}
              markdown={typographyData.heading2}
            />
            <Typography
              className={twStyles.restrictedText}
              markdown={typographyData.subHeading}
            />
          </>
        )
      case variants?.layoutCompleteProfileEndCard:
        return (
          <>
            <div className={twStyles.birthdayIconWrapper}>
              <Icon
                className={twStyles.iconWrapper}
                name={iconData.subHeading}
              />
            </div>
            <p className={twStyles.headingWrapper}>
              {iconData.heading1}
            </p>
            <div className={twStyles.contentWrapper}>
              <Typography markdown={iconData.subHeading2} />
            </div>
          </>
        )
      case variants?.paragraphModal?.sessionExpired:
        return (
          <>
            <p>{paragraphData.heading1}</p>
            <p>{paragraphData.subHeading2?.replace('{remaining}', replacingValue)}</p>
          </>
        )
      case variants?.paragraphModal?.pointsHistorySuccessResendPopUp:
      case variants?.paragraphModal?.pointsHistoryResendMailError:
        return (
          <p className={twStyles.pointsRedeemPopupmsg}>
            {paragraphData.subHeading2 ? paragraphData.subHeading2.replace('{email}', replacingValue) : paragraphData.heading1}
          </p>
        )
      case variants?.paragraphModal?.messageResendEmailSuccess:
        return (
          <>
            <p>{paragraphData.heading || ''}</p>
            <p>{paragraphData.subHeading2.replace('{email}', replacingValue)}</p>
          </>
        )
      case variants?.rewardDetailRedemptionEmailStatus: {
        return (
          addedStyle ? (
            <h4 className={addedStyle}>
              {headingData[heading]}
            </h4>
          ) : (
            <h4>
              {headingData[heading]}
            </h4>
          )
        )
      }
      case variants?.paragraphModal?.accountSetupDigitalCouponsThankyou:
        return (
          <>
            <p>{paragraphData.heading1}</p>
            <Typography markdown={paragraphData.subHeading || paragraphData.subHeading2} />
          </>
        )
      case variants?.couponCascadeThanksPopup:
        return (
          <>
            <div className={twStyles.birthdayPromo.birthdayIconWrapper}>
              <Icon
                className={twStyles.birthdayPromo.iconWrapper}
                name={couponData.heading2}
              />
            </div>
            <p className={twStyles.birthdayPromo.headingWrapper}>
              {couponData.heading1}
            </p>
            <Typography markdown={couponData.subHeading} />
          </>
        )
      case variants?.birthDayModal?.birthdayPromoModal1:
      case variants?.birthDayModal?.birthdayPromoModal2:
      case variants?.birthDayModal?.halfBirthdayPromoModal1:
      case variants?.birthDayModal?.halfBirthdayPromoModal2:
      case variants?.birthDayModal?.showTwogetherThanksPopUp:
      case variants?.birthDayModal?.showCincinnatiThanksPopUp:
      case variants?.birthDayModal?.aprilFoolPromoModal1:
        return (
          <>
            {birthdayData.heading2 ? (
              <div className={twStyles.birthdayPromo.birthdayIconWrapper}>
                <Icon
                  className={twStyles.birthdayPromo.iconWrapper}
                  name={birthdayData.heading2}
                />
              </div>
            ) : null}
            <p className={twStyles.birthdayPromo.headingWrapper}>
              {birthdayData.heading1}
            </p>
            <Typography
              className={twStyles.birthdayPromo.contentWrapper}
              markdown={birthdayData.subHeading2}
            />
          </>
        )
      case variants?.loginForm:
        return (
          <>
            <div className={twStyles.wrapperHeader}>
              <div className=''>{LoginStore.context}</div>
            </div>
            <div
              className={`${'clear'} ${
                pageSource === 'LoginPage'
                  ? twStyles.buttonLogin
                  : twStyles.button
              }`}
            >
              <div>
                <SubmitButton
                  buttonText={loginData.heading1}
                  id='login-submit-btn'
                  onClick={onLoginCLick}
                />
              </div>
            </div>
            {!LoginStore.PGEUserRedirectedFromEmail && (
            <div className={twStyles.signText}>
              {loginData.heading2}
            </div>
            )}
            <div>
              <SubmitButton
                buttonText={loginData.subHeading}
                variant='blueBorder'
                id='signup-submit-btn'
                onClick={onSignupClick}
              />
            </div>
          </>
        )
      default: {
        return (<>{children}</>)
      }
    }
  }
  okText = okText || okay || 'OK'
  cancelText = cancelText || cancel
  return (
    <div className={twStyles.wrapper} role='dialog' aria-modal='true' tabIndex='-1'>
      <div className={`${'formModal'} ${twStyles.modalWrapper}`} role='document'>
        <div className={twStyles.cancel}>
          <Button
            className='modal-closeBlack'
            data-testid={dataTestId}
            aria-label='close'
            autoFocus
            onClick={closeBtnHandler}
          >
            <Icon
              name={`${iconName || 'closeBlack'}`}
              className='focus:outline-none closeIcon'
            />
          </Button>
        </div>
        <div className={twStyles.modaltext}>{variantLogic()}</div>
        {showSpinner
          ? (
            <div className={twStyles.modelSpinner}>
              <Icon
                name='spinnerCoral'
              />
            </div>
          )
          : (
            <div className={twStyles.Modalbutton}>
              {cancelText ? (
                <>
                  <Button onClick={onConfirmation} className={twStyles.buttoninner}>
                    {okText}
                  </Button>
                  <Button
                    onClick={onCancelation}
                    className={twStyles.buttoninnerText}
                  >
                    {cancelText}
                  </Button>
                  {info && <div className={twStyles.infoText}>{info}</div>}
                </>
              ) : (
                !HideOkButton && okText && (
                <Button
                  onClick={
                  okHandlerRedirectUrl
                    ? () => redirectToCallbackURL(
                      okHandlerRedirectUrl,
                      okHandlerRedirectUrl,
                    )
                    : onConfirmation
                }
                  className={twStyles.buttoninner}
                >
                  {okText}
                </Button>
                )
              )}
            </div>
          )}
      </div>
      <style jsx global>
        {modalstyles}
      </style>
    </div>
  )
}

ModalV2.propTypes = {
  redirectUrl: PropTypes.string,
  logout: PropTypes.bool,
  HideOkButton: PropTypes.bool,
  closeHandler: PropTypes.func,
  cancelHandler: PropTypes.func,
  styles: PropTypes.string,
  okHandler: PropTypes.func,
  info: PropTypes.string,
  okHandlerRedirectUrl: PropTypes.string,
  pageSource: PropTypes.string,
  showSpinner: PropTypes.bool,
  setShowSpinner: PropTypes.func,
  iconName: PropTypes.string,
  dataTestId: PropTypes.string,
  variant: PropTypes.string,
}

ModalV2.defaultProps = {
  redirectUrl: null,
  logout: false,
  info: '',
  cancelHandler: () => {},
  closeHandler: () => {},
  okHandler: null,
  styles: null,
  HideOkButton: false,
  okHandlerRedirectUrl: '',
  pageSource: null,
  showSpinner: false,
  setShowSpinner: () => {},
  iconName: '',
  dataTestId: '',
  variant: '',
}

export default withRouter(observer(ModalV2))
