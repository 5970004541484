
let ciphertext

/*
      Fetch the contents of the "message" textbox, and encode it
      in a form we can use for the encrypt operation.
      */
function getMessageEncoding(password) {
  const enc = new TextEncoder()
  return enc.encode(password)
}

function str2ab(str) {
  const buf = new ArrayBuffer(str.length)
  const bufView = new Uint8Array(buf)
  for (let i = 0, strLen = str.length; i < strLen; i += 1) {
    bufView[i] = str.charCodeAt(i)
  }
  return buf
}

const pemEncodedKey = `-----BEGIN PUBLIC KEY-----
    ${process.env.PUBLIC_KEY}
    -----END PUBLIC KEY-----`

function importRsaKey(pem) {
  // fetch the part of the PEM string between header and footer
  const pemHeader = '-----BEGIN PUBLIC KEY-----'
  const pemFooter = '-----END PUBLIC KEY-----'
  const pemContents = pem.substring(
    pemHeader.length,
    pem.length - pemFooter.length,
  )
  // base64 decode the string to get the binary data
  const binaryDerString = window.atob(pemContents)
  // convert from a binary string to an ArrayBuffer
  const binaryDer = str2ab(binaryDerString)

  return window.crypto.subtle.importKey(
    'spki',
    binaryDer,
    {
      name: 'RSA-OAEP',
      hash: 'SHA-256',
    },
    true,
    ['encrypt'],
  )
}

/*
      Get the encoded message, encrypt it and display a representation
      of the ciphertext in the "Ciphertext" element.
      */
async function encryptMessage(key, password) {
  const encoded = getMessageEncoding(password)
  ciphertext = await window.crypto.subtle.encrypt(
    {
      name: 'RSA-OAEP',
    },
    key,
    encoded,
  )

  const encryptedText = btoa(String.fromCharCode(...new Uint8Array(ciphertext)))
  return encryptedText
}

export default async (password) => {
  const publicKey = await importRsaKey(pemEncodedKey)
  return encryptMessage(publicKey, password)
}

