import { makeAutoObservable } from 'mobx'

class LayoutStore {
  constructor() {
    makeAutoObservable(this)
  }

  ModalContent = {}
}
export default new LayoutStore()
