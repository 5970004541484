import crypto from 'crypto'
import LoginStore from '../components/UserManagement/Login-store'
import {
  enrollmentLocationExternalReference,
  defaultCharity,
  OFFER_TYPE,
  oktaSignup,
  oktaLogin,
  sessionExpiryMinute,
} from '../constants/texts'
import staticRoutes from '../../config/router.config'
import {
  enrollPGGEUserWithWelcomeEmail,
  updateOosStatus,
  fetchIncentives,
  storageAvailable,
} from '../adapters/serverside.adapters'
import { sessionClearing } from '../okta/forms/okta.helpers'
import { issueInteractionApi } from './api.helper'
import redirectTo, { getParameterByName } from './redirect.helpers'
import { triggerRegisiterEvent, triggerRegistrationSuccessEvent } from './gaEvent.helper'

export const checkRewardStatus = () => LoginStore.userLoggedIn

const { REWARD } = OFFER_TYPE

export const setOktaData = (data) => {
  if (data.userData) {
    LoginStore.profileData = data.userData
    sessionStorage.setItem(
      'oktaCaptureProfileData',
      JSON.stringify(data.userData),
    )
  }
}
export const handleBeforeUnload = (e) => {
  e.preventDefault()
  const message = 'Changes you made may not be saved.'
  e.returnValue = message
  return message
}

export const tokenToExpireinMS = (minutes = 0) => {
  const timeNow = new Date(Date.now()).toUTCString().toString()
  const timeNowMs = Date.parse(timeNow)
  const expirationTimeMs = Date.parse(
    localStorage.getItem('oktaCaptureToken_Expires'),
  )
  return expirationTimeMs - timeNowMs - minutes
}

export const actionUrl = () => {
  let url = ''
  switch (LoginStore.utmCampaign) {
    case 'sourceEnrollment':
      url = staticRoutes.earnPoints
      break
    case 'videoGating':
      url = staticRoutes.myAccount
      break
    default:
      url = ''
      break
  }
  return url
}

/**
 * This function handles the user session
 */
export const handleSession = () => {
  const DateNow = new Date()
  DateNow.setMinutes(DateNow.getMinutes() + 30) // Session will expire after 30 minutes
  localStorage.setItem('oktaCaptureToken_Expires', DateNow)
  setTimeout(() => {
    sessionClearing(false, false)
    localStorage.removeItem('loggedIn')
  }, tokenToExpireinMS())
  setTimeout(() => {
    LoginStore.showSessionExpiryModal = true
  }, tokenToExpireinMS(sessionExpiryMinute))
}

/**
 * This function handles the user redirection
 * @param {*} router next router object.
 * @param {*} specificRedirection any specific url to redirect.
 * @param {*} allUTMs pass if current url contains any query params.
 */
export const handleRedirectToCharity = (router, specificRedirection = '', allUTMs) => {
  const campaignName = LoginStore.traitCampaignName
  let redirectUrl = '/'
  if (specificRedirection !== '' && specificRedirection !== null) {
    redirectUrl = `${specificRedirection}${allUTMs}`
  } else if (campaignName?.includes('account-setup')) {
    redirectUrl = campaignName
  } else if (router?.query?.slug === 'out-of-stock') {
    redirectUrl = `${'/account-setup'}${staticRoutes.outOfStock}`
  } else if (campaignName && !campaignName?.includes('ioh-video-gating')) {
    redirectUrl = `${'/account-setup/'}${campaignName}${'/'}`
  }
  router.push(redirectUrl)
}

/**
 * This function checks for inventory available and based on that update OOO status
 * @param {*} siteSettings data from contentful.
 * @param {*} traitValue campaign slug or campiagn name.
 * @param {*} triggerBuild boolean value by default true.
 */
export const handleCheckInventory = async (siteSettings, traitValue, triggerBuild = true) => {
  let sampleID = ''
  let isUpdateRequired = false
  let multipleLot = false
  siteSettings.campaignSamplesIdCollection.items.map((item) => {
    if (item?.name === traitValue) {
      isUpdateRequired = item?.inventoryAvailable
      multipleLot = item?.multipleLot
      sampleID = process.env.OKTA_ENVIRONMENT === 'production'
        ? item?.sampleIdForProd
        : item?.sampleIdForStage
      return sampleID
    }
    return sampleID
  })
  const response = await fetchIncentives()
  const allIncentivesResponse = await response.json()
  const incentiveData = allIncentivesResponse?.data?.incentives.filter(
    (sample) => sample.incentiveId === Number(sampleID),
  )
  if (
    incentiveData
    && incentiveData.length !== 0
    && incentiveData[0]?.categories
    && incentiveData[0]?.categories.length !== 0
  ) {
    // eslint-disable-next-line prefer-destructuring
    LoginStore.incentiveResponse = incentiveData[0]
  }
  const allIncentives = allIncentivesResponse?.data?.incentives
    ?.filter((sample) => sample?.incentiveType === 'SAMPLE')
    .filter(
      (sample) => sample?.distributions[0]?.availableQuantity
        >= Number(process.env.OOS_INVENTORY_LIMIT),
    )

  if (allIncentives?.length >= 1) {
    const filteredData = allIncentives.filter(
      (incentive) => incentive.incentiveId === Number(sampleID),
    )
    if (filteredData && filteredData.length > 0) {
      if (
        filteredData[0].distributions[0].availableQuantity
          <= Number(process.env.OOS_INVENTORY_LIMIT)
        && isUpdateRequired
      ) {
        if (triggerBuild) await updateOosStatus(traitValue, Number(sampleID))
        return false
      }
      if (
        filteredData[0].distributions[0].availableQuantity
          > Number(process.env.OOS_INVENTORY_LIMIT)
        && !isUpdateRequired
        && triggerBuild
        && multipleLot
      ) {
        await updateOosStatus(traitValue, Number(sampleID))
        return true
      }
    } else if (triggerBuild && isUpdateRequired) {
      await updateOosStatus(traitValue, Number(sampleID))
      return false
    }
  }
  return true
}

/**
 * This function returns the incentive data for specific campaign
 * @param {*} siteSettings data from contentful.
 * @param {*} campaignName campaign slug or campiagn name.
 */
export const handleGetIncentiveData = async (siteSettings, campaignName) => {
  let sampleID = ''

  siteSettings.campaignSamplesIdCollection.items.map((item) => {
    if (item.name === campaignName) {
      sampleID = process.env.OKTA_ENVIRONMENT === 'production'
        ? item.sampleIdForProd
        : item.sampleIdForStage
      return sampleID
    }
    return sampleID
  })

  const response = await fetchIncentives()
  const allIncentivesResponse = await response.json()
  const incentiveData = allIncentivesResponse?.data?.incentives.filter(
    (sample) => sample.incentiveId === Number(sampleID),
  )
  if (
    incentiveData
    && incentiveData.length !== 0
    && incentiveData[0]?.categories
    && incentiveData[0]?.categories.length !== 0
  ) {
    return incentiveData[0]
  }
  return null
}

/**
 * This function checks for the deafult charity in contentful for specific campaigns
 * @param {*} siteSettings data from contentful.
 * @param {*} campaignName campaign slug or campiagn name.
 */
export const handleGetCharityIdDuringRegistration = (siteSettings, campaignName) => {
  const CharityID = siteSettings?.defaultCharityIdForCampaignSpecificCollection?.items.filter(
    (item) => item?.campaignUrl === campaignName,
  )
  if (CharityID.length >= 1) {
    return CharityID[0]?.defaultCharity
  }
  return defaultCharity
}

/**
 * This function performs user enrollment for sample campaigns
 * @param {*} siteSettings data from contentful.
 * @param {*} router next router object.
 * @param {*} pggeUser boolean value.
 * @param {*} skipCharityAndGenderDob this falg skips the cause selection screen.
 * @param {*} redirectionUrl url for redirection after enroll.
 */
export const handleEnrollAndRewardAssignment = async (
  siteSettings,
  router,
  skipCharityAndGenderDob = false,
  redirectionUrl = staticRoutes.earnPoints,
) => {
  const campaignName = siteSettings
    && siteSettings?.inActiveCampaigns?.indexOf(LoginStore.traitCampaignName) >= 0
    ? '/account-setup/'
    : LoginStore.traitCampaignName
  LoginStore.traitCampaignName = campaignName
  if (
    campaignName
    && campaignName.includes('-sample')
    && router?.query?.slug !== 'out-of-stock'
  ) {
    sessionStorage.setItem('SampleOrdered', true)
    let response
    let incentiveData
    if (LoginStore.incentiveResponse) {
      incentiveData = LoginStore.incentiveResponse
    } else {
      incentiveData = await handleGetIncentiveData(siteSettings, campaignName)
      LoginStore.incentiveResponse = incentiveData
    }
    if (incentiveData && incentiveData?.categories?.includes(REWARD)) {
      response = await enrollPGGEUserWithWelcomeEmail(
        defaultCharity, // incentiveData?.incentiveId,
      )
    } else {
      response = await enrollPGGEUserWithWelcomeEmail()
    }
    const enrollResponseJson = await response.json()
    console.info(enrollResponseJson)
    if (
      response.status === 200
      && incentiveData
      && incentiveData?.categories?.includes(REWARD)
    ) {
      LoginStore.rewardStatus = true
      LoginStore.userLoggedIn = true
      LoginStore.emailVerified = true
      triggerRegistrationSuccessEvent()
      triggerRegisiterEvent()
      localStorage.setItem('loggedIn', true)
      localStorage.setItem('disableCompleteProfileOverlay', 'true')
      if (campaignName === 'cascade-platinum-plus-sample') {
        LoginStore.showCascadeThanksPopUp = true
        return true
      }
      if (redirectionUrl) router.push(redirectionUrl)
    } else {
      return false
    }
  } else {
    const charityName = handleGetCharityIdDuringRegistration(
      siteSettings,
      router?.query?.slug || router?.query?.utm_campaign,
    )

    const response = await enrollPGGEUserWithWelcomeEmail(charityName)
    const enrollResponseJson = await response.json()
    console.info(enrollResponseJson)

    if (
      LoginStore.addToBasketPendingWithRegistration === true
        || LoginStore.sendOTPPendingWithRegistration === true
        || (skipCharityAndGenderDob === true
          && router?.query?.flow === 'simplified-signup')
    ) {
      LoginStore.charityID = defaultCharity
    } else if (
      skipCharityAndGenderDob === true
        || LoginStore.videoGating === true
        || LoginStore.iohVideoGating === true
    ) {
      LoginStore.charityID = LoginStore.profileData.traitCharitableCause.traitValue = charityName
    }

    if (response.status === 200) {
      LoginStore.rewardStatus = true
      LoginStore.userLoggedIn = true
      localStorage.setItem('loggedIn', true)
      if (
        campaignName === 'global-citizen'
        || campaignName === 'its-our-home'
        || campaignName === 'its-our-home2'
      ) {
        // await issueRewardApi('charity_2_011ADS', 1, 'donation')
      } else if (campaignName === 'cincinnati-music-festival') {
        await issueInteractionApi(
          enrollmentLocationExternalReference.cincinnatiMusicFestival,
        )
        LoginStore.showCincinnatiThanksPopUp = true
      }
      handleRedirectToCharity(router, redirectionUrl)
    } else {
      return false
    }
  }
  return true
}

/**
 * This function performs checks for sample out of stock and then add the required redirection
 * @param {*} siteSettings data from contentful.
 * @param {*} slug campaign slug or campiagn name.
 * @param {*} callbackURL if out of stock then redirect to this URL.
 */
export async function hanldeOutOfStockRedirect(siteSettings, slug, callbackURL) {
  const campaignData = siteSettings?.campaignSamplesIdCollection?.items
    .filter((item) => slug === item.name)

  let isOutOfStock = false

  if (slug && slug.includes('-sample')) {
    const campaignDetails = campaignData?.length > 0 && campaignData[0]

    isOutOfStock = campaignDetails?.inventoryAvailable === false

    const multipleLot = campaignDetails?.multipleLot === true
    if (multipleLot) {
      const stockAvailable = await handleCheckInventory(siteSettings, slug, true)
      isOutOfStock = !stockAvailable
    }
  } else {
    isOutOfStock = campaignData?.length > 0 && campaignData[0]?.inventoryAvailable !== true
  }

  if (isOutOfStock) {
    redirectTo(true, callbackURL)
    LoginStore.auth0Loading = false
    LoginStore.outOfStockTraitCampaignName = `oos-${slug}`
    return true
  }
  return false
}

/**
 * This function returns programId
 * @param {*} mediaId program id from the url.
 */
export function verifyMediaCampaignId(programIds = [], defaultProgramId, mediaId) {
  if (!mediaId) return defaultProgramId

  if (programIds.includes(mediaId)) return mediaId

  return defaultProgramId
}

/**
 * This function handles the redirection to okta sign/login page
 * @param {*} action Pass 'login' for Login form, 'signup' for opening Signup form.
 * @param {*} exactPath URL for which user will be redirected.
 * @param {*} campaignName for utm_campaign campaign name.
 */
export function oktaAuthentication(action, exactPath, campaignName = null) {
  if (!storageAvailable('localStorage')) {
    LoginStore.showCookiesDisabledodal = true
    LoginStore.showSignInPopup = false
    return
  }
  // redirection url
  const signupUri = new URL(`${process.env.SITE_DOMAIN}/auth/`)
  localStorage.setItem('exactPath', exactPath)
  if (
    (exactPath.includes('simplified-signup')
      || LoginStore.simplifiedSignupFlow)
    && action === oktaSignup
  ) {
    signupUri.searchParams.append('flow', 'simplified-signup')
    LoginStore.simplifiedSignupFlow = false
  }
  // if any utm_campaign is present in url then add that utm_campaign to redirection url
  if (exactPath.includes('utm_campaign') && campaignName) {
    signupUri.searchParams.append('utm_campaign', campaignName)
  }
  // if any utm_campaign_coupon is present in url then add that utm_campaign_coupon
  // to redirection url
  if (exactPath.includes('utm_campaign_coupon')) {
    const utm_campaign_coupon = getParameterByName('utm_campaign_coupon')
    signupUri.searchParams.append('utm_campaign_coupon', utm_campaign_coupon)
  }
  // if user doing signup from digital-coupons page then set this value true in localStorage
  // To show the Thank you screen after signup from digital-coupons page
  if (exactPath.includes('digital-coupons')) {
    localStorage.setItem('sendOTPPendingWithRegistration', true)
  }
  if (exactPath.includes('/good-news/')) {
    signupUri.searchParams.append('flow', 'simplified-signup')
    LoginStore.simplifiedSignupFlow = false
    LoginStore.redirectionUrlAfterSimplifiedSignup = exactPath
    localStorage.setItem('redirectionUrlAfterSimplifiedSignup', exactPath)
  }
  LoginStore.userLoggedIn = false
  LoginStore.showSignInPopup = false
  // if user do login from sitemap page then store the previous url in localStorage
  // helps when user click back button after login on sitemap page
  if (action === oktaLogin && exactPath.includes('sitemap')) {
    localStorage.setItem(
      'sitemapBackUrl',
      `backURL-${LoginStore.sitemapBackUrl}`,
    )
  }

  // get all query params from the url
  const allUTMs = exactPath.split('?')[1] || ''

  const domain = process.env.AUTH0_DOMAIN
  const client_id = process.env.AUTH0_CLIENT_ID
  const audience = process.env.AUTH0_AUDIENCE
  const screen_hint = action === oktaSignup ? '&screen_hint=signup' : ''
  // randomvalue to add in state makes the state unique to every user
  const state = crypto.randomBytes(10).toString('hex')
  // final state is combination user-action, current url, unique state,
  // campaignName and allParams seperated by |
  // here only for campaign page signup/login campaignName value is passed
  // otherwise this value will be passed as null
  const encodedvalue = btoa(
    `${action}|${exactPath}|${state}|${campaignName}|${allUTMs}`,
  )
  // final url
  const oktaFormUrl = `https://${domain}/authorize?response_type=code&client_id=${client_id}&redirect_uri=${signupUri}&state=${encodedvalue}&audience=${audience}${screen_hint}`
  window.location.href = oktaFormUrl
}

export const handleOktaSignup = () => oktaAuthentication(oktaSignup, window?.location?.href)
