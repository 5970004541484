import React from 'react'

const TakeSurveyIcon = (active) => (active ? (
  <svg
    width='19px'
    height='19px'
    viewBox='0 0 20 18'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className=' relative w-17 h-15'
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
      <g transform='translate(-708.000000, -902.000000)' id='Bar-/-Nav-/-Tray-/-Bottom-Copy-3' stroke='#fff' strokeWidth='1.8275'>
        <g transform='translate(529.000000, 883.000000)'>
          <g transform='translate(161.000000, 0.000000)'>
            <g transform='translate(19.000000, 20.000000)'>
              <path d='M0,8.415 C4.64747617,8.415 8.415,4.64747617 8.415,-6.79456491e-16 C8.415,4.64747617 12.1825238,8.415 16.83,8.415' />
              <path d='M0,8.415 C4.64747617,8.415 8.415,12.1825238 8.415,16.83 C8.415,12.1825238 12.1825238,8.415 16.83,8.415' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
) : (
  <svg
    width='19px'
    height='19px'
    viewBox='0 0 20 18'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className=' relative w-17 h-15'
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
      <g transform='translate(-708.000000, -902.000000)' id='Bar-/-Nav-/-Tray-/-Bottom-Copy-3' stroke='#5E5E5E' strokeWidth='1.8275'>
        <g transform='translate(529.000000, 883.000000)'>
          <g transform='translate(161.000000, 0.000000)'>
            <g transform='translate(19.000000, 20.000000)'>
              <path d='M0,8.415 C4.64747617,8.415 8.415,4.64747617 8.415,-6.79456491e-16 C8.415,4.64747617 12.1825238,8.415 16.83,8.415' />
              <path d='M0,8.415 C4.64747617,8.415 8.415,12.1825238 8.415,16.83 C8.415,12.1825238 12.1825238,8.415 16.83,8.415' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
))

export default TakeSurveyIcon
