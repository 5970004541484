import React from 'react'
import crypto from 'crypto'

export default (active, className) => {
  const randomID = crypto.randomBytes(12).toString('hex')
  return (
    <svg
      width='69px'
      height='69px'
      viewBox='0 0 69 69'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      className={className}
    >
      <defs>
        <circle id={`path-${randomID}`} cx='27.5' cy='27.5' r='27.5' />
        <filter
          x='-20.9%'
          y='-17.3%'
          width='141.8%'
          height='141.8%'
          filterUnits='objectBoundingBox'
        >
          <feMorphology
            radius='1.5'
            operator='dilate'
            in='SourceAlpha'
            result='shadowSpreadOuter1'
          />
          <feOffset
            dx='0'
            dy='2'
            in='shadowSpreadOuter1'
            result='shadowOffsetOuter1'
          />
          <feGaussianBlur
            stdDeviation='3'
            in='shadowOffsetOuter1'
            result='shadowBlurOuter1'
          />
          <feComposite
            in='shadowBlurOuter1'
            in2='SourceAlpha'
            operator='out'
            result='shadowBlurOuter1'
          />
          <feColorMatrix
            values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0'
            type='matrix'
            in='shadowBlurOuter1'
          />
        </filter>
      </defs>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-682.000000, -386.000000)'>
          <g transform='translate(528.000000, 391.000000)'>
            <g transform='translate(161.000000, 0.000000)'>
              <g>
                <use
                  fill='black'
                  fillOpacity='1'
                  filter='url(#filter-2)'
                  xlinkHref={`#path-${randomID}`}
                />
                <use
                  stroke='#267EA6'
                  strokeWidth='3'
                  fill='#FFFFFF'
                  fillRule='evenodd'
                  xlinkHref={`#path-${randomID}`}
                />
              </g>
              <g
                transform='translate(19.000000, 20.000000)'
                stroke='#5E5E5E'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.8275'
              >
                <g>
                  <path d='M0,8.415 C4.64747617,8.415 8.415,4.64747617 8.415,-6.79456491e-16 C8.415,4.64747617 12.1825238,8.415 16.83,8.415' />
                  <path d='M0,8.415 C4.64747617,8.415 8.415,12.1825238 8.415,16.83 C8.415,12.1825238 12.1825238,8.415 16.83,8.415' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
