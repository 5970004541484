import React from 'react'

const ReceiptRadio = (active, className) => (active ? (
  <svg
    width='22px'
    height='22px'
    viewBox='-1 1 23 20'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g
      stroke='none'
      strokeWidth='2'
      fill='none'
      fillRule='evenodd'
    >
      <g
        transform='translate(-563.000000, -1703.000000)'
      >
        <g
          transform='translate(527.000000, 1390.000000)'
        >
          <g>
            <g transform='translate(37.000000, 314.000000)'>
              <circle
                stroke='#267EA6'
                cx='10'
                cy='10'
                r='10'
              />
              <circle fill='#267EA6' cx='10' cy='10' r='10' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
) : (
  <svg
    aria-labelledby='radiaButtonIconOnboard'
    width='21px'
    height='20px'
    viewBox='-1 1 23 20'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g stroke='none' strokeWidth='2' fill='none' fillRule='evenodd' id='radiaButtonIconOnboard'>
      <g transform='translate(0.518564, 0.000000)' stroke='#267EA6'>
        <circle cx='10' cy='10' r='10' />
      </g>
    </g>
  </svg>
))

export default ReceiptRadio
