import React from 'react'

export default (active, className) => (active ? (
  <svg
    width='25px'
    height='24px'
    viewBox='0 0 25 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(0.800431, 0.000000)'>
        <polygon
          stroke='#FFF'
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
          points='12 2.98061951 14.9474698 1 16.5096902 4.18912539 20.0525302 3.94746977 19.8111024 7.49030976 23 9.05253023 21.0193805 12 23 14.9474698 19.8111024 16.5096902 20.0525302 20.052758 16.5096902 19.8111024 14.9474698 23 12 21.0193805 9.05253023 23 7.49030976 19.8111024 3.94746977 20.052758 4.18912539 16.5096902 1 14.9474698 2.98061951 12 1 9.05253023 4.18912539 7.49030976 3.94746977 3.94746977 7.49030976 4.18912539 9.05253023 1'
        />
        <path
          d='M12.6575,16.557 L12.6575,15.1035 C13.8545,14.942 14.7475,14.258 14.7475,13.0895 C14.7475,10.6955 11.242,11.3225 11.242,10.2205 C11.242,9.9165 11.5175,9.7645 11.9355,9.7645 C12.4865,9.7645 13.3795,10.078 14.0825,10.477 L14.738,9.0995 C14.1775,8.7385 13.4365,8.4725 12.6575,8.387 L12.6575,7 L11.394,7 L11.394,8.3965 C10.178,8.596 9.418,9.3275 9.418,10.382 C9.418,12.738 12.9235,12.054 12.9235,13.1845 C12.9235,13.536 12.6005,13.726 12.0875,13.726 C11.394,13.726 10.3585,13.308 9.6745,12.719 L9,14.0775 C9.6365,14.6 10.501,14.9705 11.394,15.094 L11.394,16.557 L12.6575,16.557 Z'
          fill='#FFF'
          fillRule='nonzero'
        />
      </g>
    </g>
  </svg>
) : (
  <svg
    width='25px'
    height='24px'
    viewBox='0 0 25 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(0.800431, 0.000000)'>
        <polygon
          stroke='#5E5E5E'
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
          points='12 2.98061951 14.9474698 1 16.5096902 4.18912539 20.0525302 3.94746977 19.8111024 7.49030976 23 9.05253023 21.0193805 12 23 14.9474698 19.8111024 16.5096902 20.0525302 20.052758 16.5096902 19.8111024 14.9474698 23 12 21.0193805 9.05253023 23 7.49030976 19.8111024 3.94746977 20.052758 4.18912539 16.5096902 1 14.9474698 2.98061951 12 1 9.05253023 4.18912539 7.49030976 3.94746977 3.94746977 7.49030976 4.18912539 9.05253023 1'
        />
        <path
          d='M12.6575,16.557 L12.6575,15.1035 C13.8545,14.942 14.7475,14.258 14.7475,13.0895 C14.7475,10.6955 11.242,11.3225 11.242,10.2205 C11.242,9.9165 11.5175,9.7645 11.9355,9.7645 C12.4865,9.7645 13.3795,10.078 14.0825,10.477 L14.738,9.0995 C14.1775,8.7385 13.4365,8.4725 12.6575,8.387 L12.6575,7 L11.394,7 L11.394,8.3965 C10.178,8.596 9.418,9.3275 9.418,10.382 C9.418,12.738 12.9235,12.054 12.9235,13.1845 C12.9235,13.536 12.6005,13.726 12.0875,13.726 C11.394,13.726 10.3585,13.308 9.6745,12.719 L9,14.0775 C9.6365,14.6 10.501,14.9705 11.394,15.094 L11.394,16.557 L12.6575,16.557 Z'
          fill='#309DD0'
          fillRule='nonzero'
        />
      </g>
    </g>
  </svg>
))
