import {
  fetchInternetMessage,
  fetchMemberHistory,
} from '../adapters/serverside.adapters'
import { throwAIMIAError } from '../okta/forms/okta.helpers'
import LoginStore from '../components/UserManagement/Login-store'

export const fecthInternetMessageApi = async () => {
  const response = await fetchInternetMessage()
  const data = await response.json()
  if (response.status === 200) {
    return data
  }
  LoginStore.showErrorModal = true
  return throwAIMIAError('fecthInternetMessage', data)
}

export const fetchMemberHistoryApi = async ({ transactionType, page = 1 }) => {
  const response = await fetchMemberHistory({ transactionType, page })
  const data = await response.json()
  if (response.status === 200) return data
  LoginStore.showErrorModal = true
  return throwAIMIAError('fetchMemberHistory', data)
}
