import React from 'react'

export default (active, className) => (
  <svg
    width='31px'
    height='31px'
    viewBox='0 0 31 31'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    className={className}
  >
    <g id='Page-1'>
      <g id='PGGE-Account-open-state-Desktop' transform='translate(-429.000000, -2694.000000)'>
        <g id='Take-a-tour_00000010995928648270598050000002800478883365577095_' transform='translate(429.000000, 2691.000000)'>
          <g id='Take-a-tour-Icon' transform='translate(0.000000, 3.000000)'>
            <circle id='Oval' fill='none' stroke='#5E5E5E' cx='15.5' cy='15.5' r='15' />
            <g id='Take-a-tour' transform='translate(8.000000, 10.000000)'>
              <path id='Path' fill='none' stroke='#46B5D9' d='M0,4.1c2.3,0,4.1-1.8,4.1-4.1c0,2.3,1.8,4.1,4.1,4.1' />
              <path
                id='Path_00000005251696503006362240000016179496218732967867_'
                fill='none'
                stroke='#46B5D9'
                d='M0,4.1c2.3,0,4.1,1.8,4.1,4.1
                       C4.1,6,6,4.1,8.2,4.1'
              />
              <line id='Path_00000099628579003734625410000008555633653502887359_' fill='none' stroke='#46B5D9' x1='8.8' y1='12' x2='12.1' y2='12' />
              <line id='Path_00000088100125276363996720000015519937762545687194_' fill='none' stroke='#46B5D9' x1='10.5' y1='10.4' x2='10.5' y2='13.7' />
              <line id='Path_00000072248102703136970270000013181522425226653579_' fill='none' stroke='#46B5D9' x1='12.4' y1='2.5' x2='15.7' y2='2.5' />
              <line id='Path_00000069367566208657123390000012519042412842080657_' fill='none' stroke='#46B5D9' x1='14' y1='0.8' x2='14' y2='4.1' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

