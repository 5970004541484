import PGGELogo from './PGGELogo.icon'
import PGGELogoRewards from './PGGELogoRewards.icon'
import dots from './dots.icon'
import hamburger from './hamburger.icon'
import profile from './profile.icon'
import graySearch from './graySearch.icon'
import coupons from './Navigation/coupons.icon'
import receiptsIcon from './receiptsIcon.icon'
import earnPointsNav from './Navigation/earnPoints.icon'
import earnPoints from './Navigation/earnPointsNav.icon'
import goodNews from './Navigation/goodNews.icon'
import TakeSurveyIcon from './Navigation/takeSurveyNav.icon'
import rebatesStickyFooterIcon from './Navigation/rebatesStickyFooter.icon'
import PGBrandsIcon from './Navigation/pgBrands.icon'
import SamplesIcon from './Navigation/samples.icon'
import points from './points.icon'
import blueSearch from './blueSearch.icon'
import blueCouponsSearch from './blueCouponSearch.icon'
import close from './close.icon'
import blueClose from './closeBlueSearch.icon'
import plus from './plus.icon'
import minus from './minus.icon'
import digitalCouponsPlus from './DigitalCoupons/digitalCouponsPlus.icon'
import digitalCouponsBluePlus from './DigitalCoupons/digitalCouponsBluePlus.icon'
import digitalCouponsMinus from './DigitalCoupons/digitalcouponsMinus.icon'
import digitalCouponsBlueMinus from './DigitalCoupons/digitalcouponsBlueMinus.icon'
import pgBrandsHeart from './PGBrands/pgBrandsHeart.icon'
import pgBrandsFlip from './PGBrands/pgBrandsFlip.icon'
import spinner from './spinner.icon'
import bgSpinner from './bgSpinner.icon'
import completeProfileOnBoarding from './completeProfileOnBoarding.icon'
import causeOnBoarding from './causeOnBoarding.icon'
import takeSurveyOnBoarding from './takeSurveyOnBoarding.icon'
import scanReceiptOnBoarding from './scanReceiptOnBoarding.icon'
import rewardsOnBoarding from './rewardsOnBoarding.icon'
import sparksOnBoarding from './sparksOnBoarding.icon'
import chatBot from './chatBot.icon'
import whiteCheckmark from './whiteCheckmark.icon'
import arrow from './arrow.icon'
import onboardingInfoIcon from './onboardingInfo.icon'
import radioButtonOnboarding from './radioButtonOnboarding.icon'
import doubleDonation from './twoxThedoGood.icon'
import plusIcon from './MyAccount/plus.icon'
import sparksLeft from './MyAccount/sparksLeft.icon'
import sparksLeftWhite from './MyAccount/sparksLeftWhite.icon'
import sparksRight from './MyAccount/sparksRight.icon'
import sparksRightWhite from './MyAccount/sparksRightWhite.icon'
import RewardsSparksLeft from './MyAccount/RewardsSparksLeft.icon'
import RewardsSparksRight from './MyAccount/RewardsSparksRight.icon'
import heartSparks from './MyAccount/heartSparks.icon'
import redHeart from './MyAccount/heart.icon'
import heartOpen from './MyAccount/heartOpen.icon'
import minusIcon from './MyAccount/minus.icon'
import takeATour from './MyAccount/takeATour.icon'
import privacyChoice from './yourPrivacyChoices'
import causeIcon from './cause.icon'
import notification from './notificationIcon'
import PGGECircleLogo from './PGGECircleLogo.icon'

const colors = {
  red: '#DD3447',
  coral: '#FF6F61',
  blue: '#267EA6',
  darkBlue: '#003CA5',
  green: '#57AB58',
  lightRed: '#FF6F61',
  spinnerBlue: '#1EA7C2',
  gray: '#5E5E5E',
  white: '#FFF',
  black: '#000',
  couponsBlue: '#003CA5',
}

const plusHelp = (active, className) => plus(false, className, colors.lightRed)
const coralPlus = (active, className) => plus(false, className, colors.coral)
const bluePlus = (active, className) => plus(false, className, colors.blue)
const darkBluePlus = (active, className) => plus(false, className, colors.darkBlue)
const greenPlus = (active, className) => plus(false, className, colors.green)
const redPlus = (active, className) => plus(false, className, colors.red)
const minusHelp = (active, className) => minus(false, className, colors.lightRed)
const coralMinus = (active, className) => minus(false, className, colors.coral)
const blueMinus = (active, className) => minus(false, className, colors.blue)
const darkBlueMinus = (active, className) => minus(false, className, colors.darkBlue)
const greenMinus = (active, className) => minus(false, className, colors.green)
const redMinus = (active, className) => minus(false, className, colors.red)
const spinnerBlue = (active, className) => spinner(false, className, colors.spinnerBlue)
const spinnerCoral = (active, className) => spinner(false, className, colors.coral)
const closeWhite = (active, className) => close(false, className, colors.white)
const grayClose = (active, className) => close(false, className, colors.gray)
const closeGray = (active, className) => close(false, className, colors.gray)
const closeBlack = (active, className) => close(false, className, colors.black)
const arrowLeftGray = (active, className) => arrow(colors.gray, className, 90)
const arrowLeftBlue = (active, className) => arrow(colors.couponsBlue, className, 90)
const arrowUpBlueIcon = (active, className) => arrow(colors.blue, className, 0)
const arrowDownBlueIcon = (active, className) => arrow(colors.blue, className, 0)

export default {
  PGGELogo,
  PGGELogoRewards,
  dots,
  hamburger,
  profile,
  graySearch,
  blueSearch,
  blueCouponsSearch,
  coupons,
  receiptsIcon,
  earnPointsNav,
  earnPoints,
  goodNews,
  TakeSurveyIcon,
  rebatesStickyFooterIcon,
  PGBrandsIcon,
  SamplesIcon,
  points,
  closeWhite,
  grayClose,
  blueClose,
  plusHelp,
  minusHelp,
  coralPlus,
  coralMinus,
  bluePlus,
  darkBluePlus,
  blueMinus,
  darkBlueMinus,
  greenPlus,
  greenMinus,
  redPlus,
  redMinus,
  digitalCouponsPlus,
  digitalCouponsBluePlus,
  digitalCouponsMinus,
  digitalCouponsBlueMinus,
  pgBrandsHeart,
  pgBrandsFlip,
  spinnerBlue,
  spinnerCoral,
  bgSpinner,
  closeGray,
  closeBlack,
  completeProfileOnBoarding,
  causeOnBoarding,
  chatBot,
  takeSurveyOnBoarding,
  scanReceiptOnBoarding,
  rewardsOnBoarding,
  whiteCheckmark,
  sparksOnBoarding,
  arrowLeftGray,
  arrowLeftBlue,
  arrowUpBlueIcon,
  arrowDownBlueIcon,
  onboardingInfoIcon,
  radioButtonOnboarding,
  doubleDonation,
  plusIcon,
  sparksLeft,
  sparksLeftWhite,
  sparksRight,
  sparksRightWhite,
  RewardsSparksLeft,
  RewardsSparksRight,
  heartSparks,
  redHeart,
  heartOpen,
  minusIcon,
  takeATour,
  privacyChoice,
  causeIcon,
  notification,
  PGGECircleLogo,
}
