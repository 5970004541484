/* eslint-disable import/no-cycle */
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import loginStore from '../components/UserManagement/Login-store'
import { storageAvailable } from './serverside.adapters'

const eventCodendardEvent = require('../constants/standard-event-log.json')

class TelemetryService {
  trackException = (ex) => {
    this.appInsights?.trackException({ exception: ex })
  }

  trackEvent = (event, error, errorValidation = false) => {
    let errorMessage
    let errorCode
    try {
      if (loginStore?.profileData?.emailAddress || error?.email) {
        if (error && errorValidation) {
          if (Array.isArray(error) && error.length > 0) {
            errorMessage = error[0].eventCodetusText || error.msg
            errorCode = error[0].eventCodetus || error.msg.eventCodetusCode
          } else if (typeof error === 'object') {
            errorMessage = error.eventCodetusText || error || error.msg
            errorCode = error.eventCodetus
              || error.eventCodetusCode
              || error.msg.eventCodetusCode
          }
          let CustomError = {
            errorMessage,
            errorCode,
            email: loginStore?.profileData?.emailAddress || error?.email,
            consumerId:
              loginStore.profileData.consumerId || loginStore.profileData.uuid,
          }
          CustomError = error.page
            ? { page: error.page, ...CustomError }
            : CustomError
          this.appInsights?.trackEvent({ name: event }, CustomError)
        } else {
          Object.assign(error, {
            email: loginStore?.profileData?.emailAddress || error.email,

            consumerId:
              loginStore.profileData.consumerId
              || loginStore.profileData.uuid
              || error.consumerId,
          })
          this.appInsights?.trackEvent({ name: event }, error)
        }
      } else {
        this.appInsights?.trackEvent({ name: event }, error)
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  logErrorToAppInsight = (event, attributes) => {
    try {
      this.appInsights?.trackEvent(
        { name: event?.eventCode },
        { ...attributes, message: event?.message },
      )
    } catch (error) {
      window?.faro?.api?.pushEvent('error_logging_appinsight', { event, error })
    }
  }

  logErrorToFaro = (event, attributes) => {
    try {
      console.info(event?.eventCode)
      const attrStr = JSON.stringify(attributes || {})
      window.faro.api.pushEvent(event?.eventCode, {
        details: `${attrStr}`,
        message: event?.message,
      })
    } catch (error) {
      this.appInsights?.trackEvent({ name: 'error_logging_faro' }, { event, error })
    }
  }

  isFaroLoaded = (callback) => {
    if (window?.faro) {
      callback()
    } else {
      setTimeout(() => this.isFaroLoaded(callback), 500)
    }
  }

  logCustomEvent = (errorCode, attributes) => {
    try {
      const consumer_id = storageAvailable('localStorage') && localStorage.getItem('consumer_id')
      const event = eventCodendardEvent[errorCode]
      const logAttributes = consumer_id ? { ...attributes, consumer_id } : attributes
      if (process.env.IS_GRAFANA_ENABLED === 'true') {
        this.isFaroLoaded(() => this.logErrorToFaro(event, logAttributes))
      }
      this.logErrorToAppInsight(event, logAttributes)
    } catch (error) {
      console.error(error)
    }
  }

  initialize() {
    if (process.env.AZURE_INSTRUMENTATION_KEY) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: process.env.AZURE_INSTRUMENTATION_KEY,
          disablePageUnloadEvents: ['unload'],
          disableFetchTracking: false,
          loggingLevelConsole: 2,
          isCookieUseDisabled: true,
          loggingLevelTelemetry: 2,
        },
      })
      this.appInsights?.loadAppInsights()

      this.appInsights?.addTelemetryInitializer((item) => {
        item.tags.push({ 'ai.cloud.app': 'ModernWeb' })
      })

      this.appInsights?.trackPageView({})
    }
  }
}

export default new TelemetryService()
