import React from 'react'
import PropTypes from 'prop-types'
import twStyles from './SubmitButton.tw-styles'
import Icon from '../Icon/Icon'
import SubmitButtonStyles from './SubmitButton.styles'

const SubmitButton = (props) => {
  const {
    buttonText, disabled, isSubmitting, variant, onClick, id,
  } = props
  return (
    <>
      <div className={twStyles.headWrapper}>
        {isSubmitting && (
          <div className='spinnerIcon'>
            <Icon name='spinnerCoral' />
          </div>
        )}
        {!isSubmitting && (
          <>
            <div
              className={!disabled ? '' : 'createdisabled'}
            >
              <input
                id={id}
                type={`${!disabled ? 'submit' : 'button'}`}
                aria-label={buttonText}
                value={buttonText}
                // disabled={disabled}
                // eslint-disable-next-line jsx-a11y/aria-proptypes
                aria-disabled={`${!disabled ? 'false' : 'true'}`}
                className={`${!disabled ? 'active' : 'disabled'}  ${twStyles.wrapper[variant] || twStyles.wrapper.default}`}
                onClick={onClick}
              />
            </div>
          </>
        )}
      </div>
      <style jsx global>
        {SubmitButtonStyles}
      </style>
    </>
  )
}

SubmitButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
}

SubmitButton.defaultProps = {
  disabled: false,
  isSubmitting: false,
  variant: '',
  onClick: () => {},
  id: 'submit-btn',
}

export default SubmitButton
