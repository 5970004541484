import React from 'react'
import crypto from 'crypto'

export default (active, className) => {
  const randomID = crypto.randomBytes(12).toString('hex')
  return (
    <svg
      aria-labelledby={`spinnerIcon-${randomID}`}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 65 65'
      preserveAspectRatio='xMidYMid'
      className={className}
    >
      <g transform='translate(32,32)'>
        <g id='all'>
          <circle cx='0' cy='0' r='32' fill='#FFFFFF' />
          <circle id='turquoise' cx='0' cy='20' r='4' fill='#218372' />
          <circle id='red' cx='0' cy='20' r='4' fill='#DD3447' />
          <circle id='darkgreen' cx='0' cy='20' r='4' fill='#438447' />
          <circle id='pink' cx='0' cy='20' r='4' fill='#C7008E' />
          <circle id='blue' cx='0' cy='20' r='4' fill='#267EA6' />
        </g>
      </g>
      <animateTransform xlinkHref='#all' attributeName='transform' type='rotate' to='360 0 0' dur='2s' repeatCount='indefinite' />
      <animateTransform xlinkHref='#blue' attributeName='transform' type='rotate' values='0 0 0;360 0 0;360 0 0;360 0 0' dur='4s' repeatCount='indefinite' />
      <animateTransform xlinkHref='#pink' attributeName='transform' type='rotate' values='0 0 0;288 0 0;360 0 0;360 0 0' dur='4s' repeatCount='indefinite' />
      <animateTransform xlinkHref='#darkgreen' attributeName='transform' type='rotate' values='0 0 0;216 0 0;360 0 0;360 0 0' dur='4s' repeatCount='indefinite' />
      <animateTransform xlinkHref='#red' attributeName='transform' type='rotate' values='0 0 0;144 0 0;360 0 0;360 0 0' dur='4s' repeatCount='indefinite' />
      <animateTransform xlinkHref='#turquoise' attributeName='transform' type='rotate' values='0 0 0;72 0 0;360 0 0;360 0 0' dur='4s' repeatCount='indefinite' />
    </svg>
  )
}
