import LoginStore from '../components/UserManagement/Login-store'

const setDataLayerUserObject = () => {
  const { profileData } = LoginStore
  window.PGdataLayer.user = {
    firstname: profileData.name,
    // lastname: profileData.familyName,
    email: profileData?.emailAddress,
    id: profileData.consumerId || profileData.uuid,
    marketingProgramNbr: profileData.marketingProgramNbr,
    sourceID: profileData.sourceId,
  }
}

export const setDataLayerCouponObject = (couponData = []) => {
  delete window.PGdataLayer.product
  window.PGdataLayer.ecomm = {
    products:
      couponData
      && couponData.map(
        ({ GAData }) => GAData && {
          brand: GAData.brand ? GAData.brand.toString() : '',
          bundle_products: '',
          category: GAData.category_description
            ? GAData.category_description.toString()
            : '',
          frequency: '',
          listtype: GAData.top_level_category_description
            ? GAData.top_level_category_description.toString()
            : '',
          name: GAData.giq_category_description
            ? GAData.giq_category_description.toString()
            : '',
          position: '1',
          price: GAData.value ? GAData.value.toString() : '',
          product_coupon: GAData.coupon_id ? GAData.coupon_id.toString() : '',
          product_coupon_value: GAData.value ? GAData.value.toString() : '',
          product_discount: '',
          product_discount_value: '',
          quantity: '1',
          recurrence: '',
          sku: '',
          variant_sku: '',
        },
      ),
  }
}
const eventUrl = process.env.OKTA_ENVIRONMENT === 'production' ? 'www' : 'stage'
const resetDataLayerUserObject = () => {
  window.PGdataLayer.user.id = null
  window.PGdataLayer.user.sourceId = null
}

const triggerEvent = (
  eventCategory,
  eventAction,
  eventLabel = '',
  callback,
) => {
  const interval = setInterval(() => {
    if (window.Optanon) {
      clearInterval(interval)
      try {
        window.dataLayer.push({
          event: 'customEvent',
          GAeventCategory: eventCategory,
          GAeventAction: eventAction,
          GAeventLabel: eventLabel,
          GAeventValue: 0,
          GAeventNonInteraction: false,
          eventCallback: callback,
        })
      } catch (error) {
        console.error('triggerEvent error: ', error)
      }
    }
  }, 500)
}

export const triggerLoginEvent = () => {
  try {
    setDataLayerUserObject()
    triggerEvent(
      'event_crm_action',
      'event_profile_login_complete',
      'pgGoodEveryday_successful_login',
    )
  } catch (error) {
    console.error('GA is not loaded...')
  }
}

export const triggerRegisiterEvent = () => {
  try {
    setDataLayerUserObject()
    /** Promise to make sure Login is called only after registration
     *  dont use EventCallout
     */
    triggerEvent(
      'event_crm_action',
      'event_profile_register_complete',
      'pgGoodEveryday_successful_register',
    )
    triggerEvent(
      'event_crm_action',
      'event_profile_login_complete',
      'pgGoodEveryday_successful_login',
    )
  } catch (error) {
    console.error('GA is not loaded...')
  }
}

export const triggerLogoutEvent = () => {
  if (!(window.PGdataLayer && window.PGdataLayer.user)) return
  if (!window.PGdataLayer.user.id) return
  try {
    triggerEvent(
      'event_crm_action',
      'event_profile_logout',
      'pgGoodEveryday_successful_logout',
    )
    resetDataLayerUserObject()
  } catch (error) {
    console.error('GA is not loaded...')
  }
}
export const triggerUnsuccessfulLoginEvent = () => {
  try {
    triggerEvent(
      'event_crm_action',
      'event_profile_login_unsuccessful',
      'pgGoodEveryday_unsuccessful_login',
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerReceiptUploadSuccess = (callback = null) => {
  try {
    triggerEvent(
      'event_loyalty_action',
      'event_rewards_submit_scan',
      '',
      callback,
    )
  } catch (error) {
    console.error('GA is not loaded...')
  }
}

export const triggerRetailerEvent = (retailername) => {
  try {
    triggerEvent(
      'event_loyalty_action',
      'event_choose_retailer',
      retailername.trim(),
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}
export const triggerRetailerSearchEvent = () => {
  try {
    triggerEvent('event_loyalty_action', 'event_receipt_upload_search_retailer')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerBrandSearchEvent = () => {
  try {
    triggerEvent('event_loyalty_action', 'event_receipt_upload_search_brand')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerHashtagEvent = () => {
  try {
    triggerEvent('event_information_action', 'event_content_tag_click')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerMainSearchEvent = () => {
  try {
    triggerEvent('event_information_action', 'event_main_search')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerSearchEvent = (textToBeSearched) => {
  try {
    triggerEvent('event_information_action', 'event_search', textToBeSearched)
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerAddtoHomeEvent = () => {
  try {
    triggerEvent('event_crm_action', 'event_add_to_home')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerBrandEvent = (brandname) => {
  try {
    triggerEvent(
      'event_loyalty_action',
      'event_choose_brand',
      brandname.map((brand) => brand.trim()).join(),
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerReceiptEnlarge = () => {
  console.info('Reciept enlarged')
  try {
    triggerEvent('event_loyalty_action', 'event_receipt_upload_enlarge')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerPgeKognitivAccountCreation = () => {
  try {
    window.dataLayer.push({
      event: 'customEvent',
      GAeventCategory: 'event_crm_action',
      GAeventAction: 'event_kognitiv_account_creation',
      GAeventLabel: 'pge_kognitiv_account_creation',
      GAeventValue: 0,
    })
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggeAddToBasketEvent = (couponData) => {
  try {
    // window.dataLayer.push({
    //   event: 'customSegmentEvent',
    //   segmentCustomEventName: 'Request Coupon Add To Basket',
    //   segmentCustomEventPayload: {
    //     ...couponData.GAData,
    //   },
    // })
    window.dataLayer.push({
      event: 'customEvent',
      GAeventCategory: 'event_coupon_action',
      GAeventAction: 'event_coupon_request',
      GAeventLabel: `${couponData
        && couponData.GAData
        && couponData.GAData.brand
        && couponData.GAData.brand.toString()}|`,
      GAeventValue: `${couponData
        && couponData.GAData
        && couponData.GAData.value
        && couponData.GAData.value.toString()}`,
      GAeventNonInteraction: false,
      segmentSecondary: '',
    })
  } catch (error) {
    console.error('GA not loaded', error)
  }
}

export const triggerAddToDigitalBasketEvent = (digitalcouponData) => {
  try {
    window.dataLayer.push({
      event: 'customEvent',
      GAeventCategory: 'event_coupon_action',
      GAeventAction: 'event_coupon_request',
      GAeventLabel: `${digitalcouponData
        && digitalcouponData.GAData
        && digitalcouponData.GAData.brand
        && digitalcouponData.GAData.brand.toString()}|`,
      GAeventValue: `${digitalcouponData
        && digitalcouponData.GAData
        && digitalcouponData.GAData.value
        && digitalcouponData.GAData.value.toString()}`,
      GAeventNonInteraction: false,
      segmentSecondary: '',
    })
  } catch (error) {
    console.error('GA not loaded', error)
  }
}

export const triggerSendToBndlEvent = (appName) => {
  try {
    window.dataLayer.push({
      event: 'customEvent',
      GAeventCategory: 'event_coupon_action',
      GAeventAction: 'event_coupon_request',
      GAeventLabel: `SEND TO ${appName}`,
      GAeventNonInteraction: false,
      segmentSecondary: '',
    })
  } catch (error) {
    console.error('GA not loaded', error)
  }
}
export const triggerShowBasketEvent = () => {
  try {
    window.dataLayer.push({
      event: 'customEvent',
      GAeventCategory: 'event_coupon_action',
      GAeventAction: 'event_coupon_request',
      GAeventLabel: 'BASKET',
      GAeventNonInteraction: false,
      segmentSecondary: '',
    })
  } catch (error) {
    console.error('GA not loaded', error)
  }
}
export const triggerTextMeEvent = () => {
  try {
    window.dataLayer.push({
      event: 'customEvent',
      GAeventCategory: 'event_coupon_action',
      GAeventAction: 'event_coupon_request',
      GAeventLabel: 'TEXT ME',
      GAeventNonInteraction: false,
      segmentSecondary: '',
    })
  } catch (error) {
    console.error('GA not loaded', error)
  }
}

export const triggerSendDownloadLinkEvent = () => {
  try {
    window.dataLayer.push({
      event: 'customEvent',
      GAeventCategory: 'event_coupon_action',
      GAeventAction: 'event_coupon_request',
      GAeventLabel: 'SEND DOWNLOAD LINK',
      GAeventNonInteraction: false,
      segmentSecondary: '',
    })
  } catch (error) {
    console.error('GA not loaded', error)
  }
}

export const triggerCallMeEvent = () => {
  try {
    window.dataLayer.push({
      event: 'customEvent',
      GAeventCategory: 'event_coupon_action',
      GAeventAction: 'event_coupon_request',
      GAeventLabel: 'CALL ME',
      GAeventNonInteraction: false,
      segmentSecondary: '',
    })
  } catch (error) {
    console.error('GA not loaded', error)
  }
}
export const triggeVirtualPageview = (data) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer?.push({ event: 'virtualPageview', ...data })
}
export const triggePrintEvent = (couponData) => {
  try {
    // window.dataLayer.push({
    //   event: 'customSegmentEvent',
    //   segmentCustomEventName: 'Request Coupon Print',
    //   segmentCustomEventPayload: couponData.map(({ GAData }) => GAData),
    // })
    couponData.map(({ GAData }) => {
      window.dataLayer.push({
        event: 'customEvent',
        GAeventCategory: 'event_coupon_action',
        GAeventAction: 'event_coupon_print',
        GAeventLabel: `${GAData.brand.toString()}|`,
        GAeventValue: `${GAData.value.toString()}`,
        GAeventNonInteraction: false,
        segmentSecondary: '',
      })
      return null
    })
  } catch (error) {
    console.error('GA not loaded', error)
  }
}
export const triggerGetCodeEvent = (brandName) => {
  try {
    triggerEvent('event_coupon_action', 'event_coupon_code_request', brandName)
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerMessageBarEvent = () => {
  try {
    triggerEvent('event_crm_action', 'event_message_bar_action')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerCreateAccountEvent = () => {
  try {
    triggerEvent('event_crm_action', 'event_sign_up_create_your_account')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerCharityCompletionEvent = () => {
  try {
    triggerEvent('event_crm_action', 'event_sign_up_select_charity_complete')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerEmailVerificationEvent = () => {
  try {
    triggerEvent(
      'event_crm_action',
      'event_profile_email_verification_complete',
      'pgGoodEveryday_successful_email_verification',
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerProfileIncompletionEvent = () => {
  try {
    triggerEvent('event_crm_action', 'event_sign_up_profile_complete_no')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerProfileCompletionEvent = () => {
  try {
    triggerEvent('event_crm_action', 'event_sign_up_profile_complete')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerRewardRedeemEvent = () => {
  try {
    triggerEvent('event_reward_action', 'event_redeem_now')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerVideoGatingStartEvent = (label) => {
  try {
    triggerEvent('event_crm_action', `${label}` || 'event_sign_up_video_start')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerVideoGatingCompletionEvent = (label) => {
  try {
    triggerEvent(
      'event_crm_action',
      `${label}` || 'event_sign_up_video_complete',
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerVideoGatingNextButtonEvent = (label) => {
  try {
    triggerEvent('event_crm_action', `${label}` || 'event_sign_up_video_next')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerReceiptscanStartButtonEvent = () => {
  console.info('ReceiptscanScanStart event triggered...')
  try {
    triggerEvent('event_loyalty_action', 'event_receipt_upload_start')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerReceiptscanScanDateButtonEvent = () => {
  console.info('ReceiptscanScanDate event triggered...')
  try {
    triggerEvent('event_loyalty_action', 'event_choose_date')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerAlertbarPointsIconButtonEvent = () => {
  console.info('AlertbarPoints event triggered...')
  try {
    triggerEvent('event_crm_action', 'event_message_bar_action_points')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerAlertbarDonationsIconButtonEvent = () => {
  console.info('AlertbarDonations event triggered...')
  try {
    triggerEvent('event_crm_action', 'event_message_bar_action_donations')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerDigitalPromoTabEvent = () => {
  try {
    triggerEvent('event_coupon_action', 'event_promo_tab')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerPAHTabEvent = () => {
  try {
    triggerEvent('event_coupon_action', 'event_pah_tab')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerFooterNavButton = (GAEventaction) => {
  try {
    triggerEvent('event_informational_action', GAEventaction)
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerLoggedOutUserIconEvent = () => {
  console.info('login category triggered')
  try {
    triggerEvent('event_informational_action', 'event_log_in_icon')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerLoggedInUserIconEvent = () => {
  try {
    triggerEvent('event_informational_action', 'event_user_icon')
  } catch (error) {
    console.error('GA not loaded')
  }
}
export const triggerSignedUpLandingEvent = () => {
  try {
    triggerEvent('event_crm_action', 'event_profile_register', 'signup')
  } catch (error) {
    console.error('GA not loaded')
  }
}
export const triggerSignedUpSubmitEvent = () => {
  try {
    triggerEvent('event_crm_action', 'event_profile_register_submit', 'signup')
  } catch (error) {
    console.error('GA not loaded')
  }
}
export const triggerRegisiterUnSuccessfulEvent = () => {
  try {
    triggerEvent(
      'event_crm_action',
      'event_profile_register_unsuccessful',
      'signup',
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}
export const triggerLoginSubmitErrorEvent = () => {
  try {
    triggerEvent(
      'event_crm_action',
      'pgGoodEveryday_unsuccessful_login',
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}
export const triggerProfileLogInEvent = () => {
  try {
    triggerEvent('event_crm_action', 'event_profile_login')
  } catch (error) {
    console.error('GA not loaded')
  }
}
export const triggerProfileLogInSubmitEvent = () => {
  try {
    triggerEvent('event_crm_action', 'event_profile_login_submit')
  } catch (error) {
    console.error('GA not loaded')
  }
}
export const triggerProfileLogoutSubmitErrorEvent = () => {
  try {
    triggerEvent(
      'event_crm_action',
      'event_profile_logout',
      'pgGoodEveryday_successful_logout',
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}
export const tiggerSurveyStartEvent = () => {
  try {
    triggerEvent('event_survey_action', 'event_survey_start')
  } catch (error) {
    console.error('GA not loaded')
  }
}
export const triggerSurveyCompleteEvent = (eventLabel = '') => {
  try {
    triggerEvent('event_survey_action', 'event_survey_complete', eventLabel)
  } catch (error) {
    console.error('GA not loaded')
  }
}
export const triggerArticleViewEvent = (articleHeadline) => {
  try {
    triggerEvent(
      'event_informational_action',
      'event_view_article_page',
      articleHeadline,
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerShareEvent = (socialMediaName) => {
  try {
    triggerEvent(
      'event_informational_action',
      'event_social_share',
      socialMediaName,
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerLogoClickEvent = () => {
  try {
    triggerEvent('event_informational_action', 'event_image_click', 'logo')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerHomeBannerIconEvent = (label) => {
  try {
    triggerEvent('event_information_action', 'event_banner_click', label)
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerHomeBannerInternalLinkEvent = () => {
  try {
    triggerEvent(
      'event_information_action',
      'event_internal_link',
      'event_internal_link',
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerHomeBannerViewBrandEvent = () => {
  try {
    triggerEvent(
      'event_information_action',
      'event_view_brand',
      'event_view_brand',
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerHomeEarnButtonEvent = () => {
  try {
    triggerEvent(
      'event_information_action',
      'event_other_ways_to_earn',
      `https://${eventUrl}.pggoodeveryday.com/earn-Points`,
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerHomeImpactEvent = () => {
  try {
    triggerEvent(
      'event_information_action',
      'event_impact_click',
      `https://${eventUrl}.pggoodeveryday.com/impact/summary`,
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerHomeSignupEvent = () => {
  try {
    triggerEvent(
      'event_information_action',
      'event_signup_click',
      `https://${eventUrl}.pggoodeveryday.com/signup`,
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerHomeGoodNewsEvent = () => {
  try {
    triggerEvent(
      'event_informational_action',
      'event_view_good_news_page',
      `https://${eventUrl}.pggoodeveryday.com/good-news`,
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerHomeFeedbackEvent = () => {
  try {
    triggerEvent(
      'event_informational_action',
      'event_feedback_survey',
      'event_provide_feedback',
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const articlePAgeEvent = (url) => {
  try {
    triggerEvent(
      'event_informational_action',
      'event_article_detail_links',
      `${url}`,
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const homeBannerHeroButtonClick = () => {
  try {
    triggerEvent(
      'event_information_action',
      'event_banner_button_Click',
      `https://${eventUrl}.pggoodeveryday.com/earn-Points`,
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const carousalButtonClickEvent = (url) => {
  try {
    triggerEvent(
      'event_information_action',
      'event_carousal_button_Click',
      decodeURIComponent(url),
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerBirthdayRewardEvent = () => {
  try {
    triggerEvent('event_information_action', 'event_birthday_reward')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerHalfBirthdayRewardEvent = (eventCategory = 'event_information_action', eventName = 'event_half_birthday_reward', eventLabel = 'event_half_birthday_reward') => {
  try {
    triggerEvent(
      eventCategory,
      eventName,
      eventLabel,
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerSelectRetailerEvent = (retailername) => {
  try {
    triggerEvent(
      'event_loyalty_action',
      'event_rewards_scan_receipt',
      retailername.trim(),
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerSelectBrandEvent = (brandName) => {
  try {
    triggerEvent(
      'event_loyalty_action',
      'event_rewards_scan_receipt',
      brandName.trim(),
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerReceiptUploadEvent = () => {
  try {
    triggerEvent(
      'event_loyalty_action',
      'event_receipt_upload_submit',
      'Receipt Upload Submit',
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerReceiptZoomEvent = () => {
  try {
    triggerEvent(
      'event_loyalty_action',
      'event_receipt_upload_enlarge',
      'Receipt Upload Enlarge',
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerRegistrationSuccessEvent = () => {
  try {
    triggerEvent(
      'event_loyalty_action',
      'event_complete_registation',
      'pgGoodEveryday_successful_register',
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerCouponsEvent = () => {
  try {
    triggerEvent('event_coupon_action', 'event_promo_tab', 'Coupons')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerChatNowLinkEvent = () => {
  try {
    triggerEvent(
      'event_chatbot_action',
      'event_deflector_button_click',
      'chat_now',
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerChatPopupCloseEvent = () => {
  try {
    triggerEvent(
      'event_chatbot_action',
      'event_deflector_button_click',
      'close',
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerChatViewedEvent = () => {
  try {
    triggerEvent('event_chatbot_action', 'event_deflector_view', 'viewed')
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerSampleRedemptionSuccessfulEvent = (campaignName) => {
  try {
    triggerEvent('event_sample_action', 'event_sample_request', campaignName)
  } catch (error) {
    console.error('GA not loaded')
  }
}

export const triggerCompleteProfileClickEvent = () => {
  try {
    triggerEvent(
      'event_informational_action',
      'event_image_click',
      'profile_tile',
    )
  } catch (error) {
    console.error('GA not loaded')
  }
}
