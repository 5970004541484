import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'
import headerIcons from '../../constants/new-icons/headerIcon'

const Icon = ({
  name, active, className, spanStyle, color, pageSource, router,
}) => {
  let iconName; let colorName; let
    rotate = null
  const ImportedIconRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [pageLoaded, setPageLoaded] = useState(false)

  const importIcon = async () => {
    try {
      const { default: namedImport } = await import(`../../constants/new-icons/${iconName}.icon`)
      ImportedIconRef.current = namedImport
    } catch (error) {
      // this will add default user icon if icon file is not found
      const { default: namedImport } = await import('../../constants/new-icons/user.icon')
      ImportedIconRef.current = namedImport
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setPageLoaded(true)
  }, [])

  useEffect(() => {
    if (iconName) {
      setLoading(true)
      importIcon()
    }
  }, [iconName, name])

  if (headerIcons[name] !== undefined) {
    const icon = headerIcons[name]
    return (
      <span className={spanStyle}>
        {icon(active, className, color)}
      </span>
    )
  }

  const iconsArr = [
    { case: 'couponInfo', icon: 'info' },
    { case: 'couponDigitalInfo', icon: 'couponsInfo' },
    { case: 'onboardingnInfo', icon: 'info' },
    { case: 'couponAdd', icon: 'add' },
    { case: 'arrowHomeLightBlue', icon: 'arrowHome' },
    { case: 'arrowHomeLightGreen', icon: 'arrowHome' },
    { case: 'arrowHomeDarkGreen', icon: 'arrowHome' },
    { case: 'arrowHomeOrange', icon: 'arrowHome' },
    { case: 'arrowHomeGreen', icon: 'arrowHome' },
    { case: 'arrowHomePink', icon: 'arrowHome' },
    { case: 'arrowHomeBlue', icon: 'arrowHome' },
    { case: 'pinkArrow', icon: 'arrowHome' },
    { case: 'darkBlueArrow', icon: 'arrowHome' },
    { case: 'darkBlueArrowHome', icon: 'arrowHome' },
    { case: 'turquoiseArrow', icon: 'arrowHome' },
    { case: 'arrowHomeRed', icon: 'arrowHome' },
    { case: 'arrowHomePurple', icon: 'arrowHome' },
    { case: 'arrowUpBlue', icon: 'arrow' },
    { case: 'arrowUpBlueIcon', icon: 'arrow' },
    { case: 'arrowUpGray', icon: 'arrow' },
    { case: 'arrowUpTeal', icon: 'arrow' },
    { case: 'arrowDownGreen', icon: 'arrow' },
    { case: 'arrowDownTeal', icon: 'arrow' },
    { case: 'arrowDownGray', icon: 'arrow' },
    { case: 'arrowRightGray', icon: 'arrow' },
    { case: 'arrowRightCoral', icon: 'arrow' },
    { case: 'arrowRightBlue', icon: 'arrow' },
    { case: 'arrowRightGreen', icon: 'arrow' },
    { case: 'arrowRightCouponsBlue', icon: 'arrow' },
    { case: 'arrowRightWhite', icon: 'arrow' },
    { case: 'arrowLeftWhite', icon: 'arrow' },
    { case: 'arrowDownBlueIcon', icon: 'arrow' },
    { case: 'arrowBubblelightBlue', icon: 'arrowBubble' },
    { case: 'arrowBubbleBlue', icon: 'arrowBubble' },
    { case: 'arrowBubbleGreen', icon: 'arrowBubble' },
    { case: 'greenPlus1', icon: 'plus' },
    { case: 'greenPlus', icon: 'plus' },
    { case: 'redPlus', icon: 'plus' },
    { case: 'bluePlus', icon: 'plus' },
    { case: 'coralPlus', icon: 'plus' },
    { case: 'plusHelp', icon: 'plus' },
    { case: 'greenMinus1', icon: 'minus' },
    { case: 'greenMinus', icon: 'minus' },
    { case: 'redMinus', icon: 'minus' },
    { case: 'blueMinus', icon: 'minus' },
    { case: 'coralMinus', icon: 'minus' },
    { case: 'minusHelp', icon: 'minus' },
    { case: 'closeWhite', icon: 'close' },
    { case: 'closeLightGreen', icon: 'close' },
    { case: 'closeBlue', icon: 'close' },
    { case: 'closeCouponsBlue', icon: 'close' },
    { case: 'spinnerWhite', icon: 'spinner' },
    { case: 'coralEarnPoint', icon: 'coral' },
    { case: 'coralDarkBlue', icon: 'coral' },
    { case: 'coralPink', icon: 'coral' },
    { case: 'coralTurquoise', icon: 'coral' },
    { case: 'featureSurvey', icon: 'beautySurvey' },
    { case: 'beautySurvey', icon: 'beautySurvey' },
    { case: 'scanReceipt', icon: 'homeScanReceipt' },
    { case: 'scanReciept', icon: 'scan-reciept' },
    { case: 'searchImpact', icon: 'impact' },
    { case: 'searchRewards', icon: 'rewards' },
    { case: 'searchCoupons', icon: 'coupons' },
    { case: 'Twitter', icon: 'footerTwitter' },
    { case: 'searchGoodNews', icon: 'goodNews' },
    { case: 'redHeart', icon: 'heart' },
    { case: 'rebatesIcon', icon: 'rebate' },
    { case: 'causeSurveyMini', icon: 'causesSurveyMini' },
    { case: 'searchHome', icon: 'home' },
    { case: 'searchHelp', icon: 'help' },
    { case: 'searchAbout', icon: 'about' },
    { case: 'ohGreenFlag', icon: 'greenFlag' },
    { case: 'giftcard', icon: 'giftCards' },
    { case: 'Sweepstakes', icon: 'sweepstakes' },
    { case: 'couponsLogo', icon: 'aboutCoupons' },
    { case: 'aboutBrandSaverLogo', icon: 'aboutBrandSaver' },
    { case: 'Facebook', icon: 'facebook' },
    { case: 'alliancepoints', icon: 'alliancePoints' },
    { case: 'pledge', icon: 'Pledge' },
    { case: 'takeOnRaceCause', icon: 'torCause' },
    { case: 'CSDW', icon: 'csdw' },
    { case: 'opertaionHomefront', icon: 'operationHomefront' },
    { case: 'safeGuard', icon: 'safeguard' },
    { case: 'pampers', icon: 'pampers' },
    { case: 'itsOurHomeIcon', icon: 'itsOurHome' },
    { case: 'aeppIDLogo', icon: 'aepp' },
    { case: 'csdwIDLogo', icon: 'csdw' },
    { case: 'tideIDLogo', icon: 'tide' },
    { case: 'feedingamericaIDLogo', icon: 'fa' },
    { case: 'matthewIDLogo', icon: 'matthew' },
    { case: 'safeguardIDLogo', icon: 'safeguard' },
    { case: 'dawnibrIDLogo', icon: 'dawnibr' },
    { case: 'dawnmmcIDLogo', icon: 'dawnmmc' },
    { case: 'unitedIDLogo', icon: 'united' },
    { case: 'torIDLogo', icon: 'tor' },
    { case: 'ohIDLogo', icon: 'operationHomefront' },
    { case: 'hispanicStarIDLogo', icon: 'hispanicStar' },
    { case: 'impacthelp', icon: 'impactHelp' },
    {
      case: 'digitalCouponBndlColorIcon',
      icon: 'digitalCouponBndlColorIconDownload',
    },
    { case: 'digitalCouponBndlWhiteIcon', icon: 'digitalCouponsBndlWhite' },
    {
      case: 'digitalCouponCoupons24ColorIcon',
      icon: 'digitalCouponCoupons24ColorIconDownload',
    },
    {
      case: 'digitalCouponCoupons24WhiteIcon',
      icon: 'digitalCouponsCoupons24White',
    },
    { case: 'digitalCouponsCloseIcon', icon: 'digitalCouponClose' },
    { case: 'receiptRadioButton', icon: 'radioButton' },
    { case: 'digitalRadioButton', icon: 'radioButtonDigitalCoupons' },
    { case: 'test', icon: 'checkBox' },
    { case: 'test1', icon: 'checkBox' },
    { case: 'receiptCheckBox', icon: 'checkBox' },
    { case: 'pgBrandsRedHeart', icon: 'PGBrands/pgBrandsHeart' },
    {
      case: 'brandSaverC24CVSLogoIcon',
      icon: 'brandSaverC24CVSLogo',
    },
  ]
  if (name) {
    const icons = iconsArr.find((item) => (item.case === name))
    iconName = icons ? icons.icon : name
  }

  const colorArr = [{
    case: 'featureSurvey',
    color: {
      color1: '#29FAFC',
      color2: '#309DD0',
    },
  },
  {
    case: 'beautySurvey',
    color: {
      color1: '#A400A6',
      color2: '#E04748',
    },
  },
  { case: 'arrowHomeRed', color: '#DD3447' },
  { case: 'redPlus', color: '#DD3447' },
  { case: 'red', color: '#DD3447' },
  { case: 'redMinus', color: '#DD3447' },
  { case: 'pgBrandsRedHeart', color: '#DD3447' },
  { case: 'arrowHomePurple', color: '#A400A6' },
  { case: 'test1', color: '#FF6F61' },
  { case: 'coralPlus', color: '#FF6F61' },
  { case: 'coralMinus', color: '#FF6F61' },
  { case: 'arrowRightCoral', color: '#FF6F61' },
  { case: 'arrowHomeOrange', color: '#FF6F61' },
  { case: 'coral', color: '#FF6F61' },
  { case: 'test', color: '#FF6F61' },
  { case: 'plusHelp', color: '#FF6F61' },
  { case: 'minusHelp', color: '#FF6F61' },
  { case: 'coralTurquoise', color: '#4CC5D9' },
  { case: 'coralEarnPoint', color: '#4CC5D9' },
  { case: 'turquoiseArrow', color: '#4CC5D9' },
  { case: 'arrowBubblelightBlue', color: '#4CC5D9' },
  { case: 'arrowHomeLightBlue', color: '#4CC5D9' },
  { case: 'coralPink', color: '#C7008E' },
  { case: 'pinkArrow', color: '#C7008E' },
  { case: 'arrowHomePink', color: '#C7008E' },
  { case: 'coralDarkBlue', color: '#267EA6' },
  { case: 'darkBlueArrowHome', color: '#267EA6' },
  { case: 'arrowBubbleBlue', color: '#003DA6' },
  { case: 'arrowHomeBlue', color: '#267EA6' },
  { case: 'blueMinus', color: '#267EA6' },
  { case: 'arrowUpBlue', color: '#267EA6' },
  { case: 'arrowUpBlueIcon', color: '#267EA6' },
  { case: 'arrowDownBlueIcon', color: '#267EA6' },
  { case: 'onboardingnInfo', color: '#267EA6' },
  { case: 'darkBlueArrow', color: '#1EA7C2' },
  { case: 'arrowRightBlue', color: '#1EA7C2' },
  { case: 'blue', color: '#1EA7C2' },
  { case: 'closeBlue', color: '#1EA7C2' },
  { case: 'closeCouponsBlue', color: '#003CA5' },
  { case: 'spinnerWhite', color: '#FFFFFF' },
  { case: 'closeWhite', color: '#FFFFFF' },
  { case: 'arrowLeftWhite', color: '#FFFFFF' },
  { case: 'arrowRightWhite', color: '#FFFFFF' },
  { case: 'white', color: '#FFFFFF' },
  { case: 'closeLightGreen', color: '#2BAD9A' },
  { case: 'arrowRightGreen', color: '#2BAD9A' },
  { case: 'arrowRightCouponsBlue', color: '#003CA5' },
  { case: 'lightGreenHome', color: '#2BAD9A' },
  { case: 'arrowBubbleGreen', color: '#2BAD9A' },
  { case: 'arrowHomeLightGreen', color: '#2BAD9A' },
  { case: 'couponInfo', color: '#2BAD9A' },
  { case: 'arrowHomeDarkGreen', color: '#246D38' },
  { case: 'arrowRightGray', color: '#5E5E5E' },
  { case: 'arrowDownGray', color: '#5E5E5E' },
  { case: 'arrowUpGray', color: '#5E5E5E' },
  { case: 'arrowDownGreen', color: '#56AB57' },
  { case: 'green', color: '#56AB57' },
  { case: 'arrowUpTeal', color: '#207F6F' },
  { case: 'arrowDownTeal', color: '#207F6F' },
  { case: 'teal', color: '#207F6F' },
  { case: 'greenMinus1', color: '#57AB58' },
  { case: 'greenMinus', color: '#57AB58' },
  { case: 'arrowHomeGreen', color: '#57AB58' },
  { case: 'greenPlus', color: '#57AB58' },
  ]
  if (name) {
    const colors = colorArr.find((item) => (item.case === name))
    colorName = colors ? colors.color : color
  }
  switch (name) {
    case 'arrowLeftWhite':
      rotate = 90
      break
    case 'arrowRightGray':
    case 'arrowRightWhite':
    case 'arrowRightGreen':
    case 'arrowRightBlue':
    case 'arrowRightCouponsBlue':
      rotate = -90
      break
    case 'arrowUpBlue':
      rotate = -180
      break
    case 'greenMinus1':
    case 'arrowUpBlueIcon':
      rotate = -180
      break
    case 'test':
    case 'arrowDownBlueIcon':
      rotate = 180
      break
    default:
      rotate = 0
      break
  }
  if (router?.asPath?.includes('/impact/')) {
    switch (name) {
      case 'scanReceipt':
        iconName = 'scan-reciept'
        break
      case 'Facebook':
        iconName = 'socialShareFacebook'
        break
      case 'arrowDownGreen':
      case 'arrowRightGray':
      case 'arrowLeftWhite':
      case 'arrowRightWhite':
        iconName = 'arrow'
        break
      case 'pledge':
        iconName = 'Pledge'
        break
      case 'takeOnRaceCause':
        iconName = 'torCause'
        break
      case 'alwayspoints':
        iconName = 'alwaysPoints'
        break
      case 'alliancepoints':
        iconName = 'alliancePoints'
        break
      case 'calendar':
        iconName = 'ImpactSummary/calendar'
        break
      case 'CSDW':
        iconName = 'csdw'
        break
      case 'opertaionHomefront':
        iconName = 'operationHomefront'
        break
      case 'safeGuard':
        iconName = 'safeguard'
        break
      case 'ohGreenFlag':
        iconName = 'greenFlag'
        break
      default:
        iconName = name
        break
    }
  }
  if (router?.asPath?.includes('/about/')) {
    switch (name) {
      case 'scanReciept':
        iconName = 'homeScanReceipt'
        break
      case 'sweepstakes':
        iconName = 'aboutSweepstakes'
        break
      case 'giftcard':
        iconName = 'giftCards'
        break
      case 'couponsLogo':
        iconName = 'aboutCoupons'
        break
      case 'aboutBrandSaverLogo':
        iconName = 'aboutBrandSaver'
        break
      case 'arrowBubbleGreen':
      case 'arrowHomeLightGreen':
      case 'couponInfo':
        colorName = '#2BAD9A'
        break
      case 'arrowHomeDarkGreen':
        colorName = '#246D38'
        break
      case 'arrowBubblelightBlue':
      case 'arrowBubbleBlue':
        iconName = 'arrowBubble'
        break
      default:
        iconName = name
        break
    }
  }
  if (pageSource) iconName = `ImpactSummary/${iconName}`

  if (!loading && ImportedIconRef.current && pageLoaded) {
    const { current: ImportedIcon } = ImportedIconRef
    return (
      <span className={spanStyle}>
        {iconName === 'arrow'
          ? ImportedIcon && ImportedIcon(colorName, className, rotate || 0)
          : ImportedIcon && ImportedIcon(active, className, colorName)}
      </span>
    )
  }

  return null
}

Icon.defaultProps = {
  active: false,
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  spanStyle: PropTypes.string,
  router: PropTypes.instanceOf(Object),
  color: PropTypes.string,
  pageSource: PropTypes.string,
}

Icon.defaultProps = {
  className: '',
  active: false,
  spanStyle: '',
  color: null,
  pageSource: null,
  router: {},
}

export default withRouter(Icon)
